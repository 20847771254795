import { Stack, Input, Button, Inline, InlineItem } from '@kinesis/bungle';
import { Me, MeUpdate } from '@/types';
import { useState, useCallback } from 'react';
import { noop } from 'lodash/fp';
import { Layout } from '@/components/layout';

const UserProfile = (props: UserProfileProps) => {
  const { user, onChange = noop } = props;
  const { firstName, lastName, jobTitle = '' } = user;
  const [firstNameState, setFirstNameState] = useState(firstName);
  const [lastNameState, setLastNameState] = useState(lastName);
  const [jobTitleState, setJobTitleState] = useState(jobTitle);
  const isDirty =
    firstNameState !== firstName ||
    lastNameState !== lastName ||
    jobTitleState !== jobTitle;
  const onSave = useCallback(
    () =>
      onChange({
        id: user.id,
        analytics: user.analytics,
        firstName: firstNameState,
        lastName: lastNameState,
        jobTitle: jobTitleState,
      }),
    [user, onChange, firstNameState, lastNameState, jobTitleState],
  );
  return (
    <Layout variant='narrow'>
      <Stack space='large'>
        <Stack space='medium'>
          <Stack space='medium'>
            <Inline>
              <InlineItem sizing='fill-container'>
                <Input
                  label='First name'
                  magnitude='large'
                  value={firstNameState}
                  onChange={setFirstNameState}
                  placeholder='Enter first name'
                />
              </InlineItem>
              <InlineItem sizing='fill-container'>
                <Input
                  label='Last name'
                  magnitude='large'
                  value={lastNameState}
                  onChange={setLastNameState}
                  placeholder='Enter last name'
                />
              </InlineItem>
            </Inline>
            <Input
              label='Job title'
              magnitude='large'
              value={jobTitleState}
              onChange={setJobTitleState}
              placeholder='Enter job title'
            />
          </Stack>
        </Stack>
        <Stack space='medium'>
          <Inline>
            <InlineItem>
              <Button variant='primary' onClick={onSave} disabled={!isDirty}>
                Save changes
              </Button>
            </InlineItem>
          </Inline>
        </Stack>
      </Stack>
    </Layout>
  );
};

export type UserProfileProps = {
  onChange?: (value: MeUpdate) => void;
  user: Me;
};

export { UserProfile };

import { isNil } from 'lodash/fp';
import { AcceptedLogin } from '@/types';
import { launch } from '@/services/launch';

const setLocation = (path: string, replace: boolean) => {
  if (replace) {
    window.location.replace(path);
  } else {
    window.location.assign(path);
  }
};

const login = (
  data: AcceptedLogin,
  replace: boolean,
  navigate: (url: string) => void,
) => {
  localStorage.setItem('kinesis:session', data.accessToken);
  const redirect = localStorage.getItem('kinesis:redirect');
  if (!data.acceptedTerms || isNil(data.analytics)) {
    navigate('/registration/terms');
  } else if (data.pendingInvitations) {
    navigate('/invitations');
  } else if (data.orphan) {
    navigate('/registration/complete');
  } else if (!redirect) {
    launch(data.target, replace);
  } else {
    localStorage.removeItem('kinesis:redirect');
    setLocation(redirect, replace);
  }
};

export { login };

import { Container, Frame, Header, Content } from '@kinesis/bungle';
import type { Session, Flag } from '@/types';
import { BrandBackground } from '@/components/brand-background';
import { PrimaryNavigation } from '@/components/primary-navigation';
import { noop } from 'lodash/fp';

const AppLayout = (props: AppLayoutProps) => {
  const {
    children,
    enableFlags,
    flags,
    session,
    onLogout = noop,
    onToggleFlag,
    onOpenSwitcher,
  } = props;

  return (
    <Container>
      <Frame>
        <BrandBackground>
          <Container>
            <Header>
              <PrimaryNavigation
                flags={flags}
                enableFlags={!!enableFlags}
                onToggleFlag={onToggleFlag}
                session={session}
                onLogout={onLogout}
                onOpenSwitcher={onOpenSwitcher}
              />
            </Header>

            <Frame>
              <Content sizing='fill-container' height='100%' background='white'>
                {children}
              </Content>
            </Frame>
          </Container>
        </BrandBackground>
      </Frame>
    </Container>
  );
};

export type AppLayoutProps = {
  children?: JSX.Element;
  enableFlags?: boolean;
  flags: Flag[];
  onLogout?: () => void;
  onToggleFlag?: (name: string) => void;
  session?: Session;
  onOpenSwitcher?: () => void;
};

export { AppLayout };

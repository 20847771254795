import {
  EntityState,
  createSelector,
  createEntityAdapter,
} from '@reduxjs/toolkit';
import {
  AcceptedLogin,
  AccessKeySecret,
  DefaultProductOption,
  LaunchOptions,
  LaunchSelection,
  Me,
  MeUpdate,
  Namespace,
} from '@/types';
import { api } from './api';
import { loginApi } from './login';

const productLaunchSelectionsAdapter = createEntityAdapter<
  LaunchSelection,
  Namespace
>({
  selectId: (l: LaunchSelection) => l.namespace,
  sortComparer: (a: LaunchSelection, b: LaunchSelection) =>
    a.namespace.localeCompare(b.namespace),
});

const userApi = api.injectEndpoints({
  endpoints: (builder) => ({
    me: builder.query<Me, void>({
      query: () => `users/me`,
    }),
    passwordReset: builder.mutation<void, string>({
      query: (email) => ({
        url: 'password-reset',
        method: 'POST',
        body: { email },
      }),
    }),
    passwordResetValidate: builder.mutation<{ email: string }, string>({
      query: (token) => ({
        url: 'password-reset-validate',
        method: 'POST',
        body: { token },
      }),
    }),
    passwordResetPerform: builder.mutation<
      void,
      { token: string; password: string }
    >({
      query: ({ token, password }) => ({
        url: 'password-reset-perform',
        method: 'POST',
        body: { token, password },
      }),
    }),
    updateMe: builder.mutation<Me, MeUpdate>({
      query: (patch) => ({
        url: `users/me`,
        method: 'POST',
        body: patch,
      }),
      onQueryStarted: async (patch, { dispatch, queryFulfilled }) => {
        const patchResult = dispatch(
          userApi.util.updateQueryData('me', undefined, (current) => {
            Object.assign(current, patch);
          }),
        );
        queryFulfilled.catch(patchResult.undo);
      },
    }),
    newAccessKey: builder.mutation<AccessKeySecret, void>({
      query: () => ({
        url: `users/me/keys`,
        method: 'POST',
        body: {},
      }),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled;
        dispatch(
          userApi.util.updateQueryData('me', undefined, (current) => {
            current.keys.push({
              id: data.id,
              created: data.created,
            });
          }),
        );
      },
    }),
    deleteAccessKey: builder.mutation<void, string>({
      query: (id) => ({
        url: `users/me/keys/${id}`,
        method: 'DELETE',
        body: {},
      }),
      onQueryStarted: async (target, { dispatch, queryFulfilled }) => {
        const patchResult = dispatch(
          userApi.util.updateQueryData('me', undefined, (current) => {
            current.keys = current.keys.filter(({ id }) => id !== target);
          }),
        );
        queryFulfilled.catch(patchResult.undo);
      },
    }),
    acceptMembershipInvitation: builder.mutation<AcceptedLogin, string>({
      query: (token) => ({
        url: `users/me/membership/accept-invitation/${token}`,
        method: 'POST',
        body: {},
      }),
      onQueryStarted: async (token, { dispatch, queryFulfilled }) => {
        const patchResult = dispatch(
          userApi.util.updateQueryData('me', undefined, (current) => {
            current.memberships = current.memberships.map((membership) => {
              if (membership.invitationToken === token) {
                membership.status = 'active';
              }
              return membership;
            });
          }),
        );
        try {
          const { data: session } = await queryFulfilled;
          dispatch(
            loginApi.util.upsertQueryData('session', undefined, session),
          );
        } catch {
          patchResult.undo();
        }
      },
    }),
    declineMembershipInvitation: builder.mutation<AcceptedLogin, string>({
      query: (token) => ({
        url: `users/me/membership/decline-invitation/${token}`,
        method: 'POST',
        body: {},
      }),
      onQueryStarted: async (token, { dispatch, queryFulfilled }) => {
        const patchResult = dispatch(
          userApi.util.updateQueryData('me', undefined, (current) => {
            current.memberships = current.memberships.filter(
              (membership) => membership.invitationToken != token,
            );
          }),
        );
        try {
          const { data: session } = await queryFulfilled;
          dispatch(
            loginApi.util.upsertQueryData('session', undefined, session),
          );
        } catch {
          patchResult.undo();
        }
      },
    }),
    defaultOrganisationOptions: builder.query<{ id: number }[], void>({
      query: () => 'users/me/options/default/organisation',
    }),
    defaultOrganisation: builder.query<{ id: number }, void>({
      query: () => 'users/me/default/organisation',
    }),
    updateDefaultOrganisation: builder.mutation<{ id: number }, { id: number }>(
      {
        query: (selection) => ({
          url: `users/me/default/organisation`,
          method: 'POST',
          body: selection,
        }),
        onQueryStarted: async (patch, { dispatch, queryFulfilled }) => {
          const patchResult = dispatch(
            userApi.util.updateQueryData(
              'defaultOrganisation',
              undefined,
              (current) => {
                Object.assign(current, patch);
              },
            ),
          );
          queryFulfilled.catch(patchResult.undo);
        },
      },
    ),
    defaultProductOptions: builder.query<DefaultProductOption[], void>({
      query: () => 'users/me/options/default/product',
    }),
    defaultProduct: builder.query<{ preference: string }, void>({
      query: () => 'users/me/default/product',
    }),
    updateDefaultProduct: builder.mutation<
      { preference: string },
      { preference: string }
    >({
      query: (preference) => ({
        url: `users/me/default/product`,
        method: 'POST',
        body: preference,
      }),
      onQueryStarted: async (patch, { dispatch, queryFulfilled }) => {
        const patchResult = dispatch(
          userApi.util.updateQueryData(
            'defaultProduct',
            undefined,
            (current) => {
              Object.assign(current, patch);
            },
          ),
        );
        queryFulfilled.catch(patchResult.undo);
      },
    }),
    productLaunchOptions: builder.query<LaunchOptions[], void>({
      query: () => 'users/me/options/product/launch',
    }),
    productLaunchSelections: builder.query<
      EntityState<LaunchSelection, Namespace>,
      void
    >({
      query: () => 'users/me/product/launch',
      transformResponse: (response: any) =>
        productLaunchSelectionsAdapter.setAll(
          productLaunchSelectionsAdapter.getInitialState(),
          response,
        ),
    }),
    updateProductLaunchSelection: builder.mutation<
      LaunchSelection,
      LaunchSelection
    >({
      query: (selection) => ({
        url: `users/me/product/launch/${selection.namespace}`,
        method: 'POST',
        body: selection.preference,
      }),
      onQueryStarted: async (patch, { dispatch, queryFulfilled }) => {
        const patchResult = dispatch(
          userApi.util.updateQueryData(
            'productLaunchSelections',
            undefined,
            (current) => {
              productLaunchSelectionsAdapter.updateOne(current as any, {
                id: patch.namespace,
                changes: patch,
              });
            },
          ),
        );
        try {
          const { data } = await queryFulfilled;
          dispatch(
            userApi.util.updateQueryData(
              'productLaunchSelections',
              undefined,
              (current) => {
                productLaunchSelectionsAdapter.updateOne(current as any, {
                  id: data.namespace,
                  changes: data,
                });
              },
            ),
          );
        } catch {
          patchResult.undo();
        }
      },
    }),
  }),
});

const productLaunchSelectionEntitiesSelector = createSelector(
  userApi.endpoints.productLaunchSelections.select(),
  (result) => result?.data ?? productLaunchSelectionsAdapter.getInitialState(),
);

export { productLaunchSelectionEntitiesSelector };

export const {
  selectById: productLaunchSelectionByIdSelector,
  selectIds: productLaunchSelectionIdsSelector,
  selectAll: productLaunchSelectionsSelector,
} = productLaunchSelectionsAdapter.getSelectors(
  productLaunchSelectionEntitiesSelector,
);

export const {
  useMeQuery,
  useUpdateMeMutation,
  useNewAccessKeyMutation,
  useDeleteAccessKeyMutation,
  useAcceptMembershipInvitationMutation,
  useDeclineMembershipInvitationMutation,
  usePasswordResetMutation,
  usePasswordResetValidateMutation,
  usePasswordResetPerformMutation,
  useDefaultOrganisationOptionsQuery,
  useDefaultOrganisationQuery,
  useUpdateDefaultOrganisationMutation,
  useDefaultProductOptionsQuery,
  useDefaultProductQuery,
  useUpdateDefaultProductMutation,
  useProductLaunchOptionsQuery,
  useProductLaunchSelectionsQuery,
  useUpdateProductLaunchSelectionMutation,
} = userApi;

export { userApi };

import { DefaultProductOption, Namespace, Product, Products } from '@/types';

export const productFromString = (raw?: string): Product | undefined => {
  return Products.find((product) => raw == product);
};

export const productFromNamespace = (
  namespace: Namespace,
): Product | undefined => {
  switch (namespace) {
    case 'city':
      return 'city';
    case 'integrated':
      return 'integrated';
    case 'inventory':
      return 'nzo';
    case 'workspace':
      return 'workspaces';
    case 'pathways':
      return 'pathways';
    case 'bill-management':
      return 'bill-management';
  }
};

export const productNamespace = (product: Product): Namespace => {
  switch (product) {
    case 'city':
      return 'city';
    case 'integrated':
      return 'integrated';
    case 'nzo':
      return 'inventory';
    case 'pathways':
      return 'pathways';
    case 'workspaces':
      return 'workspace';
    case 'bill-management':
      return 'bill-management';
  }
};

export const fromPreference = (preference?: string): DefaultProductOption => {
  switch (preference) {
    case 'default-for-organisation':
      return { type: 'default-for-organisation' };
    case 'last-visited':
      return { type: 'last-visited' };
    default:
      return {
        type: 'product',
        product: productFromString(preference) || 'workspaces',
      };
  }
};

export const toPreference = (option: DefaultProductOption): string => {
  switch (option.type) {
    case 'default-for-organisation':
      return 'default-for-organisation';
    case 'last-visited':
      return 'last-visited';
    default:
      return option.product;
  }
};

export const productName = (product: Product) => {
  switch (product) {
    case 'city':
      return 'CCAP City';
    case 'integrated':
      return 'CCAP Integrated';
    case 'nzo':
      return 'Net-Zero for Organisations';
    case 'pathways':
      return 'Net-Zero Pathways';
    case 'workspaces':
      return 'Workspaces';
    case 'bill-management':
      return 'Bill Management';
  }
};

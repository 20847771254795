import { useParams } from 'react-router-dom';
import { useAcceptTermsMutation, useGetPendingTermsQuery } from '@/api/terms';
import { Loading } from '@/components/loading';
import { CustomerTermsAndConditions } from '@/components/customer-terms-and-conditions';
import type { Namespace } from '@/types';

const PendingTerms = () => {
  const { namespace, entityId } = useParams() as {
    namespace: Namespace;
    entityId: string;
  };
  const entity = { namespace, id: entityId };
  const { data, isLoading } = useGetPendingTermsQuery(entity);
  const [acceptTerms] = useAcceptTermsMutation();

  if (isLoading) {
    return <Loading />;
  }
  return (
    <CustomerTermsAndConditions
      entity={entity}
      data={data}
      onAccept={acceptTerms}
    />
  );
};

export { PendingTerms };

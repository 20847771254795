import { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  useRegisterMutation,
  useRegisterProfileMutation,
} from '@/api/registration';
import { useToast, Text, Strong } from '@kinesis/bungle';
import { Center } from '@/components/center';
import {
  RegistrationRequest,
  RegistrationEmail,
} from '@/components/registration-request';
import {
  RegistrationCode,
  RegistrationCodeConfirm,
} from '@/components/registration-code';
import { RegistrationSso } from '@/components/registration-sso';
import {
  RegistrationProfile,
  RegistrationProfileDetails,
} from '@/components/registration-profile';
import { setLastLogin } from '@/services/last-login';
import { login } from '@/services/login';

type RegistrationState =
  | { state: 'email' }
  | { state: 'code'; email: string; token: string }
  | { state: 'sso'; email: string; organisation: string; redirectUrl: string }
  | { state: 'profile'; email: string; code: string; token: string };

const Registration = () => {
  const toast = useToast('globalTop');
  const [register, { isLoading: isRegistering }] = useRegisterMutation();
  const [registerProfile, { isLoading: isRegisteringProfile }] =
    useRegisterProfileMutation();
  const navigate = useNavigate();
  const [registrationState, setRegistrationState] = useState<RegistrationState>(
    { state: 'email' },
  );

  const onRegistration = useCallback(
    async (request: RegistrationEmail) => {
      const { email } = request;
      try {
        const data = await register({
          email,
          allowSso: true,
        }).unwrap();
        if (data.method === 'code') {
          toast(
            <Text>
              Sign up code sent to <Strong>{email}</Strong>.
            </Text>,
            {
              variant: 'success',
              duration: 6000,
            },
          );
          setRegistrationState({
            state: 'code',
            token: data.token,
            email,
          });
        } else if (data.method === 'sso') {
          setRegistrationState({
            state: 'sso',
            email,
            organisation: data.organisation,
            redirectUrl: data.redirectUrl,
          });
        } else {
          toast('We couldn’t register your account. Please try again.', {
            variant: 'error',
            duration: 6000,
          });
        }
      } catch (e) {
        toast('We couldn’t register your account. Please try again.', {
          variant: 'error',
          duration: 6000,
        });
      }
    },
    [register, toast],
  );

  const onUseCredentials = useCallback(
    async (request: RegistrationEmail) => {
      const { email } = request;
      try {
        const data = await register({
          email,
          allowSso: false,
        }).unwrap();
        if (data.method === 'code') {
          toast(`Confirmation email sent to ${email}.`, {
            variant: 'success',
            duration: 6000,
          });
          setRegistrationState({
            state: 'code',
            token: data.token,
            email,
          });
        } else {
          toast('We couldn’t register your account. Please try again.', {
            variant: 'error',
            duration: 6000,
          });
        }
      } catch (e) {
        toast('We couldn’t register your account. Please try again.', {
          variant: 'error',
          duration: 6000,
        });
      }
    },
    [register, toast],
  );

  const onConfirm = useCallback(
    (v: RegistrationCodeConfirm) => {
      setRegistrationState({
        state: 'profile',
        code: v.code,
        token: v.token,
        email: v.email,
      });
    },
    [setRegistrationState],
  );

  const onLoginSso = useCallback(() => {
    if (registrationState.state === 'sso') {
      setLastLogin(registrationState.email);
      window.location.assign(registrationState.redirectUrl);
    }
  }, [registrationState]);

  const onRegisterProfile = useCallback(
    async (request: RegistrationProfileDetails) => {
      try {
        const data = await registerProfile(request).unwrap();
        setLastLogin(request.email);
        login(data, false, navigate);
      } catch (e: any) {
        setRegistrationState({ state: 'email' });
        if (e.data?.code === 'can-not-register-existing-user') {
          toast(
            'Your email is already registered for Kinesis. Log in or reset your password to continue.',
            {
              variant: 'error',
              duration: 6000,
            },
          );
        } else {
          toast('We couldn’t register your account. Please try again.', {
            variant: 'error',
            duration: 6000,
          });
        }
      }
    },
    [registerProfile, toast, navigate],
  );

  return (
    <Center>
      {registrationState.state === 'email' && (
        <RegistrationRequest
          onRegister={onRegistration}
          isRegistering={isRegistering}
        />
      )}
      {registrationState.state === 'code' && (
        <RegistrationCode
          onConfirm={onConfirm}
          email={registrationState.email}
          token={registrationState.token}
        />
      )}
      {registrationState.state === 'sso' && (
        <RegistrationSso
          organisation={registrationState.organisation}
          email={registrationState.email}
          onLogin={onLoginSso}
          onUseCredentials={onUseCredentials}
          isLoading={isRegistering}
        />
      )}
      {registrationState.state === 'profile' && (
        <RegistrationProfile
          onRegistration={onRegisterProfile}
          isRegistering={isRegisteringProfile}
          code={registrationState.code}
          token={registrationState.token}
          email={registrationState.email}
        />
      )}
    </Center>
  );
};

export { Registration };

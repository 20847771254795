import { useMemo } from 'react';
import { Heading, Stack } from '@kinesis/bungle';
import {
  MarkdownExtension,
  BoldExtension,
  StrikeExtension,
  ItalicExtension,
  UnderlineExtension,
  TrailingNodeExtension,
  BulletListExtension,
} from 'remirror/extensions';
import { Remirror, useRemirror, useRemirrorContext } from '@remirror/react';

const extensions = () => [
  new BoldExtension({}),
  new StrikeExtension(),
  new ItalicExtension(),
  new UnderlineExtension(),
  new TrailingNodeExtension({}),
  new BulletListExtension({}),
  new MarkdownExtension({ copyAsMarkdown: false }),
];

type RemirrorSetContentProps = {
  text: string;
};

const RemirrorSetContent = ({ text }: RemirrorSetContentProps) => {
  const { setContent } = useRemirrorContext();
  useMemo(() => setContent(text), [text, setContent]);
  return null;
};

type CustomerTermsAndConditionsItemProps = {
  id: number;
  name?: string;
  text?: string;
};

const CustomerTermsAndConditionsItem = ({
  name = '',
  text = '',
}: CustomerTermsAndConditionsItemProps) => {
  const { manager, state: initialContent } = useRemirror({
    extensions,
    content: text ? text.trim() : undefined,
    stringHandler: 'markdown',
    selection: 'end',
  });
  return (
    <Stack space='large'>
      <Heading as='h2' size='large'>
        {name}
      </Heading>
      <Remirror
        editable={false}
        manager={manager}
        initialContent={initialContent}
        autoRender='end'
      >
        <RemirrorSetContent text={text} />
      </Remirror>
    </Stack>
  );
};

export { CustomerTermsAndConditionsItem };

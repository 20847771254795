import { useCallback, useRef, useState } from 'react';
import { Button, Input, Stack } from '@kinesis/bungle';
import { Loading } from '@/components/loading';
import { noop } from 'lodash/fp';

const PasswordResetNewPassword = (props: PasswordResetNewPasswordProps) => {
  const {
    onPasswordReset = noop,
    validatedEmail = '',
    isResettingPassword = false,
  } = props;

  const passwordRef = useRef<HTMLInputElement>();
  const [passwordValidationError, setPasswordValidationError] = useState(false);

  const handlePasswordReset = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      setPasswordValidationError(false);

      const password = passwordRef.current?.value || '';

      if (!password.length || password.length < 8) {
        setPasswordValidationError(true);
      } else {
        onPasswordReset(password);
      }
    },
    [onPasswordReset, passwordRef],
  );

  if (!validatedEmail) {
    return <Loading />;
  }

  return (
    <form onSubmit={handlePasswordReset} noValidate>
      <Stack space='medium'>
        <Input
          disabled
          label='Email address'
          magnitude='large'
          maxWidth={null}
          placeholder='Enter email address'
          type='email'
          value={validatedEmail}
        />
        <Input
          ref={passwordRef}
          autoFocus
          label='Choose a new password'
          magnitude='large'
          maxWidth={null}
          message='At least 8 characters.'
          placeholder='Enter password'
          tone={passwordValidationError ? 'critical' : 'neutral'}
          type='password'
        />
        <Button type='submit' display='block' loading={isResettingPassword}>
          Set password
        </Button>
      </Stack>
    </form>
  );
};

export type PasswordResetNewPasswordProps = {
  validatedEmail?: string;
  onPasswordReset?: (password: string) => void;
  isResettingPassword?: boolean;
};

export { PasswordResetNewPassword };

import React from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import { createGlobalStyle } from 'styled-components';
import { ToastProvider, ThemeProvider, GlobalStyle } from '@kinesis/bungle';
import { store } from './store';
import { router } from './routes';

const ZooStyle = createGlobalStyle`
  body, #root {
    height: 100%;
    width: 100%;
    position: fixed;
  }
`;

if (window.location.hostname === 'zoo.app.kinesis.org') {
  if (['', '/'].includes(window.location.pathname)) {
    window.location.replace(`https://app.kinesis.org/inventories`);
  } else {
    window.location.replace(
      `https://app.kinesis.org${window.location.pathname}`,
    );
  }
}

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <ReduxProvider store={store}>
      <ThemeProvider>
        <ToastProvider>
          <GlobalStyle />
          <ZooStyle />
          <RouterProvider router={router} />
        </ToastProvider>
      </ThemeProvider>
    </ReduxProvider>
  </React.StrictMode>,
);

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { server } from './server';

const baseQuery = fetchBaseQuery({
  baseUrl: server,
  prepareHeaders: (headers, { getState }) => {
    const session = localStorage.getItem('kinesis:session');
    if (session) {
      headers.set('Authorization', `Bearer ${session}`);
    }
    headers.set('Accept', 'application/json');
    headers.set('Content-type', 'application/json');
    const states = getState();
    const entities = (states as any).api?.queries['getFlags(undefined)']?.data
      ?.entities;
    const flags = entities
      ? Object.keys(entities).filter((key) => entities[key].enabled === true)
      : [];
    headers.set('Kinesis-Feature-Flags', flags.join(','));
  },
});

const api = createApi({
  reducerPath: 'api',
  baseQuery,
  tagTypes: [],
  endpoints: () => ({}),
});

export { api };

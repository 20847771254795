import { useCallback, useState } from 'react';
import {
  Content,
  Stack,
  Button,
  Input,
  H2,
  H4,
  Text,
  Strong,
  Select,
} from '@kinesis/bungle';
import { noop, every, identity, isEmpty } from 'lodash/fp';
import { KinesisLogo } from '@/components/kinesis-logo';
import { Center } from '@/components/center';
import { AnchorText } from '@/components/anchor-text';

const dataStorageOptions = [
  { value: 'au', label: 'Australia' },
  { value: 'us', label: 'United States' },
];

const industryOptions = [
  { label: 'Manufacturing', value: 'Manufacturing' },
  { label: 'Retail', value: 'Retail' },

  { label: 'Financial services', value: 'Financial services' },
  {
    label: 'Mobility and transportation',
    value: 'Mobility and transportation',
  },
  {
    label: 'Property development and management',
    value: 'Property development and management',
  },
  { label: 'Public sector', value: 'Public sector' },
  { label: 'Utilities', value: 'Utilities' },
  { label: 'Other', value: 'Other' },
];

type DataStorage = 'au' | 'us';
type Industry = string | undefined;

const RegistrationOrganisation = (props: RegistrationOrganisationProps) => {
  const {
    onCreateOrganisation = noop,
    isCreating = false,
    email,
    name,
  } = props;

  const [dataStorage, setDataStorage] = useState<DataStorage>('au');
  const [industry, setIndustry] = useState<Industry>(undefined);
  const [industryError, setIndustryError] = useState(false);
  const validateIndustry = useCallback(() => {
    const valid = !isEmpty(industry);
    setIndustryError(!valid);
    return valid;
  }, [industry, setIndustryError]);

  const [organisation, setOrganisation] = useState('');
  const [organisationError, setOrganisationError] = useState(false);
  const validateOrganisation = useCallback(() => {
    const valid = !isEmpty(organisation);
    setOrganisationError(!valid);
    return valid;
  }, [organisation, setOrganisationError]);

  const onSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      const valid = every(identity, [
        validateOrganisation(),
        validateIndustry(),
      ]);
      if (valid) {
        onCreateOrganisation({
          name: organisation,
          industry: industry as string,
          dataStorage,
        });
      }
    },
    [
      validateOrganisation,
      validateIndustry,
      organisation,
      industry,
      dataStorage,
      onCreateOrganisation,
    ],
  );

  return (
    <Content background='white' borderRadiusSize='large' width={304}>
      <Stack space='none'>
        <Content padding='large' sizing='fill-container' paddingMode='equal'>
          <Stack space='medium'>
            <Center>
              <KinesisLogo />
            </Center>
            <Center>
              <H2>Join Kinesis</H2>
            </Center>
          </Stack>
        </Content>
        <Stack space='medium'>
          <Content
            background='gray'
            borderTop
            borderBottom
            sizing='fill-container'
            padding='medium'
            paddingMode='equal'
          >
            <Stack space='medium'>
              <H4>Your account</H4>
              <Stack space='none'>
                <Text>
                  <Strong>{name}</Strong>
                </Text>
                <Text>{email}</Text>
              </Stack>
            </Stack>
          </Content>

          <Content
            background='gray'
            borderTop
            sizing='fill-container'
            padding='medium'
            paddingMode='equal'
            borderRadiusSize='large'
            borderRadiusPlacement='bottom'
          >
            <form onSubmit={onSubmit} noValidate>
              <Stack space='large'>
                <Stack space='medium'>
                  <H4>Your organisation</H4>
                  <Input
                    autoFocus
                    label='Organisation name'
                    magnitude='large'
                    onBlur={validateOrganisation}
                    onChange={setOrganisation}
                    placeholder='Enter organisation name'
                    tone={organisationError ? 'critical' : 'neutral'}
                    type='text'
                    value={organisation}
                  />
                  <Select
                    onChange={setIndustry}
                    value={industry}
                    options={industryOptions}
                    placeholder='Select industry'
                    onBlur={validateIndustry}
                    tone={industryError ? 'critical' : 'neutral'}
                    label='Industry'
                  />

                  <Select
                    onChange={setDataStorage}
                    value={dataStorage}
                    options={dataStorageOptions}
                    label='Data storage location'
                    message={
                      <Text tone='secondary'>
                        This cannot be changed later.{' '}
                        <AnchorText
                          href='https://help.kinesis.org/en/articles/8637532-data-storage-location'
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          Learn More.
                        </AnchorText>
                      </Text>
                    }
                    disabled
                  />
                </Stack>
                <Button type='submit' display='block' loading={isCreating}>
                  Start using Kinesis
                </Button>
              </Stack>
            </form>
          </Content>
        </Stack>
      </Stack>
    </Content>
  );
};

export type NewOrganisation = {
  name: string;
  industry: string;
  dataStorage: string;
};

export type RegistrationOrganisationProps = {
  onCreateOrganisation?: (v: NewOrganisation) => void;
  isCreating?: boolean;
  name: string;
  email: string;
};

export { RegistrationOrganisation };

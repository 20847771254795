import { Icon } from '@kinesis/bungle';
import { useTheme } from 'styled-components';

const IconBillManagement = (props: IconBillManagementProps) => {
  const { magnitude = 'xlarge' } = props;
  const theme = useTheme() as any;
  return (
    <Icon type='datasets' magnitude={magnitude} color={theme.color.gray7} />
  );
};

export type IconBillManagementProps = {
  magnitude?: string;
};

export { IconBillManagement };

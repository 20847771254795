import { Checkbox, Stack } from '@kinesis/bungle';

import type { Flag } from '../../types';

const FeatureFlag = (props: FeatureFlagProps) => {
  const { id, enabled, editable, name, description, toggle } = props;
  return (
    <Stack>
      <Checkbox
        key={id}
        checked={enabled}
        disabled={!editable}
        onChange={toggle}
        title={name}
      >
        {description}
      </Checkbox>
    </Stack>
  );
};

export type FeatureFlagProps = {
  toggle: () => void;
} & Flag;
export { FeatureFlag };

import {
  differenceInSeconds,
  formatDistanceStrictWithOptions,
  parseISO,
  format,
} from 'date-fns/fp';

const formatDistanceStrict = formatDistanceStrictWithOptions({
  addSuffix: true,
});

const formatDistance = (dateLeft: Date, dateRight: Date) => {
  if (Math.abs(differenceInSeconds(dateRight, dateLeft)) < 30) {
    return 'just now';
  }
  return formatDistanceStrict(dateLeft, dateRight);
};

const formatDistanceStrings = (dateLeft: string, dateRight: string) =>
  formatDistance(parseISO(dateLeft), parseISO(dateRight));

const formatDate = (date: Date) => format('d MMM yyyy, p', date);

const formatDateString = (date: string) => formatDate(parseISO(date));

export { formatDistance, formatDistanceStrings, formatDate, formatDateString };

import { Stack, Text, Icon } from '@kinesis/bungle';
import { Center } from '@/components/center';
import { TextAlignCenter } from '@/components/text-align-center';

const PasswordResetConfirmation = () => {
  return (
    <Stack space='medium'>
      <Center>
        <Icon type='check-circle' color='#74D487' magnitude='xlarge' />
      </Center>
      <TextAlignCenter>
        <Stack space='none'>
          <Text>You’ll be back up and running shortly.</Text>
          <Text>Check your email to continue.</Text>
        </Stack>
      </TextAlignCenter>
    </Stack>
  );
};

export type PasswordResetConfirmationProps = {};

export { PasswordResetConfirmation };

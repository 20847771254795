import { useState, useCallback } from 'react';
import {
  Avatar,
  ConfirmationDialog,
  Stack,
  Inline,
  InlineItem,
  Text,
  Strong,
  UtilityButton,
  DropdownMenu,
  DropdownMenuButton,
  DropdownMenuItem,
  DropdownMenuList,
  DropdownMenuSeparator,
} from '@kinesis/bungle';
import { InvitedMemberAvatar } from './organisation-member.styles';
import { Member } from '@/types';
import { noop } from 'lodash/fp';

const OrganisationMember = (props: OrganisationMemberProps) => {
  const {
    isMe = false,
    isEditor = false,
    canLeave = false,
    member,
    organisationName,
    onSetMember = noop,
    onSetAdmin = noop,
    onRemoveAccess = noop,
    onResendInvitation = noop,
    onCancelInvitation = noop,
  } = props;

  const [
    showRemoveAccessConfirmationModal,
    setShowRemoveAccessConfirmationModal,
  ] = useState(false);

  const [
    showCancelInvitationConfirmationModal,
    setShowCancelInvitationConfirmationModal,
  ] = useState(false);

  const [showSetMemberConfirmationModal, setShowSetMemberConfirmationModal] =
    useState(false);

  const closeRemoveModal = useCallback(() => {
    setShowRemoveAccessConfirmationModal(false);
  }, [setShowRemoveAccessConfirmationModal]);

  const closeCancelInvitationModal = useCallback(() => {
    setShowCancelInvitationConfirmationModal(false);
  }, [setShowCancelInvitationConfirmationModal]);

  const closeSetMemberModal = useCallback(() => {
    setShowSetMemberConfirmationModal(false);
  }, []);

  const handleSetMember = useCallback(() => {
    if (isMe && !canLeave) {
      return setShowSetMemberConfirmationModal(true);
    }
    onSetMember(member);
  }, [canLeave, isMe, member, onSetMember]);

  const handleSetAdmin = useCallback(() => {
    onSetAdmin(member);
  }, [member, onSetAdmin]);

  const handleRemoveAccess = useCallback(() => {
    setShowRemoveAccessConfirmationModal(true);
  }, [setShowRemoveAccessConfirmationModal]);

  const removeAccess = useCallback(() => {
    onRemoveAccess(member);
  }, [member, onRemoveAccess]);

  const handleResendInvitation = useCallback(() => {
    onResendInvitation(member);
  }, [member, onResendInvitation]);

  const handleCancelInvitation = useCallback(() => {
    setShowCancelInvitationConfirmationModal(true);
  }, [setShowCancelInvitationConfirmationModal]);

  const cancelInvitation = useCallback(() => {
    onCancelInvitation(member);
  }, [member, onCancelInvitation]);

  if (member.membership.status === 'active') {
    return (
      <Inline>
        <InlineItem>
          <Avatar magnitude='small' variant='individual'>
            {member.initials}
          </Avatar>
        </InlineItem>
        <InlineItem sizing='fill-container'>
          <Stack space='none'>
            <Text>
              <Strong>
                {member.name}
                {isMe ? ' (You)' : ''}
              </Strong>
            </Text>
            <Text tone='secondary'>
              {member.email}
              {member.jobTitle ? ` • ${member.jobTitle}` : ''}
            </Text>
          </Stack>
        </InlineItem>
        <InlineItem>
          {showRemoveAccessConfirmationModal && isMe && !canLeave && (
            <ConfirmationDialog
              confirmText='OK'
              icon='exclamation'
              title={`You are the only admin of “${organisationName}”.`}
              onCancel={closeRemoveModal}
              onConfirm={closeRemoveModal}
              showCancelButton={false}
              variant='primary'
              minWidth={320}
            >
              To leave this organisation, make sure another person can
              administer it.
            </ConfirmationDialog>
          )}
          {showSetMemberConfirmationModal && isMe && !canLeave && (
            <ConfirmationDialog
              confirmText='OK'
              icon='exclamation'
              title={`You are the only admin of “${organisationName}”.`}
              onCancel={closeSetMemberModal}
              onConfirm={closeSetMemberModal}
              showCancelButton={false}
              variant='primary'
              minWidth={320}
            >
              To set yourself as a member of this organisation, make sure
              another person can administer it.
            </ConfirmationDialog>
          )}
          {showRemoveAccessConfirmationModal && isMe && canLeave && (
            <ConfirmationDialog
              cancelText='Don’t leave'
              confirmText='Leave organisation'
              icon='question'
              title={`Leave “${organisationName}”?`}
              onCancel={closeRemoveModal}
              onConfirm={removeAccess}
              variant='danger'
              minWidth={320}
            >
              You will no longer be a member of this organisation.
            </ConfirmationDialog>
          )}
          {showRemoveAccessConfirmationModal && !isMe && (
            <ConfirmationDialog
              cancelText='Don’t remove'
              confirmText='Remove access'
              icon='question'
              title={`Remove access for “${member.firstName} ${member.lastName}”?`}
              onCancel={closeRemoveModal}
              onConfirm={removeAccess}
              variant='danger'
              minWidth={320}
            >
              They will lose access to this organisation.
            </ConfirmationDialog>
          )}
          <DropdownMenu justify='end'>
            <DropdownMenuButton
              as={UtilityButton}
              dropdown
              inline
              magnitude='xsmall'
              disabled={!isMe && !isEditor}
            >
              <span>
                {member.membership.permissions.editor ? 'Admin' : 'Member'}
              </span>
            </DropdownMenuButton>
            <DropdownMenuList>
              {isEditor && (
                <DropdownMenuItem
                  onSelect={handleSetAdmin}
                  variant={
                    member.membership.permissions.editor ? 'accent' : undefined
                  }
                >
                  Admin
                </DropdownMenuItem>
              )}
              <DropdownMenuItem
                onSelect={handleSetMember}
                variant={
                  !member.membership.permissions.editor ? 'accent' : undefined
                }
              >
                Member
              </DropdownMenuItem>
              <DropdownMenuSeparator />
              <DropdownMenuItem onSelect={handleRemoveAccess} variant='danger'>
                {isMe ? `Leave “${organisationName}”` : 'Remove access'}
              </DropdownMenuItem>
            </DropdownMenuList>
          </DropdownMenu>
        </InlineItem>
      </Inline>
    );
  }

  if (member.membership.status === 'pending') {
    return (
      <Inline>
        <InlineItem>
          <InvitedMemberAvatar />
        </InlineItem>
        <InlineItem sizing='fill-container'>
          <Stack space='none'>
            <Text>{member.email}</Text>
            {member.membership.invitationExpired && (
              <Text tone='critical'>Invitation expired</Text>
            )}
            {!member.membership.invitationExpired && (
              <Text tone='secondary'>Pending invitation</Text>
            )}
          </Stack>
        </InlineItem>
        <InlineItem>
          {showCancelInvitationConfirmationModal && (
            <ConfirmationDialog
              cancelText='Don’t cancel'
              confirmText='Cancel invitation'
              icon='question'
              title={`Cancel invitation for “${member.email}”?`}
              onCancel={closeCancelInvitationModal}
              onConfirm={cancelInvitation}
              variant='primary'
              minWidth={320}
            >
              They will not be able to join this organisation.
            </ConfirmationDialog>
          )}
          <DropdownMenu justify='end'>
            <DropdownMenuButton
              as={UtilityButton}
              dropdown
              inline
              magnitude='xsmall'
              disabled={!isMe && !isEditor}
            >
              <span>
                {member.membership.permissions.editor ? 'Admin' : 'Member'}
              </span>
            </DropdownMenuButton>
            <DropdownMenuList>
              <DropdownMenuItem
                onSelect={handleSetAdmin}
                variant={
                  member.membership.permissions.editor ? 'accent' : undefined
                }
              >
                Admin
              </DropdownMenuItem>
              <DropdownMenuItem
                onSelect={handleSetMember}
                variant={
                  !member.membership.permissions.editor ? 'accent' : undefined
                }
              >
                Member
              </DropdownMenuItem>
              <DropdownMenuSeparator />
              <DropdownMenuItem onSelect={handleResendInvitation}>
                Resend invitation
              </DropdownMenuItem>
              <DropdownMenuItem
                onSelect={handleCancelInvitation}
                variant='danger'
              >
                Cancel invitation
              </DropdownMenuItem>
            </DropdownMenuList>
          </DropdownMenu>
        </InlineItem>
      </Inline>
    );
  }

  return null;
};

export type OrganisationMemberProps = {
  isEditor?: boolean;
  isMe?: boolean;
  canLeave?: boolean;
  member: Member;
  organisationName: string;
  onSetMember?: (member: Member) => void;
  onSetAdmin?: (member: Member) => void;
  onRemoveAccess?: (member: Member) => void;
  onResendInvitation?: (member: Member) => void;
  onCancelInvitation?: (member: Member) => void;
};

export { OrganisationMember };

import { useCallback, useState } from 'react';
import { Content, Stack, Button, Input, H2, Text } from '@kinesis/bungle';
import { noop } from 'lodash/fp';
import { KinesisLogo } from '@/components/kinesis-logo';
import { Center } from '@/components/center';
import { LinkText } from '@/components/link-text';

const RegistrationRequest = (props: RegistrationRequestProps) => {
  const { onRegister = noop, isRegistering = false } = props;
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const validateEmail = useCallback(() => {
    // intentionally very weak validation, verification email is
    // a better check, this just is to sense check for a typo or
    // accidental submit.
    const valid = email.length >= 3 && email.includes('@');
    setEmailError(!valid);
    return valid;
  }, [email, setEmailError]);

  const onRegisterSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      const valid = validateEmail();
      if (valid) {
        onRegister({
          email,
        });
      }
    },
    [onRegister, validateEmail, email],
  );

  return (
    <Content background='white' borderRadiusSize='large' width={304}>
      <Stack space='none'>
        <Content padding='large' sizing='fill-container' paddingMode='equal'>
          <Stack space='medium'>
            <Center>
              <KinesisLogo />
            </Center>
            <Center>
              <H2>Join Kinesis</H2>
            </Center>
          </Stack>
        </Content>
        <Content
          background='gray'
          borderTop
          sizing='fill-container'
          padding='medium'
          paddingMode='equal'
          borderRadiusSize='large'
          borderRadiusPlacement='bottom'
        >
          <form onSubmit={onRegisterSubmit} noValidate>
            <Stack space='large'>
              <Stack space='medium'>
                <Input
                  label='Work email address'
                  magnitude='large'
                  placeholder='Enter work email address'
                  value={email}
                  onChange={setEmail}
                  onBlur={validateEmail}
                  autoFocus
                  type='email'
                  tone={emailError ? 'critical' : 'neutral'}
                />
              </Stack>
              <Stack space='medium'>
                <Button type='submit' display='block' loading={isRegistering}>
                  Continue
                </Button>
                <Center>
                  <Text>
                    Already have an account? <LinkText to='/'>Log in.</LinkText>
                  </Text>
                </Center>
              </Stack>
            </Stack>
          </form>
        </Content>
      </Stack>
    </Content>
  );
};

export type RegistrationEmail = {
  email: string;
};
export type RegistrationRequestProps = {
  onRegister?: (v: RegistrationEmail) => void;
  isRegistering?: boolean;
};

export { RegistrationRequest };

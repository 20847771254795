import { useCallback, useState } from 'react';
import {
  Inline,
  InlineItem,
  Text,
  UtilityButton,
  Tooltip,
} from '@kinesis/bungle';
import { noop } from 'lodash/fp';
import { formatDistanceStrings, formatDateString } from '@/date';
import { AccessKeyDeleteConfirmation } from './access-key-delete-confirmation';

const AccessKeyItem = (props: AccessKeyItemProps) => {
  const { id, created, now, onDelete = noop } = props;
  const distance = formatDistanceStrings(now, created);
  const date = formatDateString(created);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const onDeleteKey = useCallback(() => {
    onDelete(id);
  }, [id, onDelete]);
  const onShowConfirmDeleteKey = useCallback(() => {
    setShowDeleteConfirmation(true);
  }, [setShowDeleteConfirmation]);
  const onCloseConfirmDeleteKey = useCallback(() => {
    setShowDeleteConfirmation(false);
  }, [setShowDeleteConfirmation]);

  return (
    <Inline>
      <InlineItem sizing='fill-container'>
        <Text>
          Key generated{' '}
          <Tooltip title={date} placement='top'>
            <span>{distance}</span>
          </Tooltip>
        </Text>
      </InlineItem>
      <InlineItem>
        {showDeleteConfirmation && (
          <AccessKeyDeleteConfirmation
            onDeleteKey={onDeleteKey}
            onClose={onCloseConfirmDeleteKey}
          />
        )}
        <Tooltip title='Delete key' placement='top'>
          <UtilityButton
            icon='delete'
            magnitude='small'
            variant='danger'
            onClick={onShowConfirmDeleteKey}
          />
        </Tooltip>
      </InlineItem>
    </Inline>
  );
};

export type AccessKeyItemProps = {
  id: string;
  created: string;
  now: string;
  onDelete?: (id: string) => void;
};

export { AccessKeyItem };

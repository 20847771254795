import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query/react';
import { api } from '@/api/api';
import { errors } from '@/middleware/errors';

const createStore = ({ initialState }: any = {}) => {
  const store = configureStore({
    reducer: {
      [api.reducerPath]: api.reducer,
    } as any, // TODO: fix this
    preloadedState: initialState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(api.middleware, errors),
    devTools: true,
  });

  setupListeners(store.dispatch);

  return store;
};

export { createStore };

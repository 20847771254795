import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { Toaster, Content, Container, Frame } from '@kinesis/bungle';
import { SettingsMenu } from '@/components/settings-menu';
import {
  useListOrganisationsQuery,
  organisationsSelector,
} from '@/api/organisation';
import { flagsSelector } from '@/api/flags';
import { useSettingsSelection } from '@/hooks/use-settings-selection';

const Settings = () => {
  const selection = useSettingsSelection();
  useListOrganisationsQuery();
  const organisations = useSelector(organisationsSelector);
  const flagsList = useSelector(flagsSelector);
  const flags = useMemo(
    () =>
      Object.fromEntries(flagsList.map(({ name, enabled }) => [name, enabled])),
    [flagsList],
  );

  return (
    <Container>
      <Toaster id='globalTop' placement='top' />
      <Frame borderRight sizing='fit-content'>
        <Content width={304} padding='medium' minHeight='100%'>
          <SettingsMenu
            organisations={organisations}
            selection={selection}
            flags={flags}
          />
        </Content>
      </Frame>
      <Frame>
        <Outlet />
      </Frame>
    </Container>
  );
};

export { Settings };

import { Radio, RadioGroup, Stack, Text } from '@kinesis/bungle';
import { noop } from 'lodash/fp';
import { Layout } from '@/components/layout';

const Analytics = (props: AnalyticsProps) => {
  const { analytics, onUpdateAnalytics = noop } = props;
  return (
    <Layout variant='narrow'>
      <Stack space='medium'>
        <Text>
          Help us improve Kinesis by allowing full analytics of your usage data.
          We will never share personal information outside of Kinesis.
        </Text>
        <RadioGroup
          autoFocus
          hideLegend
          legend='Choose Analytics setting'
          onChange={onUpdateAnalytics}
          value={analytics}
        >
          <Stack space='xsmall'>
            <Radio title='Limited analytics' value='limited'>
              We collect high-level events such as when people log in or use
              particular features. Your data and inputs are not collected.
            </Radio>
            <Radio title='Full analytics' value='full'>
              We collect high-level events, the inputs you provide, and the
              screens that you see. This allows us to identify usability issues
              and diagnose any bugs that arise.
            </Radio>
          </Stack>
        </RadioGroup>
      </Stack>
    </Layout>
  );
};

type AnalyticsProps = {
  analytics?: 'full' | 'limited';
  onUpdateAnalytics: (analytics: 'full' | 'limited') => void;
};

export { Analytics };

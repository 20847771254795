import styled from 'styled-components';
import { Select, Content, Inline, InlineItem } from '@kinesis/bungle';
import { noop } from 'lodash/fp';
import { DefaultGridProduct, Product, ProductLaunchPreference } from '@/types';
import { ProductIcon } from '@/components/product-icon';
import { productName } from '@/data/products';

const DefaultGridRowLayout = styled.div({
  display: 'flex',
  flexDirection: 'row',
  '& > *': {
    flex: '1 1 50%',
  },
});

const DefaultGridRow = (props: DefaultGridRowProps) => {
  const {
    product,
    onChange = noop,
    defaultExpanded = false,
    currentUserCanEdit,
  } = props;

  return (
    <DefaultGridRowLayout key={product.product}>
      <Content sizing='fill-container' background='gray' padding='none'>
        <Inline heightMode='fill-container' space='small'>
          <InlineItem>
            <ProductIcon product={product.product} magnitude='small' />
          </InlineItem>
          <InlineItem sizing='fill-container'>
            {productName(product.product)}
          </InlineItem>
        </Inline>
      </Content>
      <Select
        defaultExpanded={defaultExpanded}
        appearance='cell'
        groupVariant='divider'
        groupKey='value.type'
        value={product.selection}
        onChange={(v: ProductLaunchPreference) => onChange(product.product, v)}
        options={product.options}
        disabled={!currentUserCanEdit}
      />
    </DefaultGridRowLayout>
  );
};

export type DefaultGridRowProps = {
  product: DefaultGridProduct;
  onChange?: (product: Product, selection: ProductLaunchPreference) => void;
  defaultExpanded?: boolean;
  currentUserCanEdit: boolean;
};

export { DefaultGridRow };

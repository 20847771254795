import {
  EntityState,
  createSelector,
  createEntityAdapter,
} from '@reduxjs/toolkit';
import type { Flag } from '@/types';
import { api } from './api';

const flags = createEntityAdapter<Flag, string>({
  selectId: (flag: Flag) => flag.name,
  sortComparer: (a: Flag, b: Flag) => a.name.localeCompare(b.name),
});

const initialState = flags.getInitialState();

const flagApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getFlags: builder.query<EntityState<Flag, string>, void>({
      query: () => `flags`,
      transformResponse: (response: any) =>
        flags.setAll(initialState, response.flags),
    }),
  }),
});

const flagEntitiesSelector = createSelector(
  flagApi.endpoints.getFlags.select(),
  (result) => result?.data ?? initialState,
);

const toggleFlag = (name: string) =>
  flagApi.util.updateQueryData('getFlags', undefined, (f) => {
    if (f.entities[name]) {
      const flag = f.entities[name] as Flag;
      flag.enabled = !flag.enabled;
    }
  });

export { flagEntitiesSelector, toggleFlag };

export const {
  selectById: flagByIdSelector,
  selectIds: flagIdsSelector,
  selectAll: flagsSelector,
} = flags.getSelectors(flagEntitiesSelector);
export const { useGetFlagsQuery } = flagApi;

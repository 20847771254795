import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Organisation } from '@/types';
import { useDefaultOrganisationOptionsQuery } from '@/api/user';
import {
  useListOrganisationsQuery,
  organisationsSelector,
} from '@/api/organisation';

const useDefaultOrganisationOptions = (): {
  data: Organisation[];
  isLoading: boolean;
} => {
  const { isLoading: organisationsLoading } = useListOrganisationsQuery();
  const organisations = useSelector(organisationsSelector);

  const {
    data: defaultOrganisations,
    isLoading: defaultOrganisationOptionsLoading,
  } = useDefaultOrganisationOptionsQuery();

  const defaultOrganisationOptions: Organisation[] = useMemo(
    () =>
      organisations.filter(({ id }) =>
        (defaultOrganisations ?? []).some((id2) => id == id2.id),
      ),
    [organisations, defaultOrganisations],
  );

  return {
    data: defaultOrganisationOptions,
    isLoading: organisationsLoading || defaultOrganisationOptionsLoading,
  };
};

export { useDefaultOrganisationOptions };

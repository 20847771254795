import { Stack } from '@kinesis/bungle';
import { PendingInvitationRow } from './pending-invitation-row';
import { InvitationData } from '@/types';

const PendingInvitationList = (props: PendingInvitationListProps) => {
  const { onAccept, onDecline, invitations } = props;
  return (
    <Stack space='small'>
      {invitations.map((invitation) => {
        return (
          <PendingInvitationRow
            onAccept={onAccept}
            onDecline={onDecline}
            invitation={invitation}
          />
        );
      })}
    </Stack>
  );
};

export type PendingInvitationListProps = {
  onAccept?: (token: string) => void;
  onDecline?: (token: string) => void;
  invitations: InvitationData[];
};

export { PendingInvitationList };

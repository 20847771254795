import type { Entity, TermsItem } from '@/types';
import { api } from './api';

const termsApi = api
  .enhanceEndpoints({
    addTagTypes: ['TermsItem'],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getPendingTerms: builder.query<TermsItem[], Entity>({
        query: ({ id: entityId, namespace }) =>
          `customer-terms-pending/${namespace}/${entityId}`,
        providesTags: (result) =>
          result
            ? [
                ...result.map(({ id }) => ({ type: 'TermsItem' as const, id })),
                'TermsItem',
              ]
            : ['TermsItem'],
      }),
      acceptTerms: builder.mutation<void, { id: number; entity: Entity }>({
        query: ({ id }) => ({
          url: `customer-terms-accept/${id}`,
          method: 'POST',
          body: {},
        }),
        onQueryStarted: async (
          { id, entity },
          { dispatch, queryFulfilled },
        ) => {
          const patchResult = dispatch(
            termsApi.util.updateQueryData(
              'getPendingTerms',
              entity,
              (pendingTerms) => {
                pendingTerms.forEach((terms) => {
                  if (terms.id === id) {
                    terms.accepted = true;
                  }
                });
              },
            ),
          );
          queryFulfilled.catch(patchResult.undo);
        },
      }),
    }),
  });

export const declineTerms = (id: number, entity: Entity) =>
  termsApi.util.updateQueryData('getPendingTerms', entity, (entities) => {
    entities.forEach((terms) => {
      if (terms.id === id) {
        terms.declined = true;
      }
    });
  });

export const reviewUnacceptedTerms = (entity: Entity) =>
  termsApi.util.updateQueryData('getPendingTerms', entity, (entities) => {
    const unacceptedTerms = entities.filter((terms) => !terms.accepted);
    const updatedTerms = unacceptedTerms.map((terms) => {
      return {
        ...terms,
        declined: false,
      };
    });
    return updatedTerms;
  });

export const { useAcceptTermsMutation, useGetPendingTermsQuery } = termsApi;

import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSettingsSelection } from '@/hooks/use-settings-selection';
import { useNow } from '@/hooks/use-now';
import { useMeQuery } from '@/api/user';
import {
  useListServiceAccountsQuery,
  accountsSelector,
  useListOrganisationsQuery,
  organisationByIdSelector,
  useNewServiceAccountAccessKeyMutation,
  useNewServiceAccountMutation,
  useDeleteServiceAccountMutation,
  useDeleteServiceAccountAccessKeyMutation,
} from '@/api/organisation';
import { Loading } from '@/components/loading';
import { OrganisationServiceAccounts } from '@/components/organisation-service-accounts';
import { NewServiceAccount, ServiceAccount, AccessKeySecret } from '@/types';

const SettingsOrganisationServiceAccounts = () => {
  const now = useNow();
  const { id } = useSettingsSelection() as any;
  const { isLoading: isLoadingOrganisation } = useListOrganisationsQuery();
  const organisation = useSelector((state: any) =>
    organisationByIdSelector(state, id),
  );
  const { data: me, isLoading: isLoadingMe } = useMeQuery();
  const { isLoading } = useListServiceAccountsQuery(id);
  const accounts = useSelector(accountsSelector(id));
  const [createNewAccessKey] = useNewServiceAccountAccessKeyMutation();
  const [createNewServiceAccount] = useNewServiceAccountMutation();
  const [deleteServiceAccount] = useDeleteServiceAccountMutation();
  const [deleteServiceAccountAccessKey] =
    useDeleteServiceAccountAccessKeyMutation();

  const [newAccessKey, setNewAccessKey] = useState<
    undefined | AccessKeySecret
  >();
  const onNewAccessKey = useCallback(
    async (account: ServiceAccount) => {
      const accessKey = await createNewAccessKey({
        accountId: account.id,
        organisationId: id,
      }).unwrap();
      setNewAccessKey(accessKey);
    },
    [id, setNewAccessKey, createNewAccessKey],
  );
  const onDismissNewAccessKey = useCallback(() => {
    setNewAccessKey(undefined);
  }, [setNewAccessKey]);

  const onNewServiceAccount = useCallback(
    async (account: NewServiceAccount) => {
      await createNewServiceAccount({
        organisationId: id,
        account: account,
      }).unwrap();
    },
    [createNewServiceAccount, id],
  );

  const onDeleteServiceAccount = useCallback(
    async (account: ServiceAccount) => {
      await deleteServiceAccount({
        accountId: account.id,
        organisationId: id,
      }).unwrap();
    },
    [id, deleteServiceAccount],
  );

  const onDeleteServiceAccountAccessKey = useCallback(
    async (accountId: number, keyId: string) => {
      await deleteServiceAccountAccessKey({
        accountId,
        organisationId: id,
        keyId,
      }).unwrap();
    },
    [id, deleteServiceAccountAccessKey],
  );

  if (
    !isLoading &&
    !isLoadingOrganisation &&
    !isLoadingMe &&
    accounts &&
    organisation &&
    me
  ) {
    return (
      <OrganisationServiceAccounts
        key={id}
        organisationId={id}
        now={now}
        me={me}
        accounts={accounts}
        onNewAccessKey={onNewAccessKey}
        onDismissNewAccessKey={onDismissNewAccessKey}
        newAccessKey={newAccessKey}
        onCreate={onNewServiceAccount}
        onDelete={onDeleteServiceAccount}
        onDeleteKey={onDeleteServiceAccountAccessKey}
      />
    );
  } else {
    return <Loading />;
  }
};

export { SettingsOrganisationServiceAccounts };

import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useToast } from '@kinesis/bungle';
import { Center } from '@/components/center';
import { PasswordResetLayout } from '@/components/password-reset-layout';
import { PasswordResetForm } from '@/components/password-reset-form';
import { PasswordResetConfirmation } from '@/components/password-reset-confirmation';
import { PasswordResetNewPassword } from '@/components/password-reset-new-password';
import {
  usePasswordResetMutation,
  usePasswordResetValidateMutation,
  usePasswordResetPerformMutation,
} from '@/api/user';
import { getLastLogin } from '@/services/last-login';

const PasswordReset = () => {
  const toast = useToast('globalTop');
  const lastLogin = getLastLogin();
  const { state } = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const defaultEmail = state?.email ?? lastLogin;
  const [validatedEmail, setValidatedEmail] = useState<string | undefined>(
    undefined,
  );
  const [resetComplete, setResetComplete] = useState(false);
  const [passwordReset, { isLoading: isResettingPassword }] =
    usePasswordResetMutation();
  const [passwordResetValidate] = usePasswordResetValidateMutation();
  const [passwordResetPerform, { isLoading: isSettingNewPassword }] =
    usePasswordResetPerformMutation();

  const onPasswordResetRequest = useCallback(
    async (email: string) => {
      try {
        await passwordReset(email).unwrap();
        setResetComplete(true);
      } catch (e) {
        toast('We couldn’t reset your password. Please try again.', {
          variant: 'error',
        });
      }
    },
    [toast, passwordReset, setResetComplete],
  );

  const onPasswordResetPerform = useCallback(
    async (password: string) => {
      if (!token) return;

      try {
        await passwordResetPerform({ token, password }).unwrap();
        toast('Your new password has been set. Log in to continue.', {
          variant: 'success',
          duration: 6000,
        });
      } catch (e) {
        toast(
          'Your password reset link has expired. Request a new one to reset your password.',
          {
            variant: 'error',
            duration: 6000,
          },
        );
        setValidatedEmail(undefined);
      } finally {
        navigate('/');
      }
    },
    [navigate, passwordResetPerform, toast, token],
  );

  const onValidateResetToken = useCallback(
    async (resetToken: string) => {
      try {
        const validateResponse =
          await passwordResetValidate(resetToken).unwrap();
        setValidatedEmail(validateResponse.email);
      } catch (e) {
        toast(
          'Your password reset link has expired. Request a new one to reset your password.',
          {
            variant: 'error',
            duration: 6000,
          },
        );
        setValidatedEmail(undefined);
        navigate('/');
      }
    },
    [navigate, toast, passwordResetValidate],
  );

  useEffect(() => {
    if (token && !validatedEmail) {
      onValidateResetToken(token);
    }
  }, [onValidateResetToken, token, validatedEmail]);

  return (
    <Center>
      <PasswordResetLayout showBackToLogin={!token}>
        {!token && resetComplete && <PasswordResetConfirmation />}
        {!token && !resetComplete && (
          <PasswordResetForm
            defaultEmail={defaultEmail}
            onPasswordReset={onPasswordResetRequest}
            isResettingPassword={isResettingPassword}
          />
        )}
        {token && (
          <PasswordResetNewPassword
            validatedEmail={validatedEmail}
            onPasswordReset={onPasswordResetPerform}
            isResettingPassword={isSettingNewPassword}
          />
        )}
      </PasswordResetLayout>
    </Center>
  );
};

export { PasswordReset };

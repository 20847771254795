import { useEffect, useCallback, useState } from 'react';
import { isEmpty } from 'lodash/fp';
import { SwitcherModal } from '@kinesis/spotlight-ui';
import { useGetFlagsQuery } from '@/api/flags';
import {
  useRecentEntitiesQuery,
  useSearchEntitiesQuery,
  useEntityTypesQuery,
  getEntityUrl,
  getEntityTypeUrl,
} from '@/api/entities';
import { useSessionQuery } from '@/api/login';

const emptyList: any = [];

const StandaloneSwitcher = () => {
  const { isError, isLoading } = useSessionQuery();

  const [show, setShow] = useState(false);

  const { isSuccess: flagsFetched } = useGetFlagsQuery();

  const [entitySearch, setEntitySearch] = useState('');
  const { data: searchResponse } = useSearchEntitiesQuery(entitySearch, {
    skip: isEmpty(entitySearch),
  });
  const { data: entityTypesResponse } = useEntityTypesQuery(undefined, {
    skip: !flagsFetched,
  });
  const { data: recentEntitiesResponse } = useRecentEntitiesQuery(undefined, {
    skip: !flagsFetched,
  });
  const onCloseSwitcherModal = useCallback(() => {
    window.parent.postMessage('close', '*');
    setShow(false);
  }, []);
  const onLogoutSwitcherModal = useCallback(() => {
    window.parent.postMessage('logout', '*');
  }, []);

  const searchEntities = isEmpty(entitySearch)
    ? emptyList
    : searchResponse?.results;
  const recentEntities = recentEntitiesResponse || emptyList;
  const entityTypes = entityTypesResponse || emptyList;

  useEffect(() => {
    const f = (e: any) => {
      if (e?.data === 'show') {
        setShow(true);
      }
      if (e?.data === 'hide') {
        setShow(false);
      }
    };
    window.addEventListener('message', f, false);
    return () => window.removeEventListener('message', f);
  }, []);

  if (isLoading || !show) {
    return null;
  }

  if (!isLoading && isError) {
    onLogoutSwitcherModal();
    return null;
  }

  return (
    <SwitcherModal
      width={496}
      height={600}
      minHeight={320}
      onClose={onCloseSwitcherModal}
      onSearch={setEntitySearch}
      entityTypeList={entityTypes}
      searchResultList={searchEntities}
      recentList={recentEntities}
      getEntityUrl={getEntityUrl}
      getEntityTypeUrl={getEntityTypeUrl}
    />
  );
};

export { StandaloneSwitcher };

import styled from 'styled-components';
import { Link } from 'react-router-dom';

const LinkText = styled(Link)(({ theme }) => ({
  fontSize: theme.fontSize.md,
  fontWeight: theme.lineHeight.normal,
  lineHeight: theme.lineHeight.md,
  color: theme.color.blue6,
  ':hover': {
    color: theme.color.blue7,
  },
  ':active': {
    color: theme.color.blue7,
  },
}));

export { LinkText };

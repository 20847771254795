import {
  Anchor,
  Avatar,
  Button,
  UtilityButton,
  Tooltip,
  DropdownMenu,
  DropdownMenuText,
  DropdownMenuButton,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuList,
  H3,
  H2,
  Inline,
  InlineItem,
  Modal,
  Frame,
  Container,
  Header,
  Footer,
  Content,
} from '@kinesis/bungle';
import {
  Toolbar,
  ToolbarItem,
  ToolbarGroupLeft,
  ToolbarGroupRight,
} from '@kinesis/bungle/legacy';
import { shortcuts } from '@/shortcuts';
import { useState, useCallback } from 'react';
import type { Session, Flag } from '@/types';
import { Link } from 'react-router-dom';
import { Layout } from './layout';
import { Logo } from '@/components/logo';
import { noop } from 'lodash/fp';
import { FeatureFlagCard } from '@/components/feature-flags';
import {
  PaddingDiv,
  ToolbarLeft,
  ToolbarRight,
} from './primary-navigation.styles';

const PrimaryNavigation = (props: PrimaryNavigationProps) => {
  const {
    enableFlags = false,
    onLogout = noop,
    session,
    flags,
    onToggleFlag,
    onOpenSwitcher = noop,
  } = props;
  const [flagModal, setFlagModal] = useState(false);
  const onOpenFlagModal = useCallback(() => {
    setFlagModal(true);
  }, [setFlagModal]);

  const onCloseFlagModal = useCallback(() => {
    setFlagModal(false);
  }, [setFlagModal]);
  return (
    <Content>
      {flagModal && (
        <Modal
          aria-label='Feature flags'
          onClose={onCloseFlagModal}
          width={496}
          height={600}
          minHeight={320}
        >
          <Container>
            <Header border>
              <Toolbar>
                <ToolbarGroupLeft>
                  <ToolbarItem>
                    <Content>
                      <PaddingDiv>
                        <H2>Feature flags</H2>
                      </PaddingDiv>
                    </Content>
                  </ToolbarItem>
                </ToolbarGroupLeft>
              </Toolbar>
            </Header>
            <Frame>
              <FeatureFlagCard
                featureFlagList={flags}
                onToggleFlag={onToggleFlag}
              />
            </Frame>

            <Footer border>
              <Toolbar>
                <ToolbarGroupRight>
                  <Button onClick={onCloseFlagModal}>Done</Button>
                </ToolbarGroupRight>
              </Toolbar>
            </Footer>
          </Container>
        </Modal>
      )}
      <Layout>
        <ToolbarLeft>
          <Inline>
            <InlineItem>
              <Anchor onClick={onOpenSwitcher}>
                <Logo />
              </Anchor>
            </InlineItem>
            <InlineItem>
              <UtilityButton
                as={Link}
                to='/settings/'
                displayMode='dark'
                variant='accent'
                icon='setting-filled'
              >
                Settings
              </UtilityButton>
            </InlineItem>
          </Inline>
        </ToolbarLeft>
        <ToolbarRight>
          <Inline space='small'>
            <InlineItem>
              <Tooltip
                title={`Switch to… (${shortcuts.SWITCHER.mnemonic})`}
                placement='bottom'
                offset={8}
              >
                <UtilityButton
                  icon='switcher'
                  displayMode='dark'
                  onClick={onOpenSwitcher}
                  keyboardShortcut={shortcuts.SWITCHER.handler}
                >
                  Switch to…
                </UtilityButton>
              </Tooltip>
            </InlineItem>
            {enableFlags && (
              <InlineItem>
                <Tooltip title='Feature flags' placement='bottom' offset={8}>
                  <UtilityButton
                    icon='flag'
                    displayMode='dark'
                    onClick={onOpenFlagModal}
                  />
                </Tooltip>
              </InlineItem>
            )}
            <InlineItem>
              <Tooltip title='Settings' placement='bottom' offset={8}>
                <UtilityButton
                  as={Link}
                  to='/settings'
                  icon='setting-filled'
                  displayMode='dark'
                />
              </Tooltip>
            </InlineItem>
            <InlineItem>
              <Tooltip title='Help and resources' placement='bottom' offset={8}>
                <UtilityButton
                  as='a'
                  href='https://help.kinesis.org/'
                  target='_blank'
                  icon='question-circle'
                  displayMode='dark'
                />
              </Tooltip>
            </InlineItem>
            <InlineItem>
              <DropdownMenu justify='end' placement='bottom'>
                <DropdownMenuButton
                  as={Avatar}
                  displayMode='dark'
                  interactive
                  variant='individual'
                  magnitude='small'
                >
                  {session?.initials}
                </DropdownMenuButton>
                <DropdownMenuList offset={8}>
                  <DropdownMenuText>
                    <H3>
                      {session?.firstName} {session?.lastName}
                    </H3>
                  </DropdownMenuText>
                  <DropdownMenuSeparator />
                  <DropdownMenuItem onSelect={onLogout}>
                    Log out
                  </DropdownMenuItem>
                </DropdownMenuList>
              </DropdownMenu>
            </InlineItem>
          </Inline>
        </ToolbarRight>
      </Layout>
    </Content>
  );
};

export type PrimaryNavigationProps = {
  enableFlags?: boolean;
  flags: Flag[];
  onLogout?: () => void;
  onToggleFlag?: (name: string) => void;
  session?: Session;
  animation?: boolean;
  onOpenSettings?: () => void;
  onOpenSwitcher?: () => void;
};

export { PrimaryNavigation };

import { Stack, LabelText, Text } from '@kinesis/bungle';
import { noop } from 'lodash/fp';
import { DefaultGrid } from '@/components/default-grid';
import {
  DefaultOrganisation,
  DefaultOrganisationSelection,
} from '@/components/default-organisation';
import { DefaultProduct } from '@/components/default-product';
import { Layout } from '@/components/layout';
import {
  DefaultGridProduct,
  DefaultProductOption,
  Organisation,
  Product,
  ProductLaunchPreference,
} from '@/types';

const emptyList: any[] = [];
const UserDefaults = (props: UserDefaultsProps) => {
  const {
    defaultOrganisation,
    defaultProduct,
    defaultProductOptions = emptyList,
    defaultGridProducts = emptyList,
    defaultOrganisationOptions = emptyList,
    onChangeDefaultGridProduct = noop,
    onChangeOrganisation = noop,
    onChangeDefaultProduct = noop,
  } = props;

  return (
    <Layout variant='narrow'>
      <Stack space='medium'>
        <Text>
          Select the product you want to launch when you first log in to
          Kinesis.
        </Text>
        <DefaultOrganisation
          options={defaultOrganisationOptions}
          onChangeOrganisation={onChangeOrganisation}
          defaultOrganisation={defaultOrganisation}
        />
        <DefaultProduct
          currentUserCanEdit={true}
          defaultProduct={defaultProduct}
          options={defaultProductOptions}
          onChangeProduct={onChangeDefaultProduct}
        />
        {defaultGridProducts.length > 0 && (
          <Stack space='xsmall'>
            <LabelText>Default instances</LabelText>
            <DefaultGrid
              defaultGridProducts={defaultGridProducts}
              onChange={onChangeDefaultGridProduct}
              currentUserCanEdit={true}
            />
          </Stack>
        )}
      </Stack>
    </Layout>
  );
};

export type UserDefaultsProps = {
  defaultOrganisation: DefaultOrganisationSelection;
  defaultOrganisationOptions: Organisation[];
  defaultProduct?: DefaultProductOption;
  defaultProductOptions?: DefaultProductOption[];
  defaultGridProducts?: DefaultGridProduct[];
  onChangeDefaultGridProduct?: (
    product: Product,
    selection: ProductLaunchPreference,
  ) => void;
  onChangeOrganisation?: (selection: DefaultOrganisationSelection) => void;
  onChangeDefaultProduct?: (option: DefaultProductOption) => void;
};

export { UserDefaults };

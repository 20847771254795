import { api } from './api';
import { AcceptedLogin, LoginMethod, OrganisationVanityLogin } from '@/types';

const loginApi = api.injectEndpoints({
  endpoints: (builder) => ({
    session: builder.query<AcceptedLogin, void>({
      query: () => 'authenticate',
      transformResponse: (response: any) => {
        const source = 'session';
        return { ...response, source };
      },
    }),
    authenticateSsoExchange: builder.mutation<AcceptedLogin, SsoExchange>({
      query: (v: SsoExchange) => ({
        url: 'login/exchange',
        method: 'POST',
        body: v,
        credentials: 'include',
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        queryFulfilled
          .then(({ data: session }) => {
            const source = 'login';
            dispatch(
              loginApi.util.upsertQueryData('session', undefined, {
                ...session,
                source,
              }),
            );
          })
          .catch(() => {});
      },
    }),
    authenticate: builder.mutation<
      AuthenticationResponse,
      AuthenticationRequest
    >({
      query: ({ email, password }) => ({
        url: 'login/credentials',
        method: 'POST',
        body: {
          email,
          password,
        },
        credentials: 'include',
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        queryFulfilled
          .then(({ data: session }) => {
            const source = 'login';
            dispatch(
              loginApi.util.upsertQueryData('session', undefined, {
                ...session,
                source,
              }),
            );
          })
          .catch(() => {});
      },
    }),
    introspect: builder.mutation<IntrospectResponse, IntrospectRequest>({
      query: ({ email }) => ({
        url: 'login/introspect',
        method: 'POST',
        body: {
          email,
        },
      }),
    }),
    logout: builder.mutation<void, void>({
      query: () => ({
        url: 'logout',
        method: 'POST',
      }),
      onQueryStarted: async (_, { queryFulfilled, dispatch }) => {
        await queryFulfilled;
        localStorage.removeItem('kinesis:session');
        await dispatch(api.util.resetApiState());
        window.location.assign('/');
      },
    }),
    getVanityLogin: builder.query<OrganisationVanityLogin, string>({
      query: (code: string) => `organisations/${code}/login`,
    }),
    profileAcceptInvitation: builder.mutation<
      AcceptedLogin,
      ProfileAcceptInvitationRequest
    >({
      query: ({ invitationToken, details }) => ({
        url: `invitations/${invitationToken}/profile-accept`,
        method: 'POST',
        body: details,
        credentials: 'include',
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        queryFulfilled
          .then(({ data: session }) => {
            const source = 'login';
            dispatch(
              loginApi.util.upsertQueryData('session', undefined, {
                ...session,
                source,
              }),
            );
          })
          .catch(() => {});
      },
    }),
  }),
});

type AuthenticationRequest = {
  email: string;
  password: string;
};

type AuthenticationResponse = AcceptedLogin;

type IntrospectRequest = {
  email: string;
};

type IntrospectResponse = LoginMethod;

type SsoExchange = {
  state: string;
  code: string;
};

export type ProfileAcceptInvitationRequest = {
  invitationToken: string;
  details: {
    firstName: string;
    lastName: string;
    jobTitle: string;
    password: string;
    acceptTerms: boolean;
  };
};

export const {
  useAuthenticateSsoExchangeMutation,
  useAuthenticateMutation,
  useIntrospectMutation,
  useSessionQuery,
  useLogoutMutation,
  useGetVanityLoginQuery,
  useProfileAcceptInvitationMutation,
} = loginApi;

export { loginApi };

import {
  EntityState,
  createSelector,
  createEntityAdapter,
} from '@reduxjs/toolkit';
import { api } from './api';
import { userApi } from './user';
import {
  AccessKeySecret,
  DefaultProductOption,
  Invitation,
  LaunchOptions,
  LaunchSelection,
  Licence,
  Member,
  Namespace,
  NewOrganisation,
  NewServiceAccount,
  Organisation,
  ServiceAccount,
  UpdateOrganisation,
} from '@/types';

const members = createEntityAdapter<Member, number>({
  selectId: (m: Member) => m.id,
  sortComparer: (a: Member, b: Member) => {
    if (a.membership.status === 'pending' && b.membership.status === 'active') {
      return -1; // a should come first
    }

    if (a.membership.status === 'active' && b.membership.status === 'pending') {
      return 1; // b should come first
    }

    // If both have the same state, sort by email
    return a.email.localeCompare(b.email);
  },
});

const accounts = createEntityAdapter<ServiceAccount, number>({
  selectId: (a: ServiceAccount) => a.id,
  sortComparer: (a: ServiceAccount, b: ServiceAccount) =>
    a.name.localeCompare(b.name),
});

const organisations = createEntityAdapter<Organisation, number>({
  selectId: (o: Organisation) => o.id,
  sortComparer: (a: Organisation, b: Organisation) =>
    a.name.localeCompare(b.name),
});

const productLaunchSelectionsAdapter = createEntityAdapter<
  LaunchSelection,
  Namespace
>({
  selectId: (l: LaunchSelection) => l.namespace,
  sortComparer: (a: LaunchSelection, b: LaunchSelection) =>
    a.namespace.localeCompare(b.namespace),
});

const organisationApi = api.injectEndpoints({
  endpoints: (builder) => ({
    listOrganisations: builder.query<EntityState<Organisation, number>, void>({
      query: () => 'organisations',
      transformResponse: (response: any) =>
        organisations.setAll(
          organisations.getInitialState(),
          response.organisations,
        ),
    }),
    createOrganisation: builder.mutation<Organisation, NewOrganisation>({
      query: (request) => ({
        // FIX split registration organisation create so this can return the actual organisation
        // update listOrganisations
        url: `organisations`,
        method: 'POST',
        body: request,
      }),
      onQueryStarted: async (patch, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled;
        dispatch(
          organisationApi.util.updateQueryData(
            'listOrganisations',
            undefined,
            (current) => {
              organisations.setOne(current as any, data);
            },
          ),
        );
      },
    }),
    listMembers: builder.query<EntityState<Member, number>, number>({
      query: (id) => `organisations/${id}/members`,
      transformResponse: (response: any) =>
        members.setAll(members.getInitialState(), response.members),
    }),
    exportMembers: builder.mutation<{ filename: string; csv: string }, number>({
      query: (id) => ({
        url: `organisations/${id}/members/export`,
        method: 'POST',
      }),
    }),
    listServiceAccounts: builder.query<
      EntityState<ServiceAccount, number>,
      number
    >({
      query: (id) => `organisations/${id}/service-accounts`,
      transformResponse: (response: any) =>
        accounts.setAll(accounts.getInitialState(), response.serviceAccounts),
    }),

    listLicences: builder.query<Licence[], number>({
      query: (id) => `organisations/${id}/licences`,
      transformResponse: (response: any) => response.licences,
    }),

    updateOrganisation: builder.mutation<Organisation, UpdateOrganisation>({
      query: (organisation) => ({
        url: `organisations/${organisation.id}`,
        method: 'POST',
        body: organisation,
      }),
      onQueryStarted: async (patch, { dispatch, queryFulfilled }) => {
        const patchResult = dispatch(
          organisationApi.util.updateQueryData(
            'listOrganisations',
            undefined,
            (current) => {
              organisations.updateOne(current as any, {
                id: patch.id,
                changes: patch,
              });
            },
          ),
        );
        try {
          const { data } = await queryFulfilled;
          dispatch(
            organisationApi.util.updateQueryData(
              'listOrganisations',
              undefined,
              (current) => {
                organisations.updateOne(current as any, {
                  id: data.id,
                  changes: data,
                });
              },
            ),
          );
        } catch {
          patchResult.undo();
        }
      },
    }),
    revokeMembership: builder.mutation<void, RevokeAccessRequest>({
      query: ({ membershipId }) => ({
        url: `memberships/${membershipId}`,
        method: 'DELETE',
        body: {},
      }),
      onQueryStarted: async (
        { userId, organisationId, isMe },
        { dispatch, queryFulfilled },
      ) => {
        const patchResults: any[] = [];
        patchResults.push(
          dispatch(
            organisationApi.util.updateQueryData(
              'listMembers',
              organisationId,
              (current) => {
                members.removeOne(current as any, userId);
              },
            ),
          ),
        );
        if (isMe) {
          patchResults.push(
            dispatch(
              organisationApi.util.updateQueryData(
                'listOrganisations',
                undefined,
                (current) => {
                  members.removeOne(current as any, organisationId);
                },
              ),
            ),
          );
        }

        queryFulfilled.catch(() =>
          patchResults.forEach((patch) => patch.undo()),
        );
      },
    }),
    roleUpdate: builder.mutation<void, RoleUpdateRequest>({
      query: ({ organisationId, membershipId, role }) => ({
        url: `organisations/${organisationId}/members/${membershipId}/role`,
        method: 'PATCH',
        body: {
          role,
        },
      }),
      onQueryStarted: async (
        { userId, organisationId, role, isMe },
        { dispatch, queryFulfilled },
      ) => {
        const patchResults: any[] = [];
        patchResults.push(
          dispatch(
            organisationApi.util.updateQueryData(
              'listMembers',
              organisationId,
              (current) => {
                const member = members
                  .getSelectors()
                  .selectById(current, userId);
                if (member) {
                  members.updateOne(current as any, {
                    id: userId,
                    changes: {
                      membership: {
                        ...member.membership,
                        permissions: {
                          editor: role === 'editor' ? true : false,
                        },
                      },
                    },
                  });
                }
              },
            ),
          ),
        );
        if (isMe) {
          patchResults.push(
            dispatch(
              userApi.util.updateQueryData('me', undefined, (current) => {
                const membership = current.memberships.find(
                  ({ organisationId: memberOrganisationId }) =>
                    organisationId === memberOrganisationId,
                );
                if (membership) {
                  membership.permissions = {
                    editor: role === 'editor' ? true : false,
                  };
                }
              }),
            ),
          );
        }

        queryFulfilled.catch(() =>
          patchResults.forEach((patch) => patch.undo()),
        );
      },
    }),

    cancelInvitation: builder.mutation<void, CancelInvitationRequest>({
      query: ({ invitationToken }) => ({
        url: `invitations/${invitationToken}`,
        method: 'DELETE',
        body: {},
      }),
      onQueryStarted: async (
        { userId, organisationId },
        { dispatch, queryFulfilled },
      ) => {
        const patchResult = dispatch(
          organisationApi.util.updateQueryData(
            'listMembers',
            organisationId,
            (current) => {
              members.removeOne(current as any, userId);
            },
          ),
        );
        queryFulfilled.catch(patchResult.undo);
      },
    }),
    newInvitation: builder.mutation<Member, NewInvitationRequest>({
      query: (newInvitations) => ({
        url: `invitations`,
        method: 'POST',
        body: newInvitations,
      }),
      onQueryStarted: async (
        { organisationId },
        { dispatch, queryFulfilled },
      ) => {
        const { data } = await queryFulfilled;
        dispatch(
          organisationApi.util.updateQueryData(
            'listMembers',
            organisationId,
            (current) => {
              members.setMany(current as any, data);
            },
          ),
        );
      },
    }),
    reissueInvitation: builder.mutation<void, ReissueInvitationRequest>({
      query: ({ invitationToken }) => ({
        url: `invitations/${invitationToken}/reissue`,
        method: 'POST',
        body: {},
      }),
      onQueryStarted: async (
        { userId, organisationId },
        { dispatch, queryFulfilled },
      ) => {
        const patchResult = dispatch(
          organisationApi.util.updateQueryData(
            'listMembers',
            organisationId,
            (current) => {
              const member = members.getSelectors().selectById(current, userId);
              if (member) {
                members.updateOne(current as any, {
                  id: userId,
                  changes: {
                    membership: {
                      ...member.membership,
                      invitationExpired: false,
                    },
                  },
                });
              }
            },
          ),
        );
        queryFulfilled.catch(patchResult.undo);
      },
    }),
    getInvitation: builder.query<Invitation, string>({
      query: (token) => `invitations/${token}`,
    }),
    deleteServiceAccountAccessKey: builder.mutation<
      void,
      DeleteServiceAccountAccessKeyRequest
    >({
      query: ({ accountId, keyId }) => ({
        url: `service-accounts/${accountId}/keys/${keyId}`,
        method: 'DELETE',
        body: {},
      }),
      onQueryStarted: async (
        { organisationId, accountId, keyId },
        { dispatch, queryFulfilled },
      ) => {
        const patchResult = dispatch(
          organisationApi.util.updateQueryData(
            'listServiceAccounts',
            organisationId,
            (current) => {
              current.entities[accountId].keys = current.entities[
                accountId
              ]?.keys?.filter(({ id }) => id !== keyId);
            },
          ),
        );
        queryFulfilled.catch(patchResult.undo);
      },
    }),
    newServiceAccountAccessKey: builder.mutation<
      AccessKeySecret,
      NewServiceAccountAccessKeyRequest
    >({
      query: ({ accountId }) => ({
        url: `service-accounts/${accountId}/keys`,
        method: 'POST',
        body: {},
      }),
      onQueryStarted: async (
        { accountId, organisationId },
        { dispatch, queryFulfilled },
      ) => {
        const { data } = await queryFulfilled;
        dispatch(
          organisationApi.util.updateQueryData(
            'listServiceAccounts',
            organisationId,
            (current) => {
              current.entities[accountId]?.keys?.push({
                id: data.id,
                created: data.created,
              });
            },
          ),
        );
      },
    }),
    newServiceAccount: builder.mutation<
      ServiceAccount,
      NewServiceAccountRequest
    >({
      query: ({ organisationId, account }) => ({
        url: `organisations/${organisationId}/service-accounts`,
        method: 'POST',
        body: account,
      }),
      onQueryStarted: async (
        { organisationId },
        { dispatch, queryFulfilled },
      ) => {
        const { data } = await queryFulfilled;
        dispatch(
          organisationApi.util.updateQueryData(
            'listServiceAccounts',
            organisationId,
            (current) => {
              accounts.setOne(current as any, data);
            },
          ),
        );
      },
    }),
    deleteServiceAccount: builder.mutation<void, DeleteServiceAccountRequest>({
      query: ({ accountId }) => ({
        url: `service-accounts/${accountId}`,
        method: 'DELETE',
        body: {},
      }),
      onQueryStarted: async (
        { organisationId, accountId },
        { dispatch, queryFulfilled },
      ) => {
        const patchResult = dispatch(
          organisationApi.util.updateQueryData(
            'listServiceAccounts',
            organisationId,
            (current) => {
              accounts.removeOne(current as any, accountId);
            },
          ),
        );
        queryFulfilled.catch(patchResult.undo);
      },
    }),
    organisationDefaultProductOptions: builder.query<
      DefaultProductOption[],
      number
    >({
      query: (id) => `organisations/${id}/options/default/product`,
    }),
    organisationDefaultProduct: builder.query<DefaultProductOption, number>({
      query: (id) => `organisations/${id}/default/product`,
    }),
    organisationUpdateDefaultProduct: builder.mutation<
      DefaultProductOption,
      UpdateOrganisationDefaultProduct
    >({
      query: ({ id, preference }) => ({
        url: `organisations/${id}/default/product`,
        method: 'POST',
        body: preference,
      }),
      onQueryStarted: async (patch, { dispatch, queryFulfilled }) => {
        const patchResult = dispatch(
          organisationApi.util.updateQueryData(
            'organisationDefaultProduct',
            patch.id,
            (current) => {
              Object.assign(current, patch.preference);
            },
          ),
        );
        queryFulfilled.catch(patchResult.undo);
      },
    }),
    organisationProductLaunchOptions: builder.query<LaunchOptions[], number>({
      query: (id) => `organisations/${id}/options/product/launch`,
    }),
    organisationProductLaunchSelections: builder.query<
      EntityState<LaunchSelection, Namespace>,
      number
    >({
      query: (id) => `organisations/${id}/product/launch`,
      transformResponse: (response: LaunchSelection[]) =>
        productLaunchSelectionsAdapter.setAll(
          productLaunchSelectionsAdapter.getInitialState(),
          response,
        ),
    }),
    organisationUpdateProductLaunchSelection: builder.mutation<
      LaunchSelection,
      UpdateLaunchSelection
    >({
      query: ({ id, selection }) => ({
        url: `organisation/${id}/product/launch/${selection.namespace}`,
        method: 'POST',
        body: selection.preference,
      }),
      onQueryStarted: async (patch, { dispatch, queryFulfilled }) => {
        const patchResult = dispatch(
          organisationApi.util.updateQueryData(
            'organisationProductLaunchSelections',
            patch.id,
            (current) => {
              productLaunchSelectionsAdapter.updateOne(current as any, {
                id: patch.selection.namespace,
                changes: patch.selection,
              });
            },
          ),
        );
        try {
          const { data } = await queryFulfilled;
          dispatch(
            organisationApi.util.updateQueryData(
              'organisationProductLaunchSelections',
              patch.id,
              (current) => {
                productLaunchSelectionsAdapter.updateOne(current as any, {
                  id: data.namespace,
                  changes: data,
                });
              },
            ),
          );
        } catch {
          patchResult.undo();
        }
      },
    }),
  }),
});

type RevokeAccessRequest = {
  organisationId: number;
  userId: number;
  membershipId: number;
  isMe: boolean;
};

type RoleUpdateRequest = {
  organisationId: number;
  userId: number;
  membershipId: number;
  role: string;
  isMe: boolean;
};

type CancelInvitationRequest = {
  organisationId: number;
  userId: number;
  invitationToken: string;
};

type NewInvitationRequest = {
  invitees: { email: string; role: 'editor' | 'member' }[];
  organisationId: number;
};

type ReissueInvitationRequest = {
  organisationId: number;
  userId: number;
  invitationToken: string;
};

type NewServiceAccountRequest = {
  organisationId: number;
  account: NewServiceAccount;
};

type DeleteServiceAccountRequest = {
  organisationId: number;
  accountId: number;
};

type NewServiceAccountAccessKeyRequest = {
  organisationId: number;
  accountId: number;
};

type DeleteServiceAccountAccessKeyRequest = {
  organisationId: number;
  accountId: number;
  keyId: string;
};

type UpdateOrganisationDefaultProduct = {
  id: number;
  preference: DefaultProductOption;
};

type UpdateLaunchSelection = {
  id: number;
  selection: LaunchSelection;
};

const organisationEntitiesSelector = createSelector(
  organisationApi.endpoints.listOrganisations.select(),
  (result) => result?.data ?? organisations.getInitialState(),
);

export { organisationEntitiesSelector };

export const {
  selectById: organisationByIdSelector,
  selectIds: organisationIdsSelector,
  selectAll: organisationsSelector,
} = organisations.getSelectors(organisationEntitiesSelector);

export const membersSelector = (organisationId: number) => {
  const selector = createSelector(
    organisationApi.endpoints.listMembers.select(organisationId),
    (result) => result?.data ?? members.getInitialState(),
  );
  return members.getSelectors(selector).selectAll;
};

export const accountsSelector = (organisationId: number) => {
  const selector = createSelector(
    organisationApi.endpoints.listServiceAccounts.select(organisationId),
    (result) => result?.data ?? accounts.getInitialState(),
  );
  return accounts.getSelectors(selector).selectAll;
};

export const organisationProductLaunchSelectionsSelector = (
  organisationId: number,
) => {
  const selector = createSelector(
    organisationApi.endpoints.organisationProductLaunchSelections.select(
      organisationId,
    ),
    (result) =>
      result?.data ?? productLaunchSelectionsAdapter.getInitialState(),
  );
  return productLaunchSelectionsAdapter.getSelectors(selector).selectAll;
};

export const {
  useListOrganisationsQuery,
  useUpdateOrganisationMutation,
  useCreateOrganisationMutation,
  useRevokeMembershipMutation,
  useExportMembersMutation,
  useCancelInvitationMutation,
  useNewInvitationMutation,
  useReissueInvitationMutation,
  useGetInvitationQuery,
  useRoleUpdateMutation,
  useListMembersQuery,
  useListServiceAccountsQuery,
  useListLicencesQuery,
  useNewServiceAccountAccessKeyMutation,
  useNewServiceAccountMutation,
  useOrganisationDefaultProductOptionsQuery,
  useOrganisationDefaultProductQuery,
  useOrganisationUpdateDefaultProductMutation,
  useOrganisationProductLaunchOptionsQuery,
  useOrganisationProductLaunchSelectionsQuery,
  useOrganisationUpdateProductLaunchSelectionMutation,
  useDeleteServiceAccountAccessKeyMutation,
  useDeleteServiceAccountMutation,
} = organisationApi;

import { CenteredPanel, Content, Stack, Text, Strong } from '@kinesis/bungle';
import { LicenseCard } from './licenses.styles';
import { Licence } from '@/types';
import { IconNzo } from '@/components/icon-nzo';
import { IconPathways } from '@/components/icon-pathways';
import { IconCity } from '@/components/icon-city';
import { IconIntegrated } from '@/components/icon-integrated';
import { IconWorkspaces } from '@/components/icon-workspaces';
import { IconBillManagement } from '@/components/icon-bill-management';

const products = {
  nzo: 'Net-zero for Organisations',
  workspaces: 'Workspaces',
  pathways: 'Net-zero Pathways',
  city: 'CCAP City',
  integrated: 'CCAP Integrated',
  'bill-management': 'Bill Management',
};

const icons = {
  nzo: <IconNzo />,
  workspaces: <IconWorkspaces />,
  pathways: <IconPathways />,
  city: <IconCity />,
  integrated: <IconIntegrated />,
  'bill-management': <IconBillManagement />,
};

const License = (props: LicenseProps) => {
  const { licence } = props;
  const { key, licensed } = licence;

  return (
    <LicenseCard isLicenced={licensed}>
      <Stack space='medium'>
        <span>
          <Content border height={48} width={48}>
            <CenteredPanel centerY>{icons[key]}</CenteredPanel>
          </Content>
        </span>
        <Stack space='xsmall'>
          <Strong>{products[key]}</Strong>
          {licensed ? <Text>Licensed</Text> : <Text>Not licensed</Text>}
        </Stack>
      </Stack>
    </LicenseCard>
  );
};

export type LicenseProps = {
  licence: Licence;
};

export { License };

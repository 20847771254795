import { useCallback, useState } from 'react';
import {
  Content,
  Stack,
  Button,
  Input,
  H2,
  Text,
  Strong,
} from '@kinesis/bungle';
import { noop } from 'lodash/fp';
import { KinesisLogo } from '@/components/kinesis-logo';
import { LinkText } from '@/components/link-text';
import { Center } from '@/components/center';

const RegistrationCode = (props: RegistrationCodeProps) => {
  const { onConfirm = noop, isConfirming = false, email, token } = props;
  const [code, setCode] = useState('');
  const [codeError, setCodeError] = useState(false);
  const validateCode = useCallback(() => {
    const valid = /^[A-Za-z0-9]{3}[-]*[A-Za-z0-9]{3}$/.test(code);
    setCodeError(!valid);
    return valid;
  }, [code, setCodeError]);

  const onConfirmSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      const valid = validateCode();
      if (valid) {
        onConfirm({
          code,
          token,
          email,
        });
      }
    },
    [onConfirm, validateCode, code, token, email],
  );

  return (
    <Content background='white' borderRadiusSize='large' width={304}>
      <Stack space='none'>
        <Content padding='large' sizing='fill-container' paddingMode='equal'>
          <Stack space='medium'>
            <Center>
              <KinesisLogo />
            </Center>
            <Center>
              <H2>Join Kinesis</H2>
            </Center>
          </Stack>
        </Content>
        <Content
          background='gray'
          borderTop
          sizing='fill-container'
          padding='medium'
          paddingMode='equal'
          borderRadiusSize='large'
          borderRadiusPlacement='bottom'
        >
          <form onSubmit={onConfirmSubmit} noValidate>
            <Stack space='large'>
              <Stack space='medium'>
                <Text>
                  We just sent a temporary sign up code to{' '}
                  <Strong>{email}</Strong>. Please check your email and paste
                  the code below to continue.
                </Text>

                <Input
                  label='Sign up code'
                  magnitude='large'
                  placeholder='Paste sign up code'
                  value={code}
                  onChange={setCode}
                  onBlur={validateCode}
                  autoFocus
                  type='text'
                  tone={codeError ? 'critical' : 'neutral'}
                />
              </Stack>
              <Stack space='medium'>
                <Button type='submit' display='block' loading={isConfirming}>
                  Continue
                </Button>
                <Center>
                  <Text>
                    Already have an account? <LinkText to='/'>Log in.</LinkText>
                  </Text>
                </Center>
              </Stack>
            </Stack>
          </form>
        </Content>
      </Stack>
    </Content>
  );
};

export type RegistrationCodeConfirm = {
  code: string;
  token: string;
  email: string;
};

export type RegistrationCodeProps = {
  onConfirm?: (v: RegistrationCodeConfirm) => void;
  isConfirming?: boolean;
  email: string;
  token: string;
};

export { RegistrationCode };

import { useCallback } from 'react';
import {
  Avatar,
  Button,
  Stack,
  Inline,
  InlineItem,
  Secondary,
  Text,
} from '@kinesis/bungle';
import { noop } from 'lodash/fp';
import { InvitationData } from '@/types';
import { parseISO } from 'date-fns/fp';
import { formatDistance } from '@/date';

const PendingInvitationRow = (props: PendingInvitationRowProps) => {
  const {
    onAccept = noop,
    onDecline = noop,
    invitation: { token, organisationName, avatar, invitedBy, expiresAt },
  } = props;

  const expiresIn = formatDistance(new Date(), parseISO(expiresAt));

  const handleAccept = useCallback(() => {
    onAccept(token);
  }, [onAccept, token]);

  const handleDecline = useCallback(() => {
    onDecline(token);
  }, [onDecline, token]);

  return (
    <Inline space='small'>
      <InlineItem>
        <Avatar
          alt={`${organisationName} logo`}
          image={avatar}
          magnitude='small'
          variant='organisation'
        >
          {organisationName.slice(0, 1)}
        </Avatar>
      </InlineItem>
      <InlineItem sizing='fill-container'>
        <Stack>
          <Text>{organisationName}</Text>
          <Secondary>
            Invited by {invitedBy} (expires {expiresIn})
          </Secondary>
        </Stack>
      </InlineItem>
      <InlineItem>
        <Inline space='small'>
          <InlineItem>
            <Button
              variant='secondary'
              display='block'
              magnitude='small'
              onClick={handleDecline}
            >
              Decline
            </Button>
          </InlineItem>
          <InlineItem>
            <Button variant='action' magnitude='small' onClick={handleAccept}>
              Accept
            </Button>
          </InlineItem>
        </Inline>
      </InlineItem>
    </Inline>
  );
};

export type PendingInvitationRowProps = {
  onAccept?: (token: string) => void;
  onDecline?: (token: string) => void;
  invitation: InvitationData;
};

export { PendingInvitationRow };

import { ReactNode } from 'react';
import { Content, Stack, H2 } from '@kinesis/bungle';
import { KinesisLogo } from '@/components/kinesis-logo';
import { Center } from '@/components/center';
import { LinkText } from '@/components/link-text';

const PasswordResetLayout = (props: PasswordResetLayoutProps) => {
  const { children = null, showBackToLogin = true } = props;
  return (
    <Content background='white' borderRadiusSize='large' width={304}>
      <Stack space='none'>
        <Content padding='large' sizing='fill-container' paddingMode='equal'>
          <Stack space='medium'>
            <Center>
              <KinesisLogo />
            </Center>
            <Center>
              <H2>Reset your password</H2>
            </Center>
          </Stack>
        </Content>
        <Content
          background='gray'
          borderTop
          sizing='fill-container'
          padding='medium'
          paddingMode='equal'
          borderRadiusSize='large'
          borderRadiusPlacement='bottom'
        >
          <Stack space='medium'>
            {children}
            {showBackToLogin && (
              <Center>
                <LinkText to='/'>Back to log in.</LinkText>
              </Center>
            )}
          </Stack>
        </Content>
      </Stack>
    </Content>
  );
};

export type PasswordResetLayoutProps = {
  children?: ReactNode;
  showBackToLogin?: boolean;
};

export { PasswordResetLayout };

import { Icon } from '@kinesis/bungle';

/* eslint-disable max-len */
const IconPathways = (props: IconPathwaysProps) => {
  const { magnitude = 'xlarge' } = props;
  return <Icon type='waterfall-filled' magnitude={magnitude} color='#F7AF37' />;
};

export type IconPathwaysProps = {
  magnitude?: string;
};

export { IconPathways };
/* eslint-enable max-len */

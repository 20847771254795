import { useState, useCallback, KeyboardEvent } from 'react';
import { noop, concat, reject, isNil, isEmpty } from 'lodash/fp';
import {
  Button,
  Frame,
  Header,
  Footer,
  Container,
  Stack,
  Input,
  Toolbar,
  ToolbarItem,
  ToolbarGroup,
  Heading,
  Content,
  Text,
  Inline,
  InlineItem,
  Select,
  Icon,
} from '@kinesis/bungle';
import { Email as EmailCard } from './email';
import { useTheme } from 'styled-components';

const roleOptions = [
  { label: 'Admin', value: 'editor' },
  { label: 'Member', value: 'member' },
];

const isValidSsoEmail = (
  input: string,
  mandatesSso?: boolean,
  ssoDomains?: string[],
) => {
  return (
    !mandatesSso ||
    Boolean(ssoDomains?.some((domain) => input.includes(domain)))
  );
};

const OrganisationMemberInviteForm = (
  props: OrganisationMemberInviteFormProps,
) => {
  const { onInvite = noop, onClose = noop, mandatesSso, ssoDomains } = props;
  const [emails, setEmails] = useState<Email[]>([]);
  const [input, setInput] = useState('');
  const [role, setRole] = useState<'member' | 'editor'>('member');
  const includeCandidate = input.length > 3 && input.includes('@');

  const theme = useTheme() as any;

  const onSave = useCallback(() => {
    onInvite(emails);
    onClose();
  }, [onInvite, onClose, emails]);

  const isSelectedEmail = useCallback(
    (email: string) => !isNil(emails.find((v: Email) => v.email === email)),
    [emails],
  );

  const onSelectEmail = useCallback(
    (email: string, newRole: 'editor' | 'member') => {
      if (
        !isSelectedEmail(email) &&
        email.length > 3 &&
        email.includes('@') &&
        isValidSsoEmail(email, mandatesSso, ssoDomains)
      ) {
        setEmails(concat([{ email, role: newRole }]));
        setInput('');
      }
    },
    [isSelectedEmail, mandatesSso, ssoDomains],
  );

  const onDeselectEmail = useCallback(
    (email: string) => {
      setEmails(reject((v: Email) => v.email === email));
    },
    [setEmails],
  );

  const onCancelInput = useCallback(
    (event: KeyboardEvent) => {
      if (!isEmpty(input) && event.key === 'Escape') {
        event.stopPropagation();
        setInput('');
      }
    },
    [setInput, input],
  );

  const onKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (
        event.key === 'Enter' &&
        includeCandidate &&
        isValidSsoEmail(input, mandatesSso, ssoDomains)
      ) {
        event.preventDefault();
        onSelectEmail(input, role);
      }
    },
    [includeCandidate, input, mandatesSso, ssoDomains, onSelectEmail, role],
  );

  return (
    <Container>
      <Header border={mandatesSso && !isEmpty(ssoDomains) ? undefined : true}>
        <Toolbar>
          <ToolbarItem ellipsify sizing='fill-container'>
            <Heading as='h2' size='large'>
              Invite members
            </Heading>
          </ToolbarItem>
        </Toolbar>
        {mandatesSso && !isEmpty(ssoDomains) && (
          <Content background='yellow' border padding='small'>
            <Inline space='small'>
              <InlineItem>
                <Icon
                  type='exclamation-circle'
                  magnitude='medium'
                  color={theme.color.yellow6}
                />
              </InlineItem>
              <InlineItem sizing='fill-container'>
                <Text>
                  Your organisation&apos;s settings only allow members using
                  your SSO configuration.
                </Text>
              </InlineItem>
            </Inline>
          </Content>
        )}
      </Header>
      <Frame>
        <div onKeyDown={onKeyDown} style={{ height: '100%' }}>
          <Content padding='medium' paddingMode='equal'>
            <Stack space='medium'>
              <Inline>
                <InlineItem sizing='fill-container'>
                  <Input
                    magnitude='large'
                    placeholder='Enter email to invite'
                    value={input}
                    onChange={setInput}
                    onKeyDown={onCancelInput}
                    type='search'
                    autoFocus
                    autoComplete='off'
                  />
                </InlineItem>
                <InlineItem>
                  <Select
                    options={roleOptions}
                    value={role}
                    onChange={setRole}
                  />
                </InlineItem>
              </Inline>
              <Content height={300}>
                <Stack space='small'>
                  {includeCandidate && (
                    <EmailCard
                      email={input}
                      role={role}
                      selected={false}
                      onSelect={onSelectEmail}
                      onDeselect={noop}
                      errorState={
                        !isValidSsoEmail(input, mandatesSso, ssoDomains)
                      }
                      errorMessage={
                        isValidSsoEmail(input, mandatesSso, ssoDomains)
                          ? undefined
                          : 'This email does not match your SSO domain'
                      }
                      highlighted
                    />
                  )}
                  {emails.map((v: Email) => (
                    <EmailCard
                      key={v.email}
                      email={v.email}
                      role={v.role}
                      selected
                      onDeselect={onDeselectEmail}
                      onSelect={noop}
                    />
                  ))}
                </Stack>
              </Content>
            </Stack>
          </Content>
        </div>
      </Frame>
      <Footer border>
        <Toolbar>
          <ToolbarGroup>
            <ToolbarItem>
              <Text>{emails.length} member invites</Text>
            </ToolbarItem>
          </ToolbarGroup>
          <ToolbarGroup>
            <ToolbarItem>
              <Button onClick={onClose} variant='secondary'>
                Cancel
              </Button>
            </ToolbarItem>
            <ToolbarItem>
              <Button
                onClick={onSave}
                variant='primary'
                disabled={emails.length === 0}
              >
                Invite members
              </Button>
            </ToolbarItem>
          </ToolbarGroup>
        </Toolbar>
      </Footer>
    </Container>
  );
};

export type Email = {
  email: string;
  role: 'editor' | 'member';
};
export type OrganisationMemberInviteFormProps = {
  onClose?: () => void;
  onInvite?: (v: Email[]) => void;
  mandatesSso?: boolean;
  ssoDomains?: string[];
};

export { OrganisationMemberInviteForm };

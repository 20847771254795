import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAuthenticateSsoExchangeMutation } from '@/api/login';
import { login } from '@/services/login';

// We need to ensure this loads only _once_ for the entire app.
// The callback code can't be re-used and we need to start the
// login flow again if for some reason it does happen.
let state = 'initial';

const SsoCallback = () => {
  const [search] = useSearchParams();
  const navigate = useNavigate();
  const [ssoExchange] = useAuthenticateSsoExchangeMutation();

  useEffect(() => {
    if (state !== 'initial') return;
    state = 'exchanged';
    ssoExchange({
      state: search.get('state') ?? '',
      code: search.get('code') ?? '',
    })
      .unwrap()
      .then((data) => {
        login(data, true, navigate);
      })
      .catch(() => {
        window.location.pathname = '/';
      });
  }, [ssoExchange, search, navigate]);

  return null;
};

export { SsoCallback };

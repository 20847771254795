import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Center } from '@/components/center';
import {
  RegistrationOrganisation,
  NewOrganisation,
} from '@/components/registration-organisation';
import { useSessionQuery } from '@/api/login';
import { useRegisterOrganisationMutation } from '@/api/registration';
import { launch } from '@/services/launch';

const FirstOrganisation = () => {
  const { data: session, isError, isSuccess, isLoading } = useSessionQuery();
  const [registerOrganisation, { isLoading: isCreating }] =
    useRegisterOrganisationMutation();
  const navigate = useNavigate();

  useEffect(() => {
    // NOTE: This is manually handled, and don't use the "Authenticated"
    // page, as the authenticated page has orphan handling which we
    // don't want (as we are the target of that page). We want _not_
    // orphan handling that takes you to the target instead.

    if (!isLoading && isSuccess && !session.orphan) {
      launch(session.target, true);
    } else if (!isLoading && isError) {
      // NOTE: we explicitly don't set redirect here, it will come
      // back if the session says we are an orphan and if they aren't
      // we don't want them back here, go to their default target.
      navigate('/');
    }
  }, [
    isLoading,
    isError,
    isSuccess,
    navigate,
    session?.orphan,
    session?.target,
  ]);

  const onCreateOrganisation = useCallback(
    async (v: NewOrganisation) => {
      const newSession = await registerOrganisation(v).unwrap();
      launch(newSession.target, true);
    },
    [registerOrganisation],
  );

  // NOTE: this looks odd, but it guards against loading flash
  // because we may not be an ophan and we want to bounce to
  // your target, the detection for this in the effect above
  // would cause loading flash if we don't delay until we are
  // loaded _AND_ we check we are not that not an orphan case.

  if (session && !isLoading && !(isSuccess && !session.orphan)) {
    return (
      <Center>
        <RegistrationOrganisation
          onCreateOrganisation={onCreateOrganisation}
          isCreating={isCreating}
          name={session.name}
          email={session.email}
        />
      </Center>
    );
  }
  return null;
};

export { FirstOrganisation };

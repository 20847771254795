import styled from 'styled-components';

const ButtonText = styled.span(({ theme }) => ({
  fontSize: theme.fontSize.md,
  fontWeight: theme.lineHeight.normal,
  lineHeight: theme.lineHeight.md,
  cursor: 'pointer',
  color: theme.color.blue6,
  ':hover': {
    color: theme.color.blue7,
  },
  ':active': {
    color: theme.color.blue7,
  },
}));

export { ButtonText };

import { useSettingsSelection } from '@/hooks/use-settings-selection';
import { Licenses } from '@/components/licenses';
import { Loading } from '@/components/loading';
import { useListLicencesQuery } from '@/api/organisation';

const SettingsOrganisationLicences = () => {
  const { id } = useSettingsSelection() as any;
  const { data: licences, isLoading } = useListLicencesQuery(id);
  if (!isLoading && licences) {
    return <Licenses key={id} licences={licences} />;
  } else {
    return <Loading />;
  }
};

export { SettingsOrganisationLicences };

import { noop } from 'lodash/fp';
import { Inline, InlineItem, Select, Text } from '@kinesis/bungle';
import { ProductIcon } from '@/components/product-icon';
import { IconDefaults } from '@/components/icon-defaults';
import { DefaultProductOption } from '@/types';
import { productName } from '@/data/products';

const optionName = (item: DefaultProductOption) => {
  switch (item.type) {
    case 'default-for-organisation':
      return 'Default for organisation';
    case 'last-visited':
      return 'Last visited';
    case 'product':
      return productName(item.product);
  }
};

const optionIcon = (item: DefaultProductOption) => {
  switch (item.type) {
    case 'default-for-organisation':
      return <IconDefaults magnitude='small' />;
    case 'last-visited':
      return <IconDefaults magnitude='small' />;
    case 'product':
      return <ProductIcon product={item.product} magnitude='small' />;
  }
};

const renderDefaultProductOption = (option: DefaultProductOption) => (
  <Inline space='small'>
    <InlineItem sizing='fit-content'>{optionIcon(option)}</InlineItem>
    <InlineItem sizing='fill-container'>
      <Text>{optionName(option)}</Text>
    </InlineItem>
  </Inline>
);

const DefaultProduct = (props: DefaultProductProps) => {
  const {
    defaultProduct,
    options,
    currentUserCanEdit,
    onChangeProduct = noop,
  } = props;

  return (
    <Select
      label='Default product'
      options={options}
      renderItem={renderDefaultProductOption}
      onChange={onChangeProduct}
      value={defaultProduct}
      returnEntireItem={true}
      disabled={!currentUserCanEdit}
    />
  );
};

export type DefaultProductProps = {
  defaultProduct?: DefaultProductOption;
  options: DefaultProductOption[];
  onChangeProduct?: (selection: DefaultProductOption) => void;
  currentUserCanEdit: boolean;
};

export { DefaultProduct };

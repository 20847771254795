import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Menu, Divider } from '@kinesis/bungle';
import { isEqual, compact, isNil } from 'lodash/fp';
import { IconLicences } from '@/components/icon-licences';
import { IconDefaults } from '@/components/icon-defaults';
import { IconRobot } from '@/components/icon-robot';

const defaultOrganisations: OrganisationMenuProps[] = [];

const defaultSelection: SettingsMenuSelection = {
  type: 'user',
  key: 'profile',
};

const variant = (v: boolean) => (v ? 'accent' : 'default');

type MenuItemConfiguration = {
  title: string;
  flag?: string;
  key: string;
  icon?: any;
  path: string;
  selection: SettingsMenuSelection;
};

type MenuConfiguration = {
  section: string;
  key: string;
  items: MenuItemConfiguration[];
};

const userMenuConfiguration: MenuConfiguration = {
  section: 'Your account',
  key: 'user-section',
  items: compact([
    {
      title: 'Profile',
      key: 'profile',
      icon: 'user',
      path: '/settings/account/profile',
      selection: { type: 'user', key: 'profile' },
    },
    {
      title: 'Product defaults',
      key: 'defaults',
      icon: <IconDefaults />,
      path: '/settings/account/defaults',
      selection: { type: 'user', key: 'defaults' },
    },
    {
      title: 'Usage analytics',
      key: 'analytics',
      icon: 'toggle',
      path: '/settings/account/analytics',
      selection: { type: 'user', key: 'analytics' },
    },
    {
      title: 'Access keys',
      icon: 'connection',
      key: 'keys',
      path: '/settings/account/access-keys',
      selection: { type: 'user', key: 'keys' },
    },
  ]),
};

const organisationMenuConfiguration = (
  organisation: OrganisationMenuProps,
  flags: { [key: string]: boolean },
): MenuConfiguration => ({
  section: organisation.name,
  key: `${organisation.id}`,
  items: [
    {
      key: 'profile',
      title: 'Profile and region',
      icon: 'building',
      path: `/settings/organisation/${organisation.id}/profile`,
      selection: { type: 'organisation', id: organisation.id, key: 'profile' },
    },
    {
      key: 'members',
      title: 'Members',
      icon: 'team',
      path: `/settings/organisation/${organisation.id}/members`,
      selection: { type: 'organisation', id: organisation.id, key: 'members' },
    },
    {
      key: 'service-accounts',
      title: 'Service accounts',
      flag: 'app.service-accounts',
      icon: <IconRobot />,
      path: `/settings/organisation/${organisation.id}/service-accounts`,
      selection: {
        type: 'organisation',
        id: organisation.id,
        key: 'service-accounts',
      },
    },
    {
      title: 'Product defaults',
      key: 'defaults',
      icon: <IconDefaults />,
      path: `/settings/organisation/${organisation.id}/defaults`,
      selection: { type: 'organisation', id: organisation.id, key: 'defaults' },
    },
    {
      key: 'licences',
      title: 'Licences',
      icon: <IconLicences />,
      path: `/settings/organisation/${organisation.id}/licences`,
      selection: { type: 'organisation', id: organisation.id, key: 'licences' },
    },
    {
      key: 'sso',
      title: 'Single sign-on',
      icon: 'sso',
      path: `/settings/organisation/${organisation.id}/sso`,
      selection: { type: 'organisation', id: organisation.id, key: 'sso' },
    },
  ].filter(({ flag }) => isNil(flag) || flags[flag]) as MenuItemConfiguration[],
});

const newOrganisationMenuConfiguration = (
  selection: SettingsMenuSelection,
): MenuConfiguration[] =>
  selection.type !== 'new-organisation'
    ? []
    : [
        {
          section: 'New organisation',
          key: `new`,
          items: [
            {
              key: 'profile',
              title: 'Profile and region',
              icon: 'building',
              path: `/settings/organisation/new`,
              selection: { type: 'new-organisation', key: 'new-organisation' },
            },
          ],
        },
      ];

const emptyFlags = {};
const SettingsMenu = (props: SettingsMenuProps) => {
  const {
    organisations = defaultOrganisations,
    selection = defaultSelection,
    flags = emptyFlags,
  } = props;
  const menuConfiguration = useMemo(
    () =>
      [userMenuConfiguration]
        .concat(
          organisations.map((organisation: OrganisationMenuProps) =>
            organisationMenuConfiguration(organisation, flags),
          ),
        )
        .concat(newOrganisationMenuConfiguration(selection)),
    [organisations, selection, flags],
  );

  return (
    <Menu>
      {menuConfiguration.map((section) => (
        <Menu.Item header content={section.section} key={section.key}>
          {section.items.map((item) => (
            <Menu.Item
              as={Link}
              icon={item.icon}
              to={item.path}
              key={item.key}
              content={item.title}
              variant={variant(isEqual(selection, item.selection))}
            />
          ))}
        </Menu.Item>
      ))}
      <Menu.Item
        icon='plus'
        as={Link}
        to='/settings/organisation/new'
        content='New organisation'
      />
      <Menu.Item content={<Divider />} />
      <Menu.Item
        as={Link}
        to='/settings/acknowledgements'
        content='Acknowledgements'
        variant={variant(selection.type === 'acknowledgements')}
      />
    </Menu>
  );
};

export type UserSelection = {
  type: 'user';
  key: 'profile' | 'password' | 'analytics' | 'keys' | 'defaults';
};

export type NewOrganisationSelection = {
  type: 'new-organisation';
  key: 'new-organisation';
};

export type OrganisationSelection = {
  type: 'organisation';
  id: number;
  key:
    | 'profile'
    | 'members'
    | 'service-accounts'
    | 'licences'
    | 'sso'
    | 'defaults';
};

export type AcknowledgementsSelection = {
  type: 'acknowledgements';
  key: 'acknowledgements';
};

export type SettingsMenuSelection =
  | UserSelection
  | OrganisationSelection
  | NewOrganisationSelection
  | AcknowledgementsSelection;

export type OrganisationMenuProps = {
  id: number;
  name: string;
};

export type SettingsMenuProps = {
  organisations?: OrganisationMenuProps[];
  selection?: SettingsMenuSelection;
  flags?: { [key: string]: boolean };
};

export { SettingsMenu, defaultSelection };

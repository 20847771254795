import { useCallback } from 'react';
import { Content, Stack, Button, H2, Text, Strong } from '@kinesis/bungle';
import { noop } from 'lodash/fp';
import { KinesisLogo } from '@/components/kinesis-logo';
import { Center } from '@/components/center';
import { ButtonText } from '@/components/button-text';

const RegistrationSso = (props: RegistrationSsoProps) => {
  const {
    onLogin = noop,
    onUseCredentials = noop,
    organisation,
    organisationMandatesSso,
    email,
    isLoading = false,
  } = props;

  const onLoginSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      onLogin();
    },
    [onLogin],
  );

  const onUseCredentialsClick = useCallback(() => {
    onUseCredentials({
      email,
    });
  }, [onUseCredentials, email]);

  return (
    <Content background='white' borderRadiusSize='large' width={304}>
      <Stack space='none'>
        <Content padding='large' sizing='fill-container' paddingMode='equal'>
          <Stack space='medium'>
            <Center>
              <KinesisLogo />
            </Center>
            <Center>
              <H2>Join Kinesis</H2>
            </Center>
          </Stack>
        </Content>
        <Content
          background='gray'
          borderTop
          sizing='fill-container'
          padding='medium'
          paddingMode='equal'
          borderRadiusSize='large'
          borderRadiusPlacement='bottom'
        >
          <form onSubmit={onLoginSubmit} noValidate>
            <Stack space='large'>
              <Stack space='medium'>
                <Text>
                  Kinesis supports single sign-on with your organisation. You
                  can log in using your <Strong>{organisation}</Strong> account
                  to continue.
                </Text>
              </Stack>
              <Stack space='medium'>
                <Button type='submit' display='block' isLoading={isLoading}>
                  Log in via {organisation}
                </Button>
                {!organisationMandatesSso && (
                  <Center>
                    <ButtonText
                      onClick={isLoading ? noop : onUseCredentialsClick}
                    >
                      Create an account with password
                    </ButtonText>
                  </Center>
                )}
              </Stack>
            </Stack>
          </form>
        </Content>
      </Stack>
    </Content>
  );
};

export type UseCredentialsEmail = {
  email: string;
};
export type RegistrationSsoProps = {
  onLogin?: () => void;
  onUseCredentials?: (v: UseCredentialsEmail) => void;
  organisation: string;
  organisationMandatesSso?: boolean;
  email: string;
  isLoading?: boolean;
};

export { RegistrationSso };

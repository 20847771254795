import { Icon } from '@kinesis/bungle';

const IconWorkspaces = (props: IconWorkspacesProps) => {
  const { magnitude = 'xlarge' } = props;
  return <Icon type='map-filled' magnitude={magnitude} color='#932385' />;
};

export type IconWorkspacesProps = {
  magnitude?: string;
};

export { IconWorkspaces };

import { useCallback } from 'react';
import { ConfirmationDialog } from '@kinesis/bungle';
import { noop } from 'lodash/fp';

const AccessKeyDeleteConfirmation = (
  props: AccessKeyDeleteConfirmationProps,
) => {
  const { onDeleteKey = noop, onClose = noop, animation = true } = props;
  const onConfirm = useCallback(() => {
    onDeleteKey();
    onClose();
  }, [onDeleteKey, onClose]);
  return (
    <ConfirmationDialog
      animation={animation}
      confirmText='Delete key'
      cancelText='Don’t delete'
      icon='exclamation'
      title='Delete access key?'
      onCancel={onClose}
      onConfirm={onConfirm}
      showCancelButton
      variant='danger'
      minWidth={320}
    >
      Any applications or integrations using this key will no longer be able to
      access Kinesis.
    </ConfirmationDialog>
  );
};

export type AccessKeyDeleteConfirmationProps = {
  onDeleteKey?: () => void;
  onClose?: () => void;
  animation?: boolean;
};

export { AccessKeyDeleteConfirmation };

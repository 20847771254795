import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useToast } from '@kinesis/bungle';
import { useSettingsSelection } from '@/hooks/use-settings-selection';
import {
  useListOrganisationsQuery,
  organisationByIdSelector,
  useUpdateOrganisationMutation,
} from '@/api/organisation';
import { Loading } from '@/components/loading';
import { OrganisationProfile } from '@/components/organisation-profile';
import { UpdateOrganisation } from '@/types';

const SettingsOrganisationProfile = () => {
  const toast = useToast('globalTop');
  const { id } = useSettingsSelection() as any;
  const { isLoading } = useListOrganisationsQuery();
  const organisation = useSelector((state: any) =>
    organisationByIdSelector(state, id),
  );
  const [updateOrganisation] = useUpdateOrganisationMutation();
  const onUpdateOrganisationProfile = useCallback(
    async (v: UpdateOrganisation) => {
      try {
        await updateOrganisation(v);
        toast('Organisation settings saved.', { variant: 'success' });
      } catch (e) {
        toast(
          'We couldn’t save your organisation settings. Please try again.',
          { variant: 'error' },
        );
      }
    },
    [updateOrganisation, toast],
  );

  if (!isLoading && organisation) {
    return (
      <OrganisationProfile
        key={id}
        organisation={organisation}
        onUpdateOrganisationProfile={onUpdateOrganisationProfile}
      />
    );
  } else {
    return <Loading />;
  }

  return null;
};

export { SettingsOrganisationProfile };

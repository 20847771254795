import styled from 'styled-components';

const ResponsiveMinMax = styled.div<{ variant?: 'default' | 'narrow' }>(
  ({ variant = 'default' }) => ({
    display: 'grid',
    gridTemplateColumns: {
      default: 'minmax(482px,932px)',
      narrow: 'minmax(482px,680px)',
    }[variant],
  }),
);

export { ResponsiveMinMax };

import { useCallback, useState, useEffect, useMemo } from 'react';
import axios, { AxiosProgressEvent } from 'axios';
import {
  Avatar,
  Button,
  CenteredPanel,
  Container,
  Content,
  Footer,
  Frame,
  Inline,
  InlineItem,
  Secondary,
  Stack,
  Strong,
  Text,
  Toaster,
  useToast,
} from '@kinesis/bungle';
import { filter, map, pipe, set, unset } from 'lodash/fp';
import { FileUploadSuccess } from '@/components/file-upload-success';
import { FileUploader } from '@/components/file-uploader';
import { PoweredByKinesis } from '@/components/powered-by-kinesis';
import { UploadCard, UploadCardProps } from '@/components/upload-card';
import { useParams } from 'react-router-dom';
import { ValidFileExtension } from '@/types';
import {
  useGetCollectionPortalQuery,
  useCreateCollectionPortalUploadMutation,
  useSubmitCollectionPortalUploadMutation,
} from '@/api/city';

const validFileExtensions: ValidFileExtension[] = [
  { fileExtension: '.csv', label: 'CSV' },
  { fileExtension: '.jpeg' },
  { fileExtension: '.jpg', label: 'JPG' },
  { fileExtension: '.parquet' },
  { fileExtension: '.pdf', label: 'PDF' },
  { fileExtension: '.png', label: 'PNG' },
  { fileExtension: '.xls' },
  { fileExtension: '.xlsx', label: 'XLSX' },
  { fileExtension: '.txt' },
  { fileExtension: '.markdown' },
  { fileExtension: '.docx' },
];

const CityPortal = () => {
  const portalUrl = useParams<{ token: string }>();
  const token = String(portalUrl.token);
  const { data } = useGetCollectionPortalQuery(token);
  const toast = useToast('globalTop');
  const [fileUploadStates, setFileUploadStates] = useState<{
    [key: string]: FileUploadState;
  }>({});
  const [createUpload] = useCreateCollectionPortalUploadMutation();
  const [submitUpload] = useSubmitCollectionPortalUploadMutation();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submittedSuccessfully, setSubmittedSuccessfully] = useState(false);

  const handleDeleteUploadCard = useCallback(
    (identifier: string) => () => {
      setFileUploadStates((prev) => unset(identifier, prev));
    },
    [setFileUploadStates],
  );

  const uploadableFiles = useMemo(
    () => filter({ state: 'uploaded' }, fileUploadStates),
    [fileUploadStates],
  );

  const uploadingFiles = useMemo(
    () => filter({ state: 'uploading' }, fileUploadStates),
    [fileUploadStates],
  );

  const hasUploadableFiles = uploadableFiles.length > 0;
  useEffect(() => {
    // Add warning message if user tries to navigate away when there are uploadable files
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (hasUploadableFiles) {
        event.preventDefault();
        event.returnValue = ''; // Required for Chrome
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [hasUploadableFiles]);

  const handleSubmit = useCallback(async () => {
    setIsSubmitting(true);

    const filesToSubmit = pipe(
      filter(
        (fileUploadState: FileUploadState) =>
          fileUploadState.state === 'uploaded' &&
          !!fileUploadState.key &&
          !!fileUploadState.format,
      ),
      map(
        (fileUploadState: FileUploadState) =>
          ({
            format: fileUploadState.format,
            key: fileUploadState.key,
            name: fileUploadState.fileName,
          }) as any,
      ),
    )(fileUploadStates);

    try {
      await submitUpload({
        token: token,
        uploads: filesToSubmit,
      }).unwrap();

      // Successful upload to oblivion
      setSubmittedSuccessfully(true);
      setFileUploadStates({});
    } catch (e) {
      toast(`We couldn’t submit your files. Please try again.`, {
        variant: 'error',
      });
    } finally {
      setIsSubmitting(false);
    }
  }, [fileUploadStates, submitUpload, toast, token]);

  const handleFileUploaderChange = useCallback(
    (files: FileList) => {
      const validExts = validFileExtensions.map(
        (validFileExtension) => validFileExtension.fileExtension,
      );
      Array.from(files).forEach(async (file: File, fileIndex: number) => {
        const fileExt = file.name.includes('.')
          ? file.name.split('.').pop()
          : undefined;
        if (fileExt === undefined) {
          return toast('Files with no extension are not supported.', {
            variant: 'error',
          });
        } else if (!validExts.includes(`.${fileExt}`.toLowerCase())) {
          return toast(`${fileExt.toUpperCase()} files are not supported.`, {
            variant: 'error',
          });
        }

        // Setup unique identifier and add initial file state
        const identifier = `${Date.now()}-${fileIndex}`;
        setFileUploadStates((prev) =>
          set(
            identifier,
            {
              fileName: file.name,
              format: fileExt.toLowerCase(),
              identifier,
              percentageUploaded: 0,
              state: 'uploading',
            } as FileUploadState,
            prev,
          ),
        );
        const setCurrentFileUploadState = (
          key: string,
          value: string | number,
        ) => setFileUploadStates((prev) => set([identifier, key], value, prev));

        try {
          // Fetch key and url from transfer
          const { key, url } = await createUpload({
            token,
            format: fileExt.toLowerCase(),
          }).unwrap();

          // Set key
          setCurrentFileUploadState('key', key);

          // Update percentage uploaded
          const onUploadProgress = (event: AxiosProgressEvent) => {
            setCurrentFileUploadState(
              'percentageUploaded',
              Math.round((event.progress || 0) * 100),
            );
          };

          // Upload file
          const { status } = await axios.put(url, file, {
            headers: {
              'Content-Type': file.type,
              'x-amz-server-side-encryption': 'AES256',
            },
            onUploadProgress,
          });

          // Successful upload
          if (status === 200) {
            setCurrentFileUploadState('state', 'uploaded');
          }
        } catch (e) {
          setCurrentFileUploadState('state', 'error');
        }
      });
    },
    [createUpload, toast, token],
  );

  const handleResetSuccessPage = useCallback(() => {
    setSubmittedSuccessfully(false);
  }, []);

  // Successful submission page
  if (submittedSuccessfully && data) {
    return (
      <Content alignX='center' height='100%' minWidth={528}>
        <CenteredPanel centerY>
          <Content padding='medium' paddingMode='equal'>
            <FileUploadSuccess
              totalFilesUploaded={uploadableFiles.length}
              organisation={data.owner.name}
              onClick={handleResetSuccessPage}
            />
          </Content>
        </CenteredPanel>
        <Content padding='medium' paddingMode='equal'>
          <PoweredByKinesis />
        </Content>
      </Content>
    );
  }

  if (data) {
    return (
      <Content height='100%' minWidth={528}>
        <Toaster id='globalTop' placement='top' />
        <Container>
          <Frame>
            <Content
              background='gray'
              borderRadiusSize='large'
              height='100%'
              overflow='scroll'
              sizing='fill-container'
            >
              <Content alignX='center' padding='medium' paddingMode='equal'>
                <Content width={496}>
                  <Stack space='medium'>
                    <Inline>
                      <InlineItem>
                        <Avatar
                          variant='organisation'
                          magnitude='large'
                          image={data.owner.avatar}
                        >
                          {data.owner.name.slice(0, 1)}
                        </Avatar>
                      </InlineItem>
                      <InlineItem sizing='fill-container'>
                        <Stack space='none'>
                          <Text size='large'>
                            <Strong>
                              {data.name ? data.name : 'City collection portal'}
                            </Strong>
                          </Text>
                          <Inline>
                            <InlineItem ellipsify>
                              <Text>
                                <Secondary>{data.name}</Secondary>
                              </Text>
                            </InlineItem>
                          </Inline>
                        </Stack>
                      </InlineItem>
                      <InlineItem>
                        <PoweredByKinesis />
                      </InlineItem>
                    </Inline>
                    <FileUploader
                      disabled={isSubmitting}
                      onChange={handleFileUploaderChange}
                      validFileExtensions={validFileExtensions}
                    />
                    {Object.values(fileUploadStates).map(
                      (fileUploadState: FileUploadState) => (
                        <UploadCard
                          key={fileUploadState.identifier}
                          fileName={fileUploadState.fileName}
                          state={fileUploadState.state}
                          percentageUploaded={
                            fileUploadState.percentageUploaded
                          }
                          onDelete={handleDeleteUploadCard(
                            fileUploadState.identifier,
                          )}
                          deleteButtonDisabled={isSubmitting}
                        />
                      ),
                    )}
                  </Stack>
                </Content>
              </Content>
            </Content>
          </Frame>
          <Footer border shadow>
            <Content alignX='center' padding='medium' paddingMode='equal'>
              <Content width={496}>
                <Button
                  disabled={
                    !uploadableFiles.length || uploadingFiles.length > 0
                  }
                  onClick={handleSubmit}
                  variant='primary'
                  loading={isSubmitting}
                >
                  Submit {uploadableFiles.length ? uploadableFiles.length : ''}{' '}
                  {uploadableFiles.length === 1 ? 'file' : 'files'}
                </Button>
              </Content>
            </Content>
          </Footer>
        </Container>
      </Content>
    );
  }
};

type FileUploadState = UploadCardProps & {
  format?: string;
  identifier: string;
  key?: string;
};

export { CityPortal };

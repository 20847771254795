import { api } from './api';
import { loginApi } from './login';
import { AcceptedLogin } from '@/types';

const registrationApi = api.injectEndpoints({
  endpoints: (builder) => ({
    register: builder.mutation<RegisterResponse, RegisterRequest>({
      query: ({ email, allowSso }) => ({
        url: 'registration',
        method: 'POST',
        body: {
          email,
          allowSso,
        },
      }),
    }),
    registerProfile: builder.mutation<
      RegisterProfileResponse,
      RegisterProfileRequest
    >({
      query: (request) => ({
        url: `registration/${request.token}`,
        method: 'POST',
        body: request,
        credentials: 'include',
      }),
    }),
    registerTerms: builder.mutation<
      RegisterTermsResponse,
      RegisterTermsRequest
    >({
      query: (request) => ({
        url: `registration/terms`,
        method: 'POST',
        body: request,
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        const { data: session } = await queryFulfilled;
        dispatch(loginApi.util.upsertQueryData('session', undefined, session));
      },
    }),
    registerOrganisation: builder.mutation<
      RegisterOrganisationResponse,
      RegisterOrganisationRequest
    >({
      query: (request) => ({
        url: `registration/organisations`,
        method: 'POST',
        body: request,
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        const { data: session } = await queryFulfilled;
        dispatch(loginApi.util.upsertQueryData('session', undefined, session));
      },
    }),
  }),
});

type RegisterRequest = {
  allowSso: boolean;
  email: string;
};

type RegisterResponse =
  | { method: 'code'; email: string; token: string }
  | { method: 'sso'; organisation: string; email: string; redirectUrl: string };

type RegisterProfileRequest = {
  firstName: string;
  lastName: string;
  jobTitle: string;
  email: string;
  password: string;
  code: string;
  token: string;
  acceptTerms: boolean;
};

type RegisterTermsResponse = AcceptedLogin;

type RegisterTermsRequest = {
  acceptTerms?: boolean;
  analytics?: 'full' | 'limited';
};

type RegisterProfileResponse = AcceptedLogin;

type RegisterOrganisationRequest = {
  name: string;
  industry: string;
  dataStorage: string;
};

type RegisterOrganisationResponse = AcceptedLogin;

export const {
  useRegisterMutation,
  useRegisterProfileMutation,
  useRegisterOrganisationMutation,
  useRegisterTermsMutation,
} = registrationApi;

import { Anchor, Stack, Text } from '@kinesis/bungle';
import { CardWrapper } from './licenses.styles';
import { License } from './license';
import { Licence } from '@/types';
import { Layout } from '@/components/layout';

const Licenses = (props: LicenseListProps) => {
  const { licences } = props;
  return (
    <Layout variant='narrow'>
      <Stack space='medium'>
        <CardWrapper>
          {licences.map((l: Licence) => (
            <License licence={l} key={l.key} />
          ))}
        </CardWrapper>

        <Text>
          <Anchor href='mailto:support@kinesis.org?Subject=Kinesis licenses'>
            Contact us
          </Anchor>{' '}
          to license additional Kinesis apps and manage your licenses.
        </Text>
      </Stack>
    </Layout>
  );
};

export type LicenseListProps = {
  licences: Licence[];
};

export { Licenses };

import { Outlet, Navigate, useMatches } from 'react-router-dom';
import { isNil } from 'lodash/fp';
import { useSessionQuery } from '@/api/login';
import { launch } from '@/services/launch';

const App = () => {
  const { data: session, isSuccess, isLoading } = useSessionQuery();
  const matches = useMatches();
  const publicOnly = !!matches.find(
    ({ handle }) => (handle as any)?.publicOnly,
  );

  // Catches logged in users accessing a publicOnly route, which includes the root
  if (!isLoading && isSuccess && publicOnly && session.source !== 'login') {
    if (!session.acceptedTerms || isNil(session.analytics)) {
      return <Navigate to='/registration/terms' />;
    } else if (session.orphan) {
      return <Navigate to='/registration/complete' />;
    } else {
      launch(session.target, true);
    }
  }

  if (!isLoading) {
    // NOTE: this looks odd, but it guards against loading
    // flash because we are doing the window replace above
    // while it is rendering. We could put the above in a
    // useEffect, but it will still require this guard, and
    // we would have to wait for rendering to complete before
    // redirecting, so we just delay boout against the inverse
    // of the condition above.
    if (!isSuccess || !publicOnly) {
      return <Outlet />;
    }
  }
  return null;
};

export { App };

import {
  Stack,
  Input,
  Placeholder,
  Container,
  Frame,
  Header,
  Content,
} from '@kinesis/bungle';
import { Toolbar, ToolbarBackground } from '@kinesis/bungle/legacy';
import { useState } from 'react';
import type { Flag } from '../../types';
import { FeatureFlag } from './feature-flag';
import { PaddingDiv } from './feature-flag.styles';

const FeatureFlagCard = (props: FeatureFlagCardProps) => {
  const { featureFlagList, onToggleFlag } = props;
  const [filters, setFilters] = useState<FilterState>({ query: '' });
  const filteredFeatureFlagList = featureFlagList.filter((flag) =>
    flag.name.toLowerCase().includes(filters.query.toLowerCase()),
  );

  return (
    <Container>
      <Header>
        <ToolbarBackground>
          <Content>
            <PaddingDiv>
              <Toolbar>
                <Input
                  type='search'
                  placeholder='Filter feature flags'
                  onChange={(value: string) => {
                    setFilters({ query: value || '' });
                  }}
                  value={filters.query}
                />
              </Toolbar>
            </PaddingDiv>
          </Content>
        </ToolbarBackground>
      </Header>
      <Frame>
        <Content padding='medium'>
          <Stack space='xsmall'>
            {filteredFeatureFlagList.map((flag) => (
              <FeatureFlag
                key={flag.id}
                id={flag.id}
                enabled={flag.enabled}
                editable={flag.editable}
                name={flag.name}
                description={flag.description}
                toggle={() =>
                  onToggleFlag ? onToggleFlag(flag.name) : undefined
                }
              />
            ))}

            {filteredFeatureFlagList.length === 0 && (
              <Placeholder>There are no matching feature flags.</Placeholder>
            )}
          </Stack>
        </Content>
      </Frame>
    </Container>
  );
};

type FeatureFlagCardProps = {
  featureFlagList: Flag[];
  onToggleFlag?: (name: string) => void;
};

type FilterState = {
  query: string;
};

export { FeatureFlagCard };

import { useCallback, useState } from 'react';
import { useTheme } from 'styled-components';
import {
  Icon,
  Text,
  Stack,
  Divider,
  AvatarUploader,
  LabelText,
  Input,
  Select,
  Button,
  UtilityButton,
  Inline,
  InlineItem,
  Content,
} from '@kinesis/bungle';
import { Organisation, UpdateOrganisation } from '@/types';
import { OrganisationProfileDeleteConfirmation } from './organisation-profile-delete-confirmation';
import { Layout } from '@/components/layout';
import { noop, isEmpty } from 'lodash/fp';

const dataStorageOptions = [
  { value: 'au', label: 'Australia' },
  { value: 'us', label: 'United States' },
];

const industries = [
  { label: 'Manufacturing', value: 'Manufacturing' },
  { label: 'Retail', value: 'Retail' },

  { label: 'Financial services', value: 'Financial services' },
  {
    label: 'Mobility and transportation',
    value: 'Mobility and transportation',
  },
  {
    label: 'Property development and management',
    value: 'Property development and management',
  },
  { label: 'Public sector', value: 'Public sector' },
  { label: 'Utilities', value: 'Utilities' },
  { label: 'Other', value: 'Other' },
];

const OrganisationProfile = (props: OrganisationProfileProps) => {
  const { organisation, onUpdateOrganisationProfile = noop } = props;
  const { id, avatar, name, industry, dataStorage } = organisation;
  const theme = useTheme() as any;
  const [avatarState, setAvatarState] = useState(avatar);
  const [nameState, setNameState] = useState(name);
  const [industryState, setIndustryState] = useState(industry);
  const isDirty =
    avatarState !== avatar || nameState !== name || industryState !== industry;
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const onSave = useCallback(() => {
    onUpdateOrganisationProfile({
      id: id,
      avatar: avatarState,
      name: nameState,
      industry: industryState,
    });
  }, [onUpdateOrganisationProfile, avatarState, nameState, industryState, id]);

  const onOpenDeleteConfirmation = useCallback(() => {
    setDeleteConfirmation(true);
  }, [setDeleteConfirmation]);

  const onCloseDeleteConfirmation = useCallback(() => {
    setDeleteConfirmation(false);
  }, [setDeleteConfirmation]);

  return (
    <>
      {deleteConfirmation && (
        <OrganisationProfileDeleteConfirmation
          onClose={onCloseDeleteConfirmation}
          name={nameState}
        />
      )}
      <Layout variant='narrow'>
        <Stack space='large'>
          <Stack space='medium'>
            <Stack space='medium'>
              {isEmpty(avatarState) && (
                <>
                  <Inline alignY='center'>
                    <InlineItem>
                      <Icon
                        type='info-circle'
                        color={theme.color.yellow6}
                        magnitude='small'
                      />
                    </InlineItem>
                    <InlineItem>
                      <Text>Add a logo to personalise your organisation.</Text>
                    </InlineItem>
                  </Inline>
                  <Divider />
                </>
              )}
              <Stack space='xsmall'>
                <LabelText>Logo</LabelText>
                <AvatarUploader
                  variant='organisation'
                  image={avatarState}
                  onChange={setAvatarState}
                />
              </Stack>
            </Stack>
            <Stack space='medium'>
              <Inline>
                <InlineItem sizing='fill-container'>
                  <Input
                    label='Name'
                    magnitude='large'
                    value={nameState}
                    onChange={setNameState}
                    placeholder='Enter name'
                  />
                </InlineItem>
              </Inline>
              <Select
                label='Industry'
                magnitude='large'
                options={industries}
                value={industryState}
                onChange={setIndustryState}
              />
              <Select
                label='Data storage location'
                magnitude='large'
                options={dataStorageOptions}
                value={dataStorage}
                message={
                  <Text tone='secondary'>
                    This cannot be changed once an organisation is created.
                  </Text>
                }
                disabled
              />
            </Stack>
          </Stack>
          <Stack space='medium'>
            <Stack space='large'>
              <Inline>
                <InlineItem>
                  <Button
                    variant='primary'
                    onClick={onSave}
                    disabled={!isDirty}
                  >
                    Save changes
                  </Button>
                </InlineItem>
              </Inline>
              <Divider />
            </Stack>
            <Inline>
              <InlineItem>
                <UtilityButton
                  variant='danger'
                  onClick={onOpenDeleteConfirmation}
                >
                  Delete organisation
                </UtilityButton>
              </InlineItem>
            </Inline>
          </Stack>
          <Content padding='none' />
        </Stack>
      </Layout>
    </>
  );
};

export type OrganisationProfileProps = {
  onUpdateOrganisationProfile?: (update: UpdateOrganisation) => void;
  organisation: Organisation;
};

export { OrganisationProfile };

import { Anchor, Button, Icon, Strong } from '@kinesis/bungle';
import { noop } from 'lodash/fp';
import { useTheme } from 'styled-components';
import { InformationPanel } from '@/components/information-panel';

const FileUploadSuccess = (props: Props) => {
  const {
    totalFilesUploaded,
    organisation,
    onClick = noop,
    buttonText = 'Upload more files',
  } = props;
  const theme = useTheme() as any;
  return (
    <InformationPanel
      renderIcon={
        <Icon
          type='check-circle'
          magnitude='xxlarge'
          color={theme.color.green5}
        />
      }
      heading='Thanks, we’ll take it from here'
      renderButton={
        <Button variant='primary' onClick={onClick}>
          {buttonText}
        </Button>
      }
    >
      We’ll start processing the{' '}
      {totalFilesUploaded > 1 ? `${totalFilesUploaded} files ` : 'file '}
      you uploaded shortly. There is nothing else you need to do, though we may
      reach out if we need help to understand the data.{' '}
      <Strong>{organisation}</Strong> will be able to see this data in Kinesis
      once your {totalFilesUploaded > 1 ? 'files are' : 'file is'} processed.{' '}
      <Anchor href='mailto:support@kinesis.org?Subject=Question about uploading files'>
        Contact us
      </Anchor>{' '}
      if you have any questions.
    </InformationPanel>
  );
};

type Props = {
  totalFilesUploaded: number;
  organisation: string;
  onClick: () => void;
  buttonText?: string;
};

export { FileUploadSuccess };

import styled from 'styled-components';

const InvitedMemberAvatar = styled.div({
  width: 24,
  height: 24,
  borderRadius: '50%',
  border: '1px dashed rgba(0, 0, 0, 0.15)',
});

export { InvitedMemberAvatar };

import styled from 'styled-components';

const Center = styled.div({
  margin: '0 auto',
  height: '100%',
  width: 'fit-content',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
});

export { Center };

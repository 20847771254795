import { useSelector } from 'react-redux';
import { useSettingsSelection } from '@/hooks/use-settings-selection';
import {
  useListOrganisationsQuery,
  organisationByIdSelector,
} from '@/api/organisation';
import { Loading } from '@/components/loading';
import { Sso } from '@/components/sso';

const SettingsOrganisationSso = () => {
  const { id } = useSettingsSelection() as any;
  const { isLoading } = useListOrganisationsQuery();
  const organisation = useSelector((state: any) =>
    organisationByIdSelector(state, id),
  );
  if (!isLoading && organisation) {
    return (
      <Sso
        key={id}
        ssoEnabled={organisation.ssoEnabled}
        mandatesSso={organisation.mandatesSso}
        organisationName={organisation.name}
      />
    );
  } else {
    return <Loading />;
  }

  return null;
};

export { SettingsOrganisationSso };

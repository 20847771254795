import {
  Content,
  Inline,
  InlineItem,
  Icon,
  Stack,
  Heading,
  Secondary,
  Tooltip,
  UtilityButton,
} from '@kinesis/bungle';
import { useTheme } from 'styled-components';
import { Loading } from '../loading';
import { noop } from 'lodash/fp';

const UploadCard = (props: UploadCardProps) => {
  const {
    fileName,
    state,
    percentageUploaded,
    onDelete = noop,
    expanded = false,
    deleteButtonDisabled = false,
  } = props;
  const theme = useTheme() as any;
  return (
    <Content
      background='white'
      border
      borderRadiusSize='small'
      padding='medium'
      paddingMode='equal'
    >
      <Inline>
        <InlineItem>
          {state === 'uploading' && <Loading magnitude='medium'></Loading>}
          {(state === 'error' || state === 'uploaded') && (
            <Icon
              type={
                {
                  uploaded: 'check-circle',
                  error: 'info-circle',
                }[state]
              }
              magnitude='medium'
              color={
                {
                  uploaded: theme.color.green6,
                  error: theme.color.red6,
                }[state]
              }
            />
          )}
        </InlineItem>
        <InlineItem sizing='fill-container'>
          <Stack space='none'>
            <Heading as='h3' size='medium'>
              {fileName}
            </Heading>
            <Secondary>
              {
                {
                  uploading: `Uploading (${percentageUploaded}%)`,
                  uploaded: 'Uploaded',
                  error:
                    'There was a problem uploading this file, please try again',
                }[state]
              }
            </Secondary>
          </Stack>
        </InlineItem>
        <InlineItem sizing='fit-content'>
          <Tooltip
            placement='top'
            title='Remove file'
            defaultExpanded={expanded}
          >
            <UtilityButton
              icon='delete'
              magnitude='small'
              variant='danger'
              onClick={onDelete}
              disabled={deleteButtonDisabled}
            />
          </Tooltip>
        </InlineItem>
      </Inline>
    </Content>
  );
};

export type UploadCardProps = {
  fileName: string;
  state: 'uploading' | 'uploaded' | 'error';
  percentageUploaded?: number;
  onDelete: () => void;
  expanded?: boolean;
  deleteButtonDisabled?: boolean;
};

export { UploadCard };

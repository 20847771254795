import { useCallback } from 'react';
import { useToast } from '@kinesis/bungle';
import { UserProfile } from '@/components/user-profile';
import { Loading } from '@/components/loading';
import { useMeQuery, useUpdateMeMutation } from '@/api/user';
import { MeUpdate } from '@/types';

const SettingsUserProfile = () => {
  const toast = useToast('globalTop');
  const { data: me, isLoading } = useMeQuery();
  const [updateMe] = useUpdateMeMutation();
  const onUpdateUser = useCallback(
    async (update: MeUpdate) => {
      try {
        await updateMe(update);
        toast('Profile settings saved.', { variant: 'success' });
      } catch (e) {
        toast('We couldn’t save your profile settings. Please try again.', {
          variant: 'error',
        });
      }
    },
    [updateMe, toast],
  );

  if (!isLoading && me) {
    return <UserProfile user={me} onChange={onUpdateUser} />;
  } else {
    return <Loading />;
  }
};

export { SettingsUserProfile };

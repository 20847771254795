import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useToast } from '@kinesis/bungle';
import { useCreateOrganisationMutation } from '@/api/organisation';
import { OrganisationProfileCreate } from '@/components/organisation-profile-create';
import { NewOrganisation } from '@/types';

const SettingsOrganisationNew = () => {
  const toast = useToast('globalTop');
  const navigate = useNavigate();
  const [createOrganisation] = useCreateOrganisationMutation();
  const onCreateOrganisationProfile = useCallback(
    async (v: NewOrganisation) => {
      try {
        const created = await createOrganisation(v).unwrap();
        navigate(`/settings/organisation/${created.id}/profile`);
        toast('Organisation created.', { variant: 'success' });
      } catch (e) {
        toast('We couldn’t create the new organisation.', { variant: 'error' });
      }
    },
    [createOrganisation, navigate, toast],
  );

  return (
    <OrganisationProfileCreate
      onCreateOrganisationProfile={onCreateOrganisationProfile}
    />
  );
};

export { SettingsOrganisationNew };

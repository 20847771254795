import { Product } from '@/types';
import { IconCity } from '@/components/icon-city';
import { IconIntegrated } from '@/components/icon-integrated';
import { IconNzo } from '@/components/icon-nzo';
import { IconPathways } from '@/components/icon-pathways';
import { IconWorkspaces } from '@/components/icon-workspaces';
import { IconBillManagement } from '@/components/icon-bill-management';

const ProductIcon = (props: ProductIconProps) => {
  const { product, magnitude = 'small' } = props;
  switch (product) {
    case 'city':
      return <IconCity magnitude={magnitude} />;
    case 'integrated':
      return <IconIntegrated magnitude={magnitude} />;
    case 'nzo':
      return <IconNzo magnitude={magnitude} />;
    case 'pathways':
      return <IconPathways magnitude={magnitude} />;
    case 'workspaces':
      return <IconWorkspaces magnitude={magnitude} />;
    case 'bill-management':
      return <IconBillManagement magnitude={magnitude} />;
  }
};

export type ProductIconProps = {
  product: Product;
  magnitude?: string;
};

export { ProductIcon };

import {
  Content,
  Frame,
  Container,
  Header,
  Inline,
  InlineItem,
} from '@kinesis/bungle';
import { Link } from 'react-router-dom';
import { ErrorPanel } from '@/components/error-panel';
import { Background } from '@/components/background';
import { ToolbarLeft } from '@/components/primary-navigation/primary-navigation.styles';
import { Layout } from '@/components/primary-navigation/layout';
import { Logo } from '@/components/logo';

const Error = (props: ErrorProps) => {
  const { variant } = props;
  return (
    <Container>
      <Header>
        <Content height={48}>
          <Background>
            <Layout>
              <ToolbarLeft>
                <Inline>
                  <InlineItem>
                    <Link to='/'>
                      <Logo />
                    </Link>
                  </InlineItem>
                </Inline>
              </ToolbarLeft>
            </Layout>
          </Background>
        </Content>
      </Header>
      <Frame>
        <Content sizing='fill-container' height='100%' background='gray'>
          <ErrorPanel variant={variant} />
        </Content>
      </Frame>
    </Container>
  );
};

export type ErrorProps = {
  variant?: 'server' | 'not-found' | 'unknown';
};

export { Error };

import { Launch } from '@/types';

const pathnameFor = (v: Launch) => {
  switch (v.namespace) {
    case 'city':
      return v.id ? `/city/${v.id}/` : '/city/';
    case 'integrated':
      return v.id ? `/integrated/${v.id}/` : '/integrated/';
    case 'workspace':
      return v.id ? `/workspaces/${v.id}` : '/workspaces/';
    case 'inventory':
      return v.id ? `/inventories/${v.id}` : '/inventories/';
    case 'dataset':
      return v.id ? `/datasets/${v.id}` : '/datasets/';
    case 'pathways':
      return '/pathways/';
    case 'bill-management':
      return v.id ? `/bill-management/${v.id}` : '/bill-management/';
  }
};

const launch = (v: Launch | undefined, replace: boolean) => {
  const l: Launch = v ? v : { namespace: 'workspace' };
  const pathname = pathnameFor(l);
  if (replace) {
    window.location.replace(pathname);
  } else {
    window.location.assign(pathname);
  }
};

export { launch };

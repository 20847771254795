import styled from 'styled-components';

const PaddingDiv = styled.div`
  padding-top: 8px;
  padding-bottom: 8px;
`;

const ToolbarLeft = styled.div({
  display: 'flex',
  flex: '1 1 auto',
});

const ToolbarRight = styled.div({
  display: 'flex',
  flex: '0 0 auto',
});

export { PaddingDiv, ToolbarLeft, ToolbarRight };

import { useCallback, useState } from 'react';
import {
  Content,
  Stack,
  Button,
  Input,
  H2,
  H4,
  Inline,
  InlineItem,
  Checkbox,
} from '@kinesis/bungle';
import { noop, every, identity, isEmpty } from 'lodash/fp';
import { KinesisLogo } from '@/components/kinesis-logo';
import { Center } from '@/components/center';
import { AnchorText } from '@/components/anchor-text';

const RegistrationProfile = (props: RegistrationProfileProps) => {
  const {
    onRegistration = noop,
    isRegistering = false,
    email,
    code,
    token,
  } = props;

  const [firstName, setFirstName] = useState('');
  const [firstNameError, setFirstNameError] = useState(false);
  const validateFirstName = useCallback(() => {
    const valid = !isEmpty(firstName);
    setFirstNameError(!valid);
    return valid;
  }, [firstName, setFirstNameError]);

  const [lastName, setLastName] = useState('');
  const [lastNameError, setLastNameError] = useState(false);
  const validateLastName = useCallback(() => {
    const valid = !isEmpty(lastName);
    setLastNameError(!valid);
    return valid;
  }, [lastName, setLastNameError]);

  const [jobTitle, setJobTitle] = useState('');
  const [jobTitleError, setJobTitleError] = useState(false);
  const validateJobTitle = useCallback(() => {
    setJobTitleError(false);
    return true;
  }, [setJobTitleError]);

  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState(false);
  const validatePassword = useCallback(() => {
    const valid = password.length >= 8;
    setPasswordError(!valid);
    return valid;
  }, [password, setPasswordError]);

  const [acceptTerms, setAcceptTerms] = useState(false);
  const [acceptTermsError, setAcceptTermsError] = useState(false);
  const toggleAcceptTerms = useCallback(
    () => setAcceptTerms((current) => !current),
    [setAcceptTerms],
  );
  const validateAcceptTerms = useCallback(() => {
    const valid = acceptTerms;
    setAcceptTermsError(!valid);
    return valid;
  }, [acceptTerms, setAcceptTermsError]);

  const onSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      const valid = every(identity, [
        validateAcceptTerms(),
        validateFirstName(),
        validateLastName(),
        validateJobTitle(),
        validatePassword(),
      ]);
      if (valid) {
        onRegistration({
          firstName,
          lastName,
          jobTitle,
          email,
          password,
          acceptTerms,
          code,
          token,
        });
      }
    },
    [
      validateAcceptTerms,
      validateFirstName,
      validateLastName,
      validateJobTitle,
      validatePassword,
      firstName,
      lastName,
      jobTitle,
      email,
      password,
      acceptTerms,
      onRegistration,
      code,
      token,
    ],
  );

  return (
    <Content background='white' borderRadiusSize='large' width={304}>
      <Stack space='none'>
        <Content padding='large' sizing='fill-container' paddingMode='equal'>
          <Stack space='medium'>
            <Center>
              <KinesisLogo />
            </Center>
            <Center>
              <H2>Join Kinesis</H2>
            </Center>
          </Stack>
        </Content>
        <Content
          background='gray'
          borderTop
          borderBottom
          sizing='fill-container'
          padding='medium'
          paddingMode='equal'
        >
          <form onSubmit={onSubmit} noValidate>
            <Stack space='large'>
              <Stack space='medium'>
                <H4>Your account</H4>
                <Inline space='small'>
                  <InlineItem sizing='fill-container'>
                    <Input
                      autoFocus
                      label='First name'
                      magnitude='large'
                      onBlur={validateFirstName}
                      onChange={setFirstName}
                      placeholder='Enter first name'
                      tone={firstNameError ? 'critical' : 'neutral'}
                      type='text'
                      value={firstName}
                    />
                  </InlineItem>
                  <InlineItem sizing='fill-container'>
                    <Input
                      label='Last name'
                      magnitude='large'
                      onBlur={validateLastName}
                      onChange={setLastName}
                      placeholder='Enter last name'
                      tone={lastNameError ? 'critical' : 'neutral'}
                      type='text'
                      value={lastName}
                    />
                  </InlineItem>
                </Inline>

                <Input
                  label='Job title'
                  magnitude='large'
                  onBlur={validateJobTitle}
                  onChange={setJobTitle}
                  placeholder='Enter job title'
                  tone={jobTitleError ? 'critical' : 'neutral'}
                  type='text'
                  value={jobTitle}
                />

                <Input
                  label='Password'
                  magnitude='large'
                  placeholder='Enter password'
                  value={password}
                  onChange={setPassword}
                  message='At least 8 characters.'
                  onBlur={validatePassword}
                  tone={passwordError ? 'critical' : 'neutral'}
                  type='password'
                />
                <Checkbox
                  checked={acceptTerms}
                  onChange={toggleAcceptTerms}
                  onBlur={validateAcceptTerms}
                  tone={acceptTermsError ? 'critical' : undefined}
                >
                  I agree to the Kinesis{' '}
                  <AnchorText
                    href='https://kinesis.org/privacy/'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    privacy policy
                  </AnchorText>{' '}
                  and{' '}
                  <AnchorText
                    href='https://kinesis.org/terms/'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    terms of use
                  </AnchorText>
                </Checkbox>
              </Stack>
              <Button type='submit' display='block' loading={isRegistering}>
                Create account
              </Button>
            </Stack>
          </form>
        </Content>
        <Content height='16px' />
        <Content
          background='gray'
          borderTop
          sizing='fill-container'
          padding='medium'
          paddingMode='equal'
          borderRadiusSize='large'
          borderRadiusPlacement='bottom'
        >
          <H4>Your organisation</H4>
        </Content>
      </Stack>
    </Content>
  );
};

export type RegistrationProfileDetails = {
  firstName: string;
  lastName: string;
  jobTitle: string;
  email: string;
  password: string;
  acceptTerms: boolean;
  code: string;
  token: string;
};

export type RegistrationProfileProps = {
  onRegistration?: (v: RegistrationProfileDetails) => void;
  isRegistering?: boolean;
  email: string;
  code: string;
  token: string;
};

export { RegistrationProfile };

import { useEffect } from 'react';
import { Outlet, Navigate, useNavigate, useMatches } from 'react-router-dom';
import { isNil } from 'lodash/fp';
import { useSessionQuery } from '@/api/login';

const Authenticated = () => {
  const { data: session, isSuccess, isError, isLoading } = useSessionQuery();

  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoading && isError) {
      if (window.location.pathname !== '/') {
        localStorage.setItem(
          'kinesis:redirect',
          `${window.location.pathname}${window.location.search}`,
        );
      }
      navigate('/');
    }
  }, [isLoading, isError, navigate]);

  const matches = useMatches();
  const invitationPage = !!matches.find(
    ({ handle }) => (handle as any)?.invitationOnly,
  );

  if (!isLoading && isSuccess) {
    if (!session.acceptedTerms || isNil(session.analytics)) {
      return <Navigate to='/registration/terms' />;
    } else if (session.pendingInvitations && invitationPage) {
      return <Outlet />;
    } else if (session.orphan) {
      return <Navigate to='/registration/complete' />;
    } else {
      return <Outlet />;
    }
  }

  return null;
};

export { Authenticated };

import { Icon } from '@kinesis/bungle';

/* eslint-disable max-len */
const IconNzo = (props: IconNzoProps) => {
  const { magnitude = 'xlarge' } = props;
  return (
    <Icon type='custom' magnitude={magnitude} color='#3BBEBE'>
      <svg
        width='1024'
        height='1024'
        viewBox='0 0 1024 1024'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path d='M926 860H94C76.3 860 62 845.7 62 828V188C62 170.3 76.3 156 94 156H926C943.7 156 958 170.3 958 188V828C958 845.7 943.7 860 926 860ZM833.7 665.6L536.7 368.4C535.196 366.911 533.166 366.076 531.05 366.076C528.934 366.076 526.904 366.911 525.4 368.4L410.9 482.9L238.4 310.3C236.896 308.811 234.866 307.976 232.75 307.976C230.634 307.976 228.604 308.811 227.1 310.3L190.3 347.1C188.811 348.604 187.976 350.634 187.976 352.75C187.976 354.866 188.811 356.896 190.3 358.4L405.2 573.4C408.3 576.5 413.4 576.5 416.5 573.4L531 459L785.5 713.6C788.6 716.7 793.7 716.7 796.8 713.6L833.6 676.8C836.8 673.8 836.8 668.7 833.7 665.6Z' />
      </svg>
    </Icon>
  );
};

export type IconNzoProps = {
  magnitude?: string;
};

export { IconNzo };
/* eslint-enable max-len */

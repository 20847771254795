import { useCallback, useState } from 'react';
import { isEmpty } from 'lodash/fp';
import styled, { useTheme } from 'styled-components';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Inline,
  InlineItem,
  Stack,
  Icon,
  Input,
  Button,
  Content,
  Text,
} from '@kinesis/bungle';

import { noop } from 'lodash/fp';

type NewServiceAccountRequest = {
  name: string;
};

const IconPadding = styled.div({
  paddingTop: 2,
});

const NewServiceAccount = (props: NewServiceAccountProps) => {
  const {
    onDismiss = noop,
    onNewServiceAccount = noop,
    animation = true,
  } = props;
  const theme = useTheme() as any;
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState(false);
  const validateName = useCallback(() => {
    const valid = !isEmpty(name);
    setNameError(!valid);
    return valid;
  }, [name, setNameError]);

  const newServiceAccount = useCallback(() => {
    const valid = validateName();
    if (valid) {
      onNewServiceAccount({ name });
      onDismiss();
    }
  }, [onNewServiceAccount, onDismiss, name, validateName]);

  return (
    <Modal
      aria-label='New service account'
      width={496}
      animation={animation}
      onClose={onDismiss}
    >
      <ModalHeader>New service account</ModalHeader>
      <ModalBody>
        <Stack space='medium' dividers>
          <Content padding='none' paddingBottom='medium'>
            <Inline alignY='top' space='small'>
              <InlineItem>
                <IconPadding>
                  <Icon
                    color={theme.color.yellow6}
                    magnitude='small'
                    type='exclamation-circle'
                  />
                </IconPadding>
              </InlineItem>
              <InlineItem sizing='fill-container'>
                <Text>
                  Please choose a descriptive name as you won’t be able to
                  change it later.
                </Text>
              </InlineItem>
            </Inline>
          </Content>
          <Input
            label='Service account name'
            value={name}
            onChange={setName}
            magnitude='large'
            tone={nameError ? 'critical' : 'neutral'}
            type='text'
            onBlur={validateName}
            placeholder='Enter service account name'
            message='What will you use this account for?'
          />
        </Stack>
      </ModalBody>
      <ModalFooter>
        <Inline space='small'>
          <InlineItem sizing='fill-container' />
          <InlineItem>
            <Button variant='secondary' onClick={onDismiss}>
              Cancel
            </Button>
          </InlineItem>
          <InlineItem>
            <Button variant='primary' onClick={newServiceAccount}>
              Create account
            </Button>
          </InlineItem>
        </Inline>
      </ModalFooter>
    </Modal>
  );
};

export type NewServiceAccountProps = {
  onNewServiceAccount?: (account: NewServiceAccountRequest) => void;
  onDismiss: () => void;
  animation?: boolean;
};

export { NewServiceAccount };

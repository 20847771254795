import {
  Icon,
  Anchor,
  Stack,
  Text,
  Strong,
  Content,
  Inline,
  InlineItem,
} from '@kinesis/bungle';
import { Layout } from '@/components/layout';

const Sso = (props: SsoProps) => {
  const { ssoEnabled = false, mandatesSso = false, organisationName } = props;
  if (!ssoEnabled) {
    return (
      <Layout variant='narrow'>
        <Stack space='medium'>
          <Text>
            Single sign-on allows members of your organisation to use your own
            authentication provider to log in to Kinesis.{' '}
            <Anchor
              href='https://help.kinesis.org/en/articles/8649105-kinesis-sso-configuration'
              target='_blank'
            >
              Learn more.
            </Anchor>
          </Text>

          <Content
            background='gray'
            paddingMode='equal'
            padding='small'
            borderRadiusSize='small'
          >
            <Inline space='small'>
              <InlineItem>
                <Icon type='sso' magnitude='xlarge' />
              </InlineItem>

              <InlineItem sizing='fill-container'>
                <Text>
                  <Strong>Not configured</Strong>
                </Text>
              </InlineItem>
            </Inline>
          </Content>

          <Text>
            <Anchor href='mailto:support@kinesis.org'>Contact us</Anchor> to
            configure single sign-on for your organisation.
          </Text>
        </Stack>
      </Layout>
    );
  }
  return (
    <Layout variant='narrow'>
      <Stack space='medium'>
        <Text>
          Single sign-on allows members of your organisation to use your own
          authentication provider to log in to Kinesis.{' '}
          <Anchor
            href='https://help.kinesis.org/en/articles/8649105-kinesis-sso-configuration'
            target='_blank'
          >
            Learn more.
          </Anchor>
        </Text>

        <Content
          background='gray'
          paddingMode='equal'
          padding='small'
          borderRadiusSize='small'
        >
          <Inline space='small'>
            <InlineItem>
              <Icon type='sso' magnitude='xlarge' />
            </InlineItem>
            <InlineItem sizing='fill-container'>
              <Stack space='none'>
                <Text>
                  <Strong>Enabled</Strong>
                </Text>
                {mandatesSso ? (
                  <Text tone='secondary'>
                    Members must log in using their {organisationName} account
                  </Text>
                ) : (
                  <Text tone='secondary'>
                    Members can log in using single sign-on or their Kinesis
                    account
                  </Text>
                )}
              </Stack>
            </InlineItem>
          </Inline>
        </Content>

        <Text>
          <Anchor href='mailto:support@kinesis.org?Subject=Manage single sign-on with Kinesis'>
            Contact us
          </Anchor>{' '}
          to change the single sign-on settings for your organisation.
        </Text>
      </Stack>
    </Layout>
  );
};

export type SsoProps = {
  ssoEnabled?: boolean;
  mandatesSso?: boolean;
  organisationName: string;
};

export { Sso };

import styled from 'styled-components';

const Dropzone = styled('div')(({ theme }) => ({
  display: 'none',
  position: 'fixed',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  height: '100%',
  width: '100%',
  background: theme.color.gray7,
  zIndex: 1000,
  color: theme.color.white8,
  lineHeight: '100vh',
  textAlign: 'center',
}));

const Hidden = styled('div')({
  display: 'none',
});

export { Dropzone, Hidden };

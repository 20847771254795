import { Content, Anchor, H3, Stack, Text } from '@kinesis/bungle';
import { MapboxLogo } from './acknowledgements.styles';
import { Layout } from '@/components/layout';

const MapboxCredit = () => (
  <Stack>
    <H3>Map data by Mapbox</H3>
    <MapboxLogo />
    <span>
      <Anchor target='_blank' href='https://www.mapbox.com/about/maps/'>
        © Mapbox
      </Anchor>
      <Text>, </Text>{' '}
      <Anchor target='_blank' href='http://www.openstreetmap.org/copyright'>
        © OpenStreetMap
      </Anchor>
    </span>
  </Stack>
);

const EmploymentProjectionsCredit = () => (
  <Stack>
    <H3>TZP19 Employment Projections</H3>
    <span>
      <Text>Dataset by</Text>{' '}
      <Anchor
        target='_blank'
        href='https://opendata.transport.nsw.gov.au/dataset/employment-projections'
      >
        Transport for NSW
      </Anchor>
      <Text>, licensed under</Text>{' '}
      <Anchor
        target='_blank'
        href='https://creativecommons.org/licenses/by/4.0/'
      >
        CC BY 4.0
      </Anchor>
      <Text>.</Text>
    </span>
    <Text>Disaggregated to suburb and LGA.</Text>
  </Stack>
);

const PopulationAndDwellingProjectionsCredit = () => (
  <Stack>
    <H3>TZP19 Population & Dwellings Projections</H3>
    <span>
      <Text>Dataset by</Text>{' '}
      <Anchor
        target='_blank'
        href='https://opendata.transport.nsw.gov.au/dataset/population-projections'
      >
        Transport for NSW
      </Anchor>
      <Text>, licensed under</Text>{' '}
      <Anchor
        target='_blank'
        href='https://creativecommons.org/licenses/by/4.0/'
      >
        CC BY 4.0
      </Anchor>
      <Text>.</Text>
    </span>
    <Text>Disaggregated to suburb and LGA.</Text>
  </Stack>
);

const Acknowledgements = () => (
  <Layout variant='narrow'>
    <Content padding='medium'>
      <Stack space='medium'>
        <Text>
          The following data, software, and services are used by the Kinesis
          platform.
        </Text>
        <MapboxCredit />
        <EmploymentProjectionsCredit />
        <PopulationAndDwellingProjectionsCredit />
      </Stack>
    </Content>
  </Layout>
);

export { Acknowledgements };

import { Toaster, Container, Frame, Content } from '@kinesis/bungle';
import { Outlet } from 'react-router-dom';

const Unauthenticated = () => {
  return (
    <Content
      sizing='fill-container'
      height='100%'
      padding='none'
      background='gray'
    >
      <Container>
        <Frame>
          <Content
            sizing='fill-container'
            height='100%'
            overflow='scroll'
            padding='medium'
            paddingMode='equal'
          >
            <Toaster id='globalTop' placement='top' />
            <Outlet />
          </Content>
        </Frame>
      </Container>
    </Content>
  );
};

export { Unauthenticated };

import {
  Text,
  Stack,
  Inline,
  InlineItem,
  UtilityButton,
  Content,
} from '@kinesis/bungle';
import { noop } from 'lodash/fp';
import { AccessKeyItem } from './access-key-item';
import { AccessKeyConfirmation } from './access-key-confirmation';
import { AccessKey, AccessKeySecret } from '@/types';
import { Layout } from '@/components/layout';

const AccessKeys = (props: AccessKeysProps) => {
  const {
    keys = [],
    now,
    onNewAccessKey = noop,
    onDeleteAccessKey = noop,
    isCreating = false,
    newAccessKey,
    onDismissNewAccessKey = noop,
  } = props;
  return (
    <Layout variant='narrow'>
      <Content>
        {newAccessKey && (
          <AccessKeyConfirmation
            onDismissNewKey={onDismissNewAccessKey}
            accessKey={newAccessKey}
          />
        )}
        <Stack space='medium'>
          <Stack space='small'>
            <Text>
              Access keys allow you to access Kinesis using our API. Your access
              keys are associated with your account and inherit your
              permissions.
            </Text>
            <Stack space='small'>
              {keys.map((key) => (
                <AccessKeyItem
                  key={key.id}
                  id={key.id}
                  created={key.created}
                  now={now}
                  onDelete={onDeleteAccessKey}
                />
              ))}
            </Stack>
          </Stack>
          <Inline>
            <InlineItem>
              <UtilityButton
                icon='plus'
                onClick={onNewAccessKey}
                disabled={isCreating}
                magnitude='small'
              >
                Generate access key
              </UtilityButton>
            </InlineItem>
          </Inline>
        </Stack>
      </Content>
    </Layout>
  );
};

type AccessKeysProps = {
  keys?: AccessKey[];
  now: string;
  isCreating?: boolean;
  newAccessKey?: AccessKeySecret;
  onNewAccessKey?: () => void;
  onDeleteAccessKey?: (id: string) => void;
  onDismissNewAccessKey?: () => void;
};

export { AccessKeys };

import { useCallback, useState } from 'react';
import {
  Content,
  Stack,
  Button,
  H2,
  Text,
  Radio,
  RadioGroup,
  Checkbox,
} from '@kinesis/bungle';
import { noop, every, identity } from 'lodash/fp';
import { KinesisLogo } from '@/components/kinesis-logo';
import { Center } from '@/components/center';
import { AnchorText } from '@/components/anchor-text';

const RegistrationTerms = (props: RegistrationTermsProps) => {
  const {
    onAcceptTerms = noop,
    isAccepting = false,
    requiresTerms = false,
    requiresAnalytics = false,
  } = props;

  const [analytics, setAnalytics] = useState<'full' | 'limited'>('limited');

  const [acceptTerms, setAcceptTerms] = useState(!requiresTerms);
  const [acceptTermsError, setAcceptTermsError] = useState(false);
  const toggleAcceptTerms = useCallback(
    () => setAcceptTerms((current) => !current),
    [setAcceptTerms],
  );
  const validateAcceptTerms = useCallback(() => {
    const valid = acceptTerms || !requiresTerms;
    setAcceptTermsError(!valid);
    return valid;
  }, [acceptTerms, requiresTerms, setAcceptTermsError]);

  const onSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      const valid = every(identity, [validateAcceptTerms()]);
      if (valid) {
        onAcceptTerms({
          acceptTerms,
          analytics,
        });
      }
    },
    [validateAcceptTerms, analytics, acceptTerms, onAcceptTerms],
  );

  return (
    <Content background='white' borderRadiusSize='large' width={304}>
      <Stack space='none'>
        <Content padding='large' sizing='fill-container' paddingMode='equal'>
          <Stack space='medium'>
            <Center>
              <KinesisLogo />
            </Center>
            <Center>
              <H2>Welcome to Kinesis</H2>
            </Center>
          </Stack>
        </Content>
        <Stack space='medium'>
          <Content
            background='gray'
            borderTop
            borderBottom
            sizing='fill-container'
            padding='medium'
            paddingMode='equal'
          >
            {requiresAnalytics && (
              <Text>
                Help us improve Kinesis by allowing full analytics of your usage
                data. We will never share personal information outside of
                Kinesis.
              </Text>
            )}
            {!requiresAnalytics && (
              <Text>
                Kinesis needs you to review and accept our terms and privacy
                policy before you can get started.
              </Text>
            )}
          </Content>

          <Content
            background='gray'
            borderTop
            sizing='fill-container'
            padding='medium'
            paddingMode='equal'
            borderRadiusSize='large'
            borderRadiusPlacement='bottom'
          >
            <form onSubmit={onSubmit} noValidate>
              <Stack space='large'>
                <Stack space='medium'>
                  {requiresAnalytics && (
                    <RadioGroup
                      autoFocus
                      hideLegend
                      legend='Choose Analytics setting'
                      onChange={setAnalytics}
                      value={analytics}
                    >
                      <Stack space='xsmall'>
                        <Radio title='Limited analytics' value='limited'>
                          Collects high-level events such as logging in,
                          accessing a workspace, and viewing a visualisation.
                          Your data and inputs are not collected.
                        </Radio>
                        <Radio title='Full analytics' value='full'>
                          Collects high-level events and enables our product
                          team to see the inputs you provide and how you use
                          Kinesis. This allows us to identify usability issues
                          with our user interface and diagnose any bugs that
                          arise.
                        </Radio>
                      </Stack>
                    </RadioGroup>
                  )}
                  {requiresTerms && (
                    <Checkbox
                      checked={acceptTerms}
                      onChange={toggleAcceptTerms}
                      onBlur={validateAcceptTerms}
                      tone={acceptTermsError ? 'critical' : undefined}
                    >
                      I agree to the Kinesis{' '}
                      <AnchorText
                        href='https://kinesis.org/privacy/'
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        privacy policy
                      </AnchorText>{' '}
                      and{' '}
                      <AnchorText
                        href='https://kinesis.org/terms/'
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        terms of use
                      </AnchorText>
                    </Checkbox>
                  )}
                </Stack>
                <Button type='submit' display='block' loading={isAccepting}>
                  Start using Kinesis
                </Button>
              </Stack>
            </form>
          </Content>
        </Stack>
      </Stack>
    </Content>
  );
};

export type AcceptTerms = {
  acceptTerms?: boolean;
  analytics?: 'full' | 'limited';
};

export type RegistrationTermsProps = {
  onAcceptTerms?: (v: AcceptTerms) => void;
  isAccepting?: boolean;
  requiresTerms?: boolean;
  requiresAnalytics?: boolean;
};

export { RegistrationTerms };

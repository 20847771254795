import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { isNil } from 'lodash/fp';
import { useAuthenticateMutation, useIntrospectMutation } from '@/api/login';
import { useToast } from '@kinesis/bungle';
import { LoginMethod } from '@/types';
import { Center } from '@/components/center';
import { LoginForm } from '@/components/login-form';
import { setLastLogin, getLastLogin } from '@/services/last-login';
import { login } from '@/services/login';

type AuthenticationRequest = {
  method: LoginMethod;
  email: string;
  password?: string;
};

const emptyLoginMethod = {};

const Login = () => {
  const defaultEmail = getLastLogin();
  const navigate = useNavigate();
  const [isRedirecting, setIsRedirecting] = useState(false);
  const [loginMethod, setLoginMethod] = useState<LoginMethod>(emptyLoginMethod);
  const toast = useToast('globalTop');

  const [authenticate, { isLoading, isSuccess }] = useAuthenticateMutation();
  const [introspect, { isLoading: isIntrospecting }] = useIntrospectMutation();

  const onAuthenticate = useCallback(
    async (request: AuthenticationRequest) => {
      if (isNil(request.method.method)) {
        try {
          setIsRedirecting(true);
          const introspection = await introspect({
            email: request.email,
          }).unwrap();
          if (introspection.method === 'sso') {
            window.location.assign(introspection.redirectUrl as string);
          } else {
            setIsRedirecting(false);
            setLoginMethod(introspection);
          }
        } catch (e) {
          setIsRedirecting(false);
          setLoginMethod({});
          if (request.email === defaultEmail) {
            setLastLogin(null);
          }
        }
      } else if (request.method.method === 'sso') {
        setIsRedirecting(true);
        window.location.assign(request.method.redirectUrl as string);
      } else if (request.method.method === 'db') {
        try {
          const data = await authenticate({
            email: request.email,
            password: request.password as string,
          }).unwrap();
          login(data, false, navigate);
        } catch (e) {
          toast('We couldn’t log you in. Please try again.', {
            variant: 'error',
            duration: 6000,
          });
        }
      }
    },
    [authenticate, toast, navigate, defaultEmail, introspect],
  );

  const onIntrospect = useCallback(
    async (email: string) => {
      try {
        const data = await introspect({ email }).unwrap();
        setLoginMethod(data);
      } catch (e) {
        setLoginMethod({});
        if (email === defaultEmail) {
          setLastLogin(null);
        }
      }
    },
    [introspect, setLoginMethod, defaultEmail],
  );

  const onChangeEmail = useCallback(
    (email: string) => {
      setLastLogin(email);
      if (loginMethod.email !== email) {
        onIntrospect(email);
      }
    },
    [onIntrospect, loginMethod],
  );

  return (
    <Center>
      <LoginForm
        method={loginMethod}
        defaultEmail={defaultEmail}
        onChangeEmail={onChangeEmail}
        onAuthenticate={onAuthenticate}
        isAuthenticating={
          isLoading || isSuccess || isIntrospecting || isRedirecting
        }
      />
    </Center>
  );
};

export { Login };

import { Stack, LabelText, Text } from '@kinesis/bungle';
import { noop } from 'lodash/fp';
import { DefaultProduct } from '@/components/default-product';
import {
  DefaultGridProduct,
  DefaultProductOption,
  Product,
  ProductLaunchPreference,
} from '@/types';
import { DefaultGrid } from '@/components/default-grid';
import { Layout } from '@/components/layout';

const emptyList: any[] = [];
const OrganisationDefaults = (props: OrganisationDefaultsProps) => {
  const {
    defaultProduct,
    defaultProductOptions = emptyList,
    defaultGridProducts = emptyList,
    currentUserIsOrganisationEditor,
    onChangeDefaultProduct = noop,
    onChangeDefaultGridProduct = noop,
  } = props;
  return (
    <Layout variant='narrow'>
      <Stack space='medium'>
        <Text>
          Select the product you want to launch when members first log in to
          Kinesis. Members can override these settings with their personal
          product defaults.
        </Text>
        <DefaultProduct
          defaultProduct={defaultProduct}
          options={defaultProductOptions}
          onChangeProduct={onChangeDefaultProduct}
          currentUserCanEdit={currentUserIsOrganisationEditor}
        />
        {defaultGridProducts.length > 0 && (
          <Stack space='xsmall'>
            <LabelText>Default instances</LabelText>
            <DefaultGrid
              defaultGridProducts={defaultGridProducts}
              onChange={onChangeDefaultGridProduct}
              currentUserCanEdit={currentUserIsOrganisationEditor}
            />
          </Stack>
        )}
      </Stack>
    </Layout>
  );
};

export type OrganisationDefaultsProps = {
  currentUserIsOrganisationEditor: boolean;
  defaultProduct?: DefaultProductOption;
  defaultProductOptions?: DefaultProductOption[];
  defaultGridProducts?: DefaultGridProduct[]; // TODO work out shape of products
  onChangeDefaultProduct?: (option: DefaultProductOption) => void;
  onChangeDefaultGridProduct?: (
    product: Product,
    selection: ProductLaunchPreference,
  ) => void;
};

export { OrganisationDefaults };

import { Anchor, Inline, InlineItem, Text } from '@kinesis/bungle';
import { Logo } from '@/components/logo';
const PoweredByKinesis = () => {
  return (
    <Inline space='small'>
      <InlineItem>
        <Logo height={20} width={24} />
      </InlineItem>
      <InlineItem>
        <Text>
          Powered by{' '}
          <Anchor
            href='https://kinesis.org/'
            target='_blank'
            rel='noopener noreferrer'
          >
            Kinesis
          </Anchor>
        </Text>
      </InlineItem>
    </Inline>
  );
};

export { PoweredByKinesis };

import { Icon } from '@kinesis/bungle';

/* eslint-disable max-len */
const IconCity = (props: IconCityProps) => {
  const { magnitude = 'xlarge' } = props;
  return (
    <Icon type='custom' magnitude={magnitude} color='#E33444'>
      <svg
        width='1024'
        height='1024'
        viewBox='0 0 1024 1024'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path d='M230.4 312.32C230.4 284.043 207.477 261.12 179.2 261.12C150.923 261.12 128 284.043 128 312.32C128 340.597 150.923 363.52 179.2 363.52C207.477 363.52 230.4 340.597 230.4 312.32Z' />
        <path d='M363.52 312.32C363.52 284.043 340.597 261.12 312.32 261.12C284.043 261.12 261.12 284.043 261.12 312.32C261.12 340.597 284.043 363.52 312.32 363.52C340.597 363.52 363.52 340.597 363.52 312.32Z' />
        <path d='M363.52 179.2C363.52 150.923 340.597 128 312.32 128C284.043 128 261.12 150.923 261.12 179.2C261.12 207.477 284.043 230.4 312.32 230.4C340.597 230.4 363.52 207.477 363.52 179.2Z' />
        <path d='M230.4 445.44C230.4 417.163 207.477 394.24 179.2 394.24C150.923 394.24 128 417.163 128 445.44C128 473.717 150.923 496.64 179.2 496.64C207.477 496.64 230.4 473.717 230.4 445.44Z' />
        <path d='M363.52 445.44C363.52 417.163 340.597 394.24 312.32 394.24C284.043 394.24 261.12 417.163 261.12 445.44C261.12 473.717 284.043 496.64 312.32 496.64C340.597 496.64 363.52 473.717 363.52 445.44Z' />
        <path d='M629.76 445.44C629.76 417.163 606.837 394.24 578.56 394.24C550.283 394.24 527.36 417.163 527.36 445.44C527.36 473.717 550.283 496.64 578.56 496.64C606.837 496.64 629.76 473.717 629.76 445.44Z' />
        <path d='M230.4 578.56C230.4 550.283 207.477 527.36 179.2 527.36C150.923 527.36 128 550.283 128 578.56C128 606.837 150.923 629.76 179.2 629.76C207.477 629.76 230.4 606.837 230.4 578.56Z' />
        <path d='M363.52 578.56C363.52 550.283 340.597 527.36 312.32 527.36C284.043 527.36 261.12 550.283 261.12 578.56C261.12 606.837 284.043 629.76 312.32 629.76C340.597 629.76 363.52 606.837 363.52 578.56Z' />
        <path d='M496.64 578.56C496.64 550.283 473.717 527.36 445.44 527.36C417.163 527.36 394.24 550.283 394.24 578.56C394.24 606.837 417.163 629.76 445.44 629.76C473.717 629.76 496.64 606.837 496.64 578.56Z' />
        <path d='M629.76 578.56C629.76 550.283 606.837 527.36 578.56 527.36C550.283 527.36 527.36 550.283 527.36 578.56C527.36 606.837 550.283 629.76 578.56 629.76C606.837 629.76 629.76 606.837 629.76 578.56Z' />
        <path d='M762.88 445.44C762.88 417.163 739.957 394.24 711.68 394.24C683.403 394.24 660.48 417.163 660.48 445.44C660.48 473.717 683.403 496.64 711.68 496.64C739.957 496.64 762.88 473.717 762.88 445.44Z' />
        <path d='M762.88 578.56C762.88 550.283 739.957 527.36 711.68 527.36C683.403 527.36 660.48 550.283 660.48 578.56C660.48 606.837 683.403 629.76 711.68 629.76C739.957 629.76 762.88 606.837 762.88 578.56Z' />
        <path d='M230.4 711.68C230.4 683.403 207.477 660.48 179.2 660.48C150.923 660.48 128 683.403 128 711.68C128 739.957 150.923 762.88 179.2 762.88C207.477 762.88 230.4 739.957 230.4 711.68Z' />
        <path d='M363.52 711.68C363.52 683.403 340.597 660.48 312.32 660.48C284.043 660.48 261.12 683.403 261.12 711.68C261.12 739.957 284.043 762.88 312.32 762.88C340.597 762.88 363.52 739.957 363.52 711.68Z' />
        <path d='M496.64 711.68C496.64 683.403 473.717 660.48 445.44 660.48C417.163 660.48 394.24 683.403 394.24 711.68C394.24 739.957 417.163 762.88 445.44 762.88C473.717 762.88 496.64 739.957 496.64 711.68Z' />
        <path d='M629.76 711.68C629.76 683.403 606.837 660.48 578.56 660.48C550.283 660.48 527.36 683.403 527.36 711.68C527.36 739.957 550.283 762.88 578.56 762.88C606.837 762.88 629.76 739.957 629.76 711.68Z' />
        <path d='M762.88 711.68C762.88 683.403 739.957 660.48 711.68 660.48C683.403 660.48 660.48 683.403 660.48 711.68C660.48 739.957 683.403 762.88 711.68 762.88C739.957 762.88 762.88 739.957 762.88 711.68Z' />
        <path d='M896 445.44C896 417.163 873.077 394.24 844.8 394.24C816.523 394.24 793.6 417.163 793.6 445.44C793.6 473.717 816.523 496.64 844.8 496.64C873.077 496.64 896 473.717 896 445.44Z' />
        <path d='M896 578.56C896 550.283 873.077 527.36 844.8 527.36C816.523 527.36 793.6 550.283 793.6 578.56C793.6 606.837 816.523 629.76 844.8 629.76C873.077 629.76 896 606.837 896 578.56Z' />
        <path d='M896 711.68C896 683.403 873.077 660.48 844.8 660.48C816.523 660.48 793.6 683.403 793.6 711.68C793.6 739.957 816.523 762.88 844.8 762.88C873.077 762.88 896 739.957 896 711.68Z' />
        <path d='M230.4 844.8C230.4 816.523 207.477 793.6 179.2 793.6C150.923 793.6 128 816.523 128 844.8C128 873.077 150.923 896 179.2 896C207.477 896 230.4 873.077 230.4 844.8Z' />
        <path d='M363.52 844.8C363.52 816.523 340.597 793.6 312.32 793.6C284.043 793.6 261.12 816.523 261.12 844.8C261.12 873.077 284.043 896 312.32 896C340.597 896 363.52 873.077 363.52 844.8Z' />
        <path d='M496.64 844.8C496.64 816.523 473.717 793.6 445.44 793.6C417.163 793.6 394.24 816.523 394.24 844.8C394.24 873.077 417.163 896 445.44 896C473.717 896 496.64 873.077 496.64 844.8Z' />
        <path d='M629.76 844.8C629.76 816.523 606.837 793.6 578.56 793.6C550.283 793.6 527.36 816.523 527.36 844.8C527.36 873.077 550.283 896 578.56 896C606.837 896 629.76 873.077 629.76 844.8Z' />
        <path d='M762.88 844.8C762.88 816.523 739.957 793.6 711.68 793.6C683.403 793.6 660.48 816.523 660.48 844.8C660.48 873.077 683.403 896 711.68 896C739.957 896 762.88 873.077 762.88 844.8Z' />
        <path d='M896 844.8C896 816.523 873.077 793.6 844.8 793.6C816.523 793.6 793.6 816.523 793.6 844.8C793.6 873.077 816.523 896 844.8 896C873.077 896 896 873.077 896 844.8Z' />
      </svg>
    </Icon>
  );
};

export type IconCityProps = {
  magnitude?: string;
};

export { IconCity };
/* eslint-enable max-len */

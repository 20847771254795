import {
  Heading,
  Icon,
  Stack,
  CenteredPanel,
  Content,
  Text,
} from '@kinesis/bungle';
import { IconSpacer } from './terms-not-accepted-panel.styles';

const TermsNotAcceptedPanel = () => {
  return (
    <CenteredPanel centerY>
      <Stack space='small' alignX='center'>
        <IconSpacer>
          <Icon magnitude='xxlarge' type='info-circle' />
        </IconSpacer>
        <Heading as='h1' size='xlarge'>
          There are outstanding data terms of use
        </Heading>
        <Content textAlign='center' maxWidth={396}>
          <Text size='large'>
            You are still able to access the rest of Kinesis, but we can’t
            provide access to this licenced data without agreement to the terms
            of its use.
          </Text>
        </Content>
      </Stack>
    </CenteredPanel>
  );
};

export { TermsNotAcceptedPanel };

import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { DefaultGridProduct, LaunchOptions, LaunchSelection } from '@/types';
import {
  useProductLaunchOptionsQuery,
  useProductLaunchSelectionsQuery,
  productLaunchSelectionsSelector,
} from '@/api/user';
import {
  useOrganisationProductLaunchOptionsQuery,
  useOrganisationProductLaunchSelectionsQuery,
  organisationProductLaunchSelectionsSelector,
} from '@/api/organisation';
import { productFromNamespace, productName } from '@/data/products';

const getDefaultGridProducts = (
  launchOptions: LaunchOptions[],
  launchSelections: LaunchSelection[],
): DefaultGridProduct[] => {
  return launchOptions.flatMap((productOptions) => {
    const product = productFromNamespace(productOptions.namespace);
    if (!product) {
      return [];
    } else {
      const launchSelection = launchSelections.find(
        (l) => l.namespace == productOptions.namespace,
      );
      return [
        {
          product: product,
          ...(launchSelection && { selection: launchSelection.preference }),
          options: productOptions.options.map((option) => {
            switch (option.preference.type) {
              case 'last-visited':
                return { label: 'Last visited', value: option.preference };
              case 'default-for-organisation':
                return {
                  label: 'Default for organisation',
                  value: option.preference,
                };
              default:
                const name =
                  option.name ??
                  `${productName(product)}/${option.preference.id}`;
                return {
                  label: name,
                  value: option.preference,
                };
            }
          }),
        },
      ];
    }
  });
};

const useUserDefaultGridProducts = (): {
  data: DefaultGridProduct[];
  isLoading: boolean;
} => {
  const { data: launchOptions, isLoading: launchOptionsLoading } =
    useProductLaunchOptionsQuery();

  const { isLoading: launchSelectionsLoading } =
    useProductLaunchSelectionsQuery();

  const launchSelections = useSelector(productLaunchSelectionsSelector);

  const defaultGridProducts: DefaultGridProduct[] = useMemo(
    () => getDefaultGridProducts(launchOptions ?? [], launchSelections),
    [launchOptions, launchSelections],
  );

  return {
    data: defaultGridProducts,
    isLoading: launchOptionsLoading || launchSelectionsLoading,
  };
};

const useOrganisationDefaultGridProducts = (
  organisationId: number,
): {
  data: DefaultGridProduct[];
  isLoading: boolean;
} => {
  const { data: launchOptions, isLoading: launchOptionsLoading } =
    useOrganisationProductLaunchOptionsQuery(organisationId);

  const { isLoading: launchSelectionsLoading } =
    useOrganisationProductLaunchSelectionsQuery(organisationId);

  const launchSelections = useSelector(
    organisationProductLaunchSelectionsSelector(organisationId),
  );

  const defaultGridProducts: DefaultGridProduct[] = useMemo(
    () => getDefaultGridProducts(launchOptions ?? [], launchSelections),
    [launchOptions, launchSelections],
  );

  return {
    data: defaultGridProducts,
    isLoading: launchOptionsLoading || launchSelectionsLoading,
  };
};

export {
  useUserDefaultGridProducts,
  useOrganisationDefaultGridProducts,
  getDefaultGridProducts,
};

import styled from 'styled-components';

const CardWrapper = styled('div')`
  display: grid;
  grid-template-columns: repeat(auto-fill, 200px);
  column-gap: 16px;
  row-gap: 16px;
`;

const LicenseCard = styled.div<LicenseCardProps>`
  padding: 16px;
  border: 1px solid #e9e9e9;
  opacity: ${(props: any) => (props.isLicenced ? '1' : '0.6')};
`;

export type LicenseCardProps = {
  isLicenced: boolean;
};

export { CardWrapper, LicenseCard };

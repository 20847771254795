import { useMemo } from 'react';
import { useMeQuery } from '@/api/user';
import { InvitationData } from '@/types';

const useMeInvitations = (): {
  data: InvitationData[];
  isLoading: boolean;
} => {
  const { data: me, isLoading } = useMeQuery();

  const invitations: InvitationData[] = useMemo(() => {
    if (!isLoading && me) {
      return me.memberships
        .filter(
          ({ status, invitationExpired }) =>
            status === 'pending' && !invitationExpired,
        )
        .map((membership) => {
          return {
            token: membership.invitationToken ?? '',
            organisationName: membership.organisationName,
            avatar: membership.organisationAvatar,
            invitedBy: membership.inviterName ?? '',
            expiresAt: membership.invitationExpiresAt ?? '',
            canBeAccepted: membership.invitationCanBeAccepted,
          };
        });
    } else {
      return [];
    }
  }, [me, isLoading]);

  const result = useMemo(
    () => ({
      data: invitations,
      isLoading: isLoading,
    }),
    [invitations, isLoading],
  );

  return result;
};

export { useMeInvitations };

import { useEffect, useCallback, useState, useRef, FocusEvent } from 'react';
import {
  Inline,
  InlineItem,
  Avatar,
  Content,
  Stack,
  Button,
  Input,
  H2,
} from '@kinesis/bungle';
import { LoginMethod } from '@/types';
import { noop, isEmpty } from 'lodash/fp';
import { KinesisLogo } from '@/components/kinesis-logo';
import { Center } from '@/components/center';
import { LinkText } from '@/components/link-text';
import { VanityAvatar } from './login-form.styles';

const determineMode = (v: LoginMethod) => {
  if (v.method === 'sso') {
    return 'sso';
  } else if (v.method === 'db') {
    return 'password';
  } else {
    return 'email';
  }
};

const emptyLoginMethod = {};
const LoginForm = (props: LoginFormProps) => {
  const {
    onAuthenticate = noop,
    onChangeEmail = noop,
    isAuthenticating = false,
    method = emptyLoginMethod,
    defaultEmail = '',
    vanity = false,
    avatar,
    name,
  } = props;
  const useVanity = vanity && !isEmpty(avatar);
  const mode = determineMode(method);
  const emailRef = useRef<HTMLInputElement>();
  const [email, setEmail] = useState(defaultEmail);
  const [password, setPassword] = useState('');
  const onBlurEmail = useCallback(
    (e: FocusEvent<HTMLInputElement>) => {
      if (e.relatedTarget?.tagName !== 'A') {
        onChangeEmail(email);
      }
    },
    [email, onChangeEmail],
  );
  const onAuthenticateSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      onAuthenticate({
        email,
        password,
        method,
      });
    },
    [onAuthenticate, email, password, method],
  );
  useEffect(() => {
    emailRef.current?.select();
  }, [emailRef]);
  return (
    <Content background='white' borderRadiusSize='large' width={304}>
      <Stack space='none'>
        <Content padding='large' sizing='fill-container' paddingMode='equal'>
          <Stack space='medium'>
            <Center>
              {!useVanity && <KinesisLogo />}
              {useVanity && (
                <VanityAvatar>
                  <Avatar
                    alt={name}
                    image={avatar}
                    magnitude='xlarge'
                    variant='organisation'
                  />
                </VanityAvatar>
              )}
            </Center>
            <Center>
              {!useVanity && <H2>Log in to Kinesis</H2>}

              {useVanity && (
                <Inline space='xsmall'>
                  <InlineItem>
                    <H2>Log in to</H2>
                  </InlineItem>
                  <InlineItem>
                    <KinesisLogo width='16px' height='12px' />
                  </InlineItem>
                  <InlineItem>
                    <H2>Kinesis</H2>
                  </InlineItem>
                </Inline>
              )}
            </Center>
          </Stack>
        </Content>
        <Content
          background='gray'
          borderTop
          sizing='fill-container'
          padding='medium'
          paddingMode='equal'
          borderRadiusSize='large'
          borderRadiusPlacement='bottom'
        >
          <form onSubmit={onAuthenticateSubmit} noValidate>
            <Stack space='large'>
              <Stack space='medium'>
                <Input
                  ref={emailRef}
                  label='Email address'
                  magnitude='large'
                  placeholder='Enter email address'
                  value={email}
                  onChange={setEmail}
                  onBlur={onBlurEmail}
                  type='email'
                  autoFocus
                />

                {mode === 'password' && (
                  <Input
                    label='Password'
                    magnitude='large'
                    placeholder='Enter password'
                    value={password}
                    onChange={setPassword}
                    type='password'
                    autoFocus
                  />
                )}
              </Stack>
              <Stack space='medium'>
                <Button
                  type='submit'
                  display='block'
                  loading={isAuthenticating}
                >
                  Continue
                </Button>
                <Stack space='small'>
                  <Center>
                    <LinkText to='/password-reset' state={{ email }}>
                      Forgot your password?
                    </LinkText>
                  </Center>
                  <Center>
                    <LinkText to='/registration'>Create an account</LinkText>
                  </Center>
                </Stack>
              </Stack>
            </Stack>
          </form>
        </Content>
      </Stack>
    </Content>
  );
};

export type LoginFormMode = 'email' | 'password' | 'sso';

export type LoginFormProps = {
  defaultEmail?: string;
  onAuthenticate?: (v: {
    method: LoginMethod;
    email: string;
    password?: string;
  }) => void;
  onChangeEmail?: (email: string) => void;
  isAuthenticating?: boolean;
  method?: LoginMethod;
  vanity?: boolean;
  avatar?: string;
  name?: string;
};

export { LoginForm };

import { noop } from 'lodash/fp';
import { ConfirmationDialog } from '@kinesis/bungle';

const OrganisationProfileDeleteConfirmation = (
  props: OrganisationProfileDeleteConfirmationProps,
) => {
  const { animation = true, name, onClose = noop } = props;
  return (
    <ConfirmationDialog
      animation={animation}
      confirmText='OK'
      icon='info'
      title={`Contact us to delete “${name}”.`}
      onCancel={onClose}
      onConfirm={onClose}
      showCancelButton={false}
      variant='primary'
      minWidth={320}
    >
      Organisations cannot be deleted via the app.{' '}
      <a
        href='mailto:support@kinesis.org'
        target='_blank'
        rel='noopener noreferrer'
      >
        Send us a request
      </a>{' '}
      that includes any data retention or erasure requirements you have and we
      will delete it on your behalf.
    </ConfirmationDialog>
  );
};

export type OrganisationProfileDeleteConfirmationProps = {
  name: String;
  animation?: boolean;
  onClose?: () => void;
};

export { OrganisationProfileDeleteConfirmation };

import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { isEmpty } from 'lodash/fp';
import { SwitcherModal } from '@kinesis/spotlight-ui';
import { AppLayout } from '@/components/authenticated-app-layout';
import { useSessionQuery, useLogoutMutation } from '@/api/login';
import { toggleFlag, flagsSelector, useGetFlagsQuery } from '@/api/flags';
import { useFlag } from '@/hooks/use-flag';
import {
  useRecentEntitiesQuery,
  useSearchEntitiesQuery,
  useEntityTypesQuery,
  getEntityUrl,
  getEntityTypeUrl,
} from '@/api/entities';

const emptyList: any = [];

const StyledAuthenticated = () => {
  const { data: session, isSuccess, isLoading } = useSessionQuery();
  const { isSuccess: flagsFetched } = useGetFlagsQuery();
  const [logout] = useLogoutMutation();
  const flags = useSelector(flagsSelector);
  const enableFlags = useFlag('app.flag-management');

  const dispatch = useDispatch();

  const onToggleFlag = useCallback(
    (name: string) => {
      dispatch(toggleFlag(name) as any);
    },
    [dispatch],
  );

  const [entitySearch, setEntitySearch] = useState('');
  const { data: searchResponse } = useSearchEntitiesQuery(entitySearch, {
    skip: isEmpty(entitySearch),
  });
  const { data: entityTypesResponse } = useEntityTypesQuery(undefined, {
    skip: !flagsFetched,
  });
  const { data: recentEntitiesResponse } = useRecentEntitiesQuery(undefined, {
    skip: !flagsFetched,
  });

  const [switcherModal, setSwitcherModal] = useState(false);
  const onOpenSwitcherModal = useCallback(() => {
    setSwitcherModal(true);
  }, [setSwitcherModal]);

  const onCloseSwitcherModal = useCallback(() => {
    setSwitcherModal(false);
    setEntitySearch('');
  }, [setEntitySearch, setSwitcherModal]);

  const searchEntities = isEmpty(entitySearch)
    ? emptyList
    : searchResponse?.results;
  const recentEntities = recentEntitiesResponse || emptyList;
  const entityTypes = entityTypesResponse || emptyList;

  if (!isLoading && isSuccess) {
    return (
      <AppLayout
        flags={flags}
        session={session}
        enableFlags={enableFlags}
        onToggleFlag={onToggleFlag}
        onLogout={logout}
        onOpenSwitcher={onOpenSwitcherModal}
      >
        <>
          <Outlet />
          {switcherModal && (
            <SwitcherModal
              width={496}
              height={600}
              minHeight={320}
              onClose={onCloseSwitcherModal}
              onSearch={setEntitySearch}
              entityTypeList={entityTypes}
              searchResultList={searchEntities}
              recentList={recentEntities}
              getEntityUrl={getEntityUrl}
              getEntityTypeUrl={getEntityTypeUrl}
            />
          )}
        </>
      </AppLayout>
    );
  }

  return null;
};

export { StyledAuthenticated };

import { useCallback } from 'react';
import styled, { useTheme } from 'styled-components';

import {
  useToast,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Inline,
  InlineItem,
  Stack,
  Icon,
  Input,
  Button,
  Content,
} from '@kinesis/bungle';

import { noop } from 'lodash/fp';
import { AccessKeySecret } from '@/types';

const IconPadding = styled.div({
  paddingTop: 2,
});

const AccessKeyConfirmation = (props: AccessKeyConfirmationProps) => {
  const { onDismissNewKey = noop, accessKey, animation = true } = props;
  const toast = useToast('globalTop');
  const theme = useTheme() as any;

  const onCopy = useCallback(() => {
    navigator.clipboard.writeText(accessKey.private);
    toast('Key copied to clipboard.', {
      variant: 'success',
      duration: 3000,
    });
  }, [accessKey, toast]);

  const onDownload = useCallback(() => {
    const a = document.createElement('a');
    const blob = new Blob([accessKey.private], { type: 'application/json' });
    const url = URL.createObjectURL(blob);
    a.setAttribute('href', url);
    a.setAttribute('download', 'credentials.json');
    a.click();
  }, [accessKey]);

  return (
    <Modal
      aria-label='New key generated'
      width={496}
      animation={animation}
      onClose={onDismissNewKey}
    >
      <ModalHeader>New key generated</ModalHeader>
      <ModalBody>
        <Stack space='medium' dividers>
          <Content padding='none' paddingBottom='medium'>
            <Inline alignY='top' space='small'>
              <InlineItem>
                <IconPadding>
                  <Icon
                    color={theme.color.yellow6}
                    magnitude='small'
                    type='exclamation-circle'
                  />
                </IconPadding>
              </InlineItem>
              <InlineItem sizing='fill-container'>
                Please save this key now as you won’t be able to see it again.
                For more information about how to use this key, view our{' '}
                <a
                  target='_blank'
                  href='https://doc.api.kinesis.org'
                  rel='noreferrer'
                >
                  API documentation
                </a>
                .
              </InlineItem>
            </Inline>{' '}
          </Content>

          <Inline space='small' alignY='bottom'>
            <InlineItem sizing='fill-container'>
              <Input
                disabled
                label='Access key'
                value={accessKey.private}
                magnitude='large'
              />
            </InlineItem>
            <InlineItem>
              <Button icon='copy' variant='secondary' onClick={onCopy}>
                Copy
              </Button>
            </InlineItem>
            <InlineItem>
              <Button icon='download' variant='secondary' onClick={onDownload}>
                Download
              </Button>
            </InlineItem>
          </Inline>
        </Stack>
      </ModalBody>
      <ModalFooter>
        <Inline>
          <InlineItem sizing='fill-container' />
          <InlineItem>
            <Button onClick={onDismissNewKey}>Done</Button>
          </InlineItem>
        </Inline>
      </ModalFooter>
    </Modal>
  );
};

export type AccessKeyConfirmationProps = {
  onDismissNewKey?: () => void;
  accessKey: AccessKeySecret;
  animation?: boolean;
};

export { AccessKeyConfirmation };

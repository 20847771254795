import { useCallback, useEffect, useRef, useState } from 'react';
import { useTheme } from 'styled-components';
import { noop } from 'lodash/fp';
import {
  Anchor,
  Content,
  Icon,
  Inline,
  InlineItem,
  Pill,
  Portal,
  Secondary,
  Stack,
  Strong,
  useEventListener,
} from '@kinesis/bungle';
import { Dropzone, Hidden } from './file-uploader.styles';
import { ValidFileExtension } from '@/types';

const FileUploader = (props: Props) => {
  const { onChange = noop, validFileExtensions = [], disabled = false } = props;

  const theme = useTheme() as any;
  const fileInputRef = useRef<HTMLInputElement>() as any;
  const dropzoneRef = useRef<HTMLDivElement>() as any;

  const [showDropzone, setShowDropzone] = useState(false);
  const [fileCount, setFileCount] = useState(0);

  const handleChooseFileClick = useCallback(() => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  }, [fileInputRef]);

  const handleInputChange = useCallback(
    (e: React.ChangeEvent) => {
      onChange((e.target as HTMLInputElement).files as FileList);
    },
    [onChange],
  );

  useEffect(() => {
    const timerId = setTimeout(() => {
      if (!showDropzone && dropzoneRef.current)
        dropzoneRef.current.style.display = 'none';
    }, 200);

    return () => {
      clearTimeout(timerId);
    };
  }, [showDropzone]);

  useEventListener('dragenter', (e: DragEvent) => {
    if (disabled) return;
    setShowDropzone(true);
    setFileCount(e.dataTransfer?.items.length || 0);
    dropzoneRef.current.style.display = 'block';
  });

  useEventListener('dragover', (e: DragEvent) => {
    if (disabled) return;
    setShowDropzone(true);
    e.preventDefault();
  });

  useEventListener('dragleave', () => {
    setShowDropzone(false);
  });

  useEventListener(
    'drop',
    useCallback(
      (e: DragEvent) => {
        e.preventDefault();
        setShowDropzone(false);
        setFileCount(0);
        dropzoneRef.current.style.display = 'none';
        fileInputRef.current.files = e.dataTransfer?.files;
        onChange(e.dataTransfer?.files as FileList);
      },
      [onChange],
    ),
  );

  return (
    <>
      <Content
        background='gray'
        border
        borderRadiusSize='small'
        padding='medium'
      >
        <Stack alignX='center' space='medium'>
          <Icon color={theme.color.gray6} magnitude='xxlarge' type='upload' />
          <Stack alignX='center' space='none'>
            <Strong>Drag and drop</Strong>
            <Secondary>
              or&nbsp;
              {/* When the below bungle issue is fixed, replace 'browse' inline styling with disabled prop */}
              {/* https://github.com/kinesisptyltd/bungle/issues/5089 */}
              {disabled ? (
                <span
                  style={{
                    fontWeight: 500,
                    color: theme.color.gray6,
                  }}
                >
                  browse
                </span>
              ) : (
                <Anchor
                  as='button'
                  type='button'
                  onClick={handleChooseFileClick}
                >
                  browse
                </Anchor>
              )}
              &nbsp;to choose files
            </Secondary>
          </Stack>
          {validFileExtensions.length && (
            <Inline space='small'>
              {validFileExtensions
                .filter((fileExtension) => fileExtension.label)
                .map((fileExtension: ValidFileExtension) => (
                  <InlineItem key={fileExtension.fileExtension}>
                    <Pill>{fileExtension.label}</Pill>
                  </InlineItem>
                ))}
            </Inline>
          )}
        </Stack>
      </Content>
      <Portal>
        <Dropzone ref={dropzoneRef}>
          Drop {fileCount === 1 ? 'file' : 'files'} to upload.
        </Dropzone>
      </Portal>
      <Hidden>
        <input
          accept={validFileExtensions
            .map((fileExtension) => fileExtension.fileExtension)
            .join()}
          multiple
          onChange={handleInputChange}
          ref={fileInputRef}
          type='file'
        />
      </Hidden>
    </>
  );
};

type Props = {
  onChange: (files: FileList) => void;
  validFileExtensions?: ValidFileExtension[];
  disabled?: boolean;
};

export { FileUploader };

import { useSelector } from 'react-redux';
import { Store } from '@/store';
import { useGetFlagsQuery, flagByIdSelector } from '@/api/flags';

const useFlag = (name: string) => {
  useGetFlagsQuery();
  const flag = useSelector((state: Store) => flagByIdSelector(state, name));
  return flag?.enabled ?? false;
};

export { useFlag };

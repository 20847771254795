import { KeyboardEvent } from 'react';
import { every, get } from 'lodash/fp';
import Bowser from 'bowser';

const parser = Bowser.getParser(window.navigator.userAgent);
const osName = parser.getOSName();
const osNameMacOs = osName === 'macOS';

const Shortcuts = (isMacOs: boolean) => {
  const modifiers: Modifiers = {
    none: {
      keys: [],
      inverseKeys: ['ctrlKey', 'altKey', 'metaKey', 'shiftKey'],
    },
    primary: isMacOs
      ? {
          keys: ['metaKey'],
          inverseKeys: ['ctrlKey', 'altKey', 'shiftKey'],
          description: '⌘',
        }
      : {
          keys: ['ctrlKey'],
          inverseKeys: ['altKey', 'metaKey', 'shiftKey'],
          description: 'Ctrl',
        },
    alternative: isMacOs
      ? {
          keys: ['altKey'],
          inverseKeys: ['ctrlKey', 'metaKey', 'shiftKey'],
          description: '⌥',
        }
      : {
          keys: ['altKey'],
          inverseKeys: ['ctrlKey', 'metaKey', 'shiftKey'],
          description: 'Alt',
        },
    secondary: isMacOs
      ? {
          keys: ['metaKey', 'shiftKey'],
          inverseKeys: ['ctrlKey', 'altKey'],
          description: '⌘+⇧',
        }
      : {
          keys: ['ctrlKey', 'shiftKey'],
          inverseKeys: ['metaKey', 'altKey'],
          description: 'Ctrl+Shift',
        },
  };

  const keys = {
    slash: {
      symbol: '/',
      code: 'Slash',
    },
    k: {
      symbol: 'k',
      code: 'KeyK',
    },
    y: {
      symbol: 'y',
      code: 'KeyY',
    },
    z: {
      symbol: 'z',
      code: 'KeyZ',
    },
    // add more here when you want other symbols
  };

  const generateMnemonic = (modifier: Modifier, key: Key) => {
    const ks = [];
    if (modifier.description) {
      ks.push(modifier.description);
    }
    ks.push(key.symbol);
    return ks.join('+');
  };

  const modifierMatch = (event: KeyboardEvent, modifier: Modifier) =>
    every((key) => event[key], modifier.keys) &&
    every((key) => !event[key], modifier.inverseKeys);

  const codeMatch = (event: KeyboardEvent, key: Key) =>
    get('code', event) === key.code;

  const shortcut = (modifierType: ModifierType, keyType: KeyType) => {
    const modifier = modifiers[modifierType];
    const key = keys[keyType];
    const mnemonic = generateMnemonic(modifier, key);
    const handler = (e: KeyboardEvent) => {
      if (modifierMatch(e, modifier) && codeMatch(e, key)) {
        e.preventDefault();
        return true;
      } else {
        return false;
      }
    };

    return {
      handler,
      mnemonic,
    };
  };

  return {
    SEARCH_ITEMS: shortcut('primary', 'slash'),
    SWITCHER: shortcut('primary', 'k'),
  };
};

const shortcuts = Shortcuts(osNameMacOs);

export type KeyType = 'slash' | 'k' | 'y' | 'z';
export type ModifierType = 'none' | 'primary' | 'alternative' | 'secondary';
export type MetaKey = 'ctrlKey' | 'altKey' | 'metaKey' | 'shiftKey';
export type Modifier = {
  keys: MetaKey[];
  inverseKeys: MetaKey[];
  description?: string;
};

export type Modifiers = {
  none: Modifier;
  primary: Modifier;
  alternative: Modifier;
  secondary: Modifier;
};

export type Key = {
  symbol: string;
  code: string;
};

export { shortcuts, Shortcuts };

import styled from 'styled-components';
import { noop } from 'lodash/fp';
import { DefaultGridProduct, Product, ProductLaunchPreference } from '@/types';
import { DefaultGridRow } from './default-grid-row';

const DefaultGridLayout = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  margin: 0,
  padding: 0,
  borderColor: theme.borderColor,
  borderStyle: 'solid',
  borderWidth: `1px`,
  borderRadius: '2px',
  '& > *': {
    borderColor: theme.borderColor,
    borderStyle: 'solid',
    borderWidth: `0 0 1px 0`,
  },
  '& > * > *:first-child': {
    borderColor: theme.borderColor,
    borderStyle: 'solid',
    borderWidth: `0 1px 0 0`,
    padding: `0 8px`,
  },
  '& > *:last-child': {
    border: 'none',
  },
}));

const DefaultGrid = (props: DefaultGridProps) => {
  const {
    defaultGridProducts,
    currentUserCanEdit,
    onChange = noop,
    expandProductOptions,
  } = props;

  return (
    <DefaultGridLayout>
      {defaultGridProducts.map((product) => (
        <DefaultGridRow
          defaultExpanded={expandProductOptions == product.product}
          key={product.product}
          product={product}
          onChange={onChange}
          currentUserCanEdit={currentUserCanEdit}
        />
      ))}
    </DefaultGridLayout>
  );
};

export type DefaultGridProps = {
  defaultGridProducts: DefaultGridProduct[];
  onChange?: (product: Product, selection: ProductLaunchPreference) => void;
  expandProductOptions?: Product;
  currentUserCanEdit: boolean;
};

export { DefaultGrid };

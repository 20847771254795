import { isRejectedWithValue } from '@reduxjs/toolkit';
import type { Middleware, MiddlewareAPI } from '@reduxjs/toolkit';

export const errors: Middleware =
  (api: MiddlewareAPI) => (next) => (actiont) => {
    const action = actiont as any;
    if (isRejectedWithValue(action)) {
      // Handle 403s, by bouncing back to login screen
      if (action?.payload?.status === 403) {
        const state = api.getState();
        if (
          (state?.api?.queries ?? {})['session(undefined)']?.status !==
          'pending'
        ) {
          localStorage.removeItem('kinesis:session');
          if (
            window.location.pathname !== '/' &&
            !window.location.pathname.startsWith('/login')
          ) {
            localStorage.setItem(
              'kinesis:redirect',
              `${window.location.pathname}${window.location.search}`,
            );

            window.location.assign('/');
          }
        }
      } else if (action?.payload?.status === 500) {
        if (import.meta.env.PROD) {
          (window as any).Rollbar.error('Server error', action);
        }
        window.location.assign(
          `/errors/server?errorId=${action?.payload?.data?.errorId}`,
        );
      } else if (action?.payload?.status === 404) {
        if (import.meta.env.PROD) {
          (window as any).Rollbar.error('Not found error', action);
        }
        window.location.assign(`/errors/not-found`);
      }
      // Note: we don't handle 400s, they should be handle by the
      // component/mutation where they can happen.
    }
    return next(action);
  };

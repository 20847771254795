import { useCallback } from 'react';
import { useToast } from '@kinesis/bungle';
import { Analytics } from '@/components/analytics';
import { Loading } from '@/components/loading';
import { useMeQuery, useUpdateMeMutation } from '@/api/user';
import { Me, AnalyticsLevel } from '@/types';

const SettingsAnalytics = () => {
  const toast = useToast('globalTop');
  const { data: me, isLoading } = useMeQuery();
  const [updateMe] = useUpdateMeMutation();
  const onUpdateAnalytics = useCallback(
    async (update: AnalyticsLevel) => {
      try {
        await updateMe({ ...me, analytics: update } as Me);
        toast('Usage analytics settings saved.', { variant: 'success' });
      } catch (e) {
        toast(
          'We couldn’t save your usage analytics settings. Please try again.',
          { variant: 'error' },
        );
      }
    },
    [updateMe, me, toast],
  );

  if (!isLoading && me) {
    return (
      <Analytics
        analytics={me.analytics}
        onUpdateAnalytics={onUpdateAnalytics}
      />
    );
  } else {
    return <Loading />;
  }
};

export { SettingsAnalytics };

import { useCallback, useMemo } from 'react';
import { useToast } from '@kinesis/bungle';
import { useSettingsSelection } from '@/hooks/use-settings-selection';
import { OrganisationDefaults } from '@/components/organisation-defaults';
import { Loading } from '@/components/loading';
import {
  useOrganisationDefaultProductOptionsQuery,
  useOrganisationDefaultProductQuery,
  useOrganisationUpdateDefaultProductMutation,
  useOrganisationUpdateProductLaunchSelectionMutation,
} from '@/api/organisation';
import {
  DefaultProductOption,
  Product,
  ProductLaunchPreference,
} from '@/types';
import { productNamespace } from '@/data/products';
import { useOrganisationDefaultGridProducts } from '@/hooks/use-default-grid-products';
import { useMeQuery } from '@/api/user';

const SettingsOrganisationDefaults = () => {
  const toast = useToast('globalTop');
  const { id } = useSettingsSelection() as any;
  const { data: me } = useMeQuery();
  const isEditor = useMemo(
    () =>
      (me &&
        me.memberships.find(
          ({ organisationId: memberOrganisationId }) =>
            memberOrganisationId === id,
        )?.permissions?.editor) ??
      false,
    [id, me],
  );

  const {
    data: defaultProductOptions,
    isLoading: defaultProductOptionsLoading,
  } = useOrganisationDefaultProductOptionsQuery(id);

  const { data: defaultProduct, isLoading: defaultProductLoading } =
    useOrganisationDefaultProductQuery(id);
  const [updateDefaultProduct] = useOrganisationUpdateDefaultProductMutation();

  const { data: defaultGridProducts, isLoading: defaultGridProductsLoading } =
    useOrganisationDefaultGridProducts(id);

  const [updateProductLaunchSelection] =
    useOrganisationUpdateProductLaunchSelectionMutation();

  const settingsLoading =
    defaultProductOptionsLoading ||
    defaultProductLoading ||
    defaultGridProductsLoading;

  const onChangeProductDefault = useCallback(
    async (preference: DefaultProductOption) => {
      try {
        await updateDefaultProduct({ id, preference }).unwrap();
      } catch (e) {
        toast(
          'We couldn’t save your product defaults settings. Please try again.',
          { variant: 'error' },
        );
      }
    },
    [updateDefaultProduct, toast, id],
  );

  const onChangeDefaultGridProduct = useCallback(
    async (product: Product, selection: ProductLaunchPreference) => {
      try {
        const namespace = productNamespace(product);
        const launchSelection = { namespace: namespace, preference: selection };
        await updateProductLaunchSelection({
          id,
          selection: launchSelection,
        }).unwrap();
      } catch (e) {
        toast(
          'We couldn’t save your product defaults settings. Please try again.',
          {
            variant: 'error',
          },
        );
      }
    },
    [updateProductLaunchSelection, toast, id],
  );

  if (!settingsLoading && id) {
    return (
      <OrganisationDefaults
        key={id}
        currentUserIsOrganisationEditor={isEditor}
        defaultProduct={defaultProduct}
        defaultProductOptions={defaultProductOptions}
        defaultGridProducts={defaultGridProducts}
        onChangeDefaultProduct={onChangeProductDefault}
        onChangeDefaultGridProduct={onChangeDefaultGridProduct}
      />
    );
  } else {
    return <Loading />;
  }
};

export { SettingsOrganisationDefaults };

import { useState, useEffect } from 'react';
import { formatISO } from 'date-fns/fp';

const newNow = () => formatISO(new Date());
const useNow = () => {
  const [now, setNow] = useState(newNow());
  useEffect(() => {
    const id = setInterval(() => setNow(newNow()), 1000 * 60);
    return () => {
      clearInterval(id);
    };
  }, [setNow]);
  return now;
};

export { useNow };

import { ReactNode } from 'react';
import { Content, Heading, Stack, Text } from '@kinesis/bungle';
import { ButtonOffset } from './information-panel.styles';

const InformationPanel = (props: Props) => {
  const {
    children,
    heading,
    renderButton,
    renderIcon,
    mode = 'narrow',
  } = props;

  return (
    <Content
      alignX='center'
      maxWidth={mode == 'narrow' ? 396 : 792}
      textAlign='center'
    >
      <Stack alignX='center' space='large'>
        {renderIcon}
        <Stack alignX='center' space='small'>
          <Heading as='h1' size='xlarge'>
            {heading}
          </Heading>
          <Text size='large'>{children}</Text>
        </Stack>
      </Stack>
      {!!renderButton ? <ButtonOffset>{renderButton}</ButtonOffset> : undefined}
    </Content>
  );
};

type Props = {
  children: ReactNode;
  heading: string;
  renderButton?: ReactNode;
  renderIcon?: ReactNode;
  mode?: 'narrow' | 'wide';
};

export { InformationPanel };

import { useEffect, useCallback, useState, useRef } from 'react';
import { Stack, Input, Button } from '@kinesis/bungle';
import { noop } from 'lodash/fp';

const PasswordResetForm = (props: PasswordResetFormProps) => {
  const {
    defaultEmail = '',
    onPasswordReset = noop,
    isResettingPassword = false,
  } = props;
  const emailRef = useRef<HTMLInputElement>();
  const [email, setEmail] = useState(defaultEmail);
  const [emailError, setEmailError] = useState(false);
  const validateEmail = useCallback(() => {
    // intentionally very weak validation, verification email is
    // a better check, this just is to sense check for a typo or
    // accidental submit.
    const valid = email.length >= 3 && email.includes('@');
    setEmailError(!valid);
    return valid;
  }, [email, setEmailError]);

  const onPasswordResetSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      const valid = validateEmail();
      if (valid) {
        onPasswordReset(email);
      }
    },
    [onPasswordReset, email, validateEmail],
  );

  useEffect(() => {
    emailRef.current?.select();
  }, [emailRef]);

  return (
    <form onSubmit={onPasswordResetSubmit} noValidate>
      <Stack space='medium'>
        <Input
          ref={emailRef}
          label='Email address'
          magnitude='large'
          placeholder='Enter email address'
          value={email}
          onChange={setEmail}
          onBlur={validateEmail}
          tone={emailError ? 'critical' : 'neutral'}
          type='email'
          autoFocus
        />
        <Button type='submit' display='block' loading={isResettingPassword}>
          Send password reset email
        </Button>
      </Stack>
    </form>
  );
};

export type PasswordResetFormProps = {
  defaultEmail?: string;
  onPasswordReset?: (email: string) => void;
  isResettingPassword?: boolean;
};

export { PasswordResetForm };

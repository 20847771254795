import { useCallback, useState } from 'react';
import { AccessKeys } from '@/components/access-keys';
import { Loading } from '@/components/loading';
import { useNow } from '@/hooks/use-now';
import {
  useMeQuery,
  useNewAccessKeyMutation,
  useDeleteAccessKeyMutation,
} from '@/api/user';
import { AccessKeySecret } from '@/types';

const SettingsAccessKeys = () => {
  const now = useNow();
  const { data: me, isLoading } = useMeQuery();
  const [createNewAccessKey, { isLoading: isCreating }] =
    useNewAccessKeyMutation();
  const [deleteAccessKey] = useDeleteAccessKeyMutation();
  const [newAccessKey, setNewAccessKey] = useState<
    undefined | AccessKeySecret
  >();
  const onNewAccessKey = useCallback(async () => {
    setNewAccessKey(await createNewAccessKey().unwrap());
  }, [setNewAccessKey, createNewAccessKey]);
  const onDismissNewAccessKey = useCallback(() => {
    setNewAccessKey(undefined);
  }, [setNewAccessKey]);

  const onDeleteAccessKey = useCallback(
    (id: string) => {
      deleteAccessKey(id);
    },
    [deleteAccessKey],
  );

  if (!isLoading && me) {
    return (
      <AccessKeys
        now={now}
        keys={me.keys}
        isCreating={isCreating}
        newAccessKey={newAccessKey}
        onNewAccessKey={onNewAccessKey}
        onDeleteAccessKey={onDeleteAccessKey}
        onDismissNewAccessKey={onDismissNewAccessKey}
      />
    );
  } else {
    return <Loading />;
  }
};

export { SettingsAccessKeys };

import styled from 'styled-components';

const ServiceAccountEntry = styled.div({
  paddingLeft: 8,
  paddingRight: 4,
  height: 32,
});

const ServiceAccountKeyEntry = styled.div({
  paddingLeft: 32,
  paddingRight: 4,
  height: 32,
});

export { ServiceAccountEntry, ServiceAccountKeyEntry };

import styled from 'styled-components';

const VanityAvatar = styled.div({
  '& > div': {
    width: 144,
    height: 144,
  },
  '& img': {
    padding: 8,
  },
});

export { VanityAvatar };

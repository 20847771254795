import { Modal } from '@kinesis/bungle';
import { noop } from 'lodash/fp';
import { OrganisationMemberInviteForm } from './organisation-member-invite-form';

const OrganisationMemberInviteModal = (
  props: OrganisationMemberInviteModalProps,
) => {
  const {
    onInvite = noop,
    onClose = noop,
    animation = true,
    mandatesSso,
    ssoDomains,
  } = props;
  return (
    <Modal
      animation={animation}
      aria-label='Invite members'
      onClose={onClose}
      width={496}
    >
      <OrganisationMemberInviteForm
        onInvite={onInvite}
        onClose={onClose}
        mandatesSso={mandatesSso}
        ssoDomains={ssoDomains}
      />
    </Modal>
  );
};

export type Email = {
  email: string;
  role: 'editor' | 'member';
};

export type OrganisationMemberInviteModalProps = {
  onClose?: () => void;
  onInvite?: (v: Email[]) => void;
  animation?: boolean;
  mandatesSso?: boolean;
  ssoDomains?: string[];
};

export { OrganisationMemberInviteModal };

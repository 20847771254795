import { Toaster, Container, Frame, Content } from '@kinesis/bungle';
import { Background } from '@/components/background';

const AppLayout = (props: AppLayoutProps) => {
  const { children } = props;
  return (
    <Container>
      <Frame>
        <Background>
          <Toaster id='globalTop' placement='top' />
          <Content
            sizing='fill-container'
            height='100%'
            overflow='scroll'
            padding='medium'
            paddingMode='equal'
          >
            {children}
          </Content>
        </Background>
      </Frame>
    </Container>
  );
};

export type AppLayoutProps = {
  children?: JSX.Element;
};

export { AppLayout };

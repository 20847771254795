import { useCallback, useState } from 'react';
import {
  Stack,
  Text,
  Inline,
  InlineItem,
  UtilityButton,
} from '@kinesis/bungle';
import { OrganisationServiceAccount } from './organisation-service-account';
import { NewServiceAccount as NewServiceAccountModal } from './new-service-account';
import { AccessKeyConfirmation } from '../access-keys/access-key-confirmation';
import { Layout } from '@/components/layout';
import {
  Me,
  NewServiceAccount,
  ServiceAccount,
  AccessKeySecret,
} from '@/types';
import { noop, isEmpty } from 'lodash/fp';

const OrganisationServiceAccounts = (
  props: OrganisationServiceAccountsProps,
) => {
  const {
    organisationId,
    me,
    accounts,
    onDelete = noop,
    onDeleteKey = noop,
    onCreate = noop,
    isCreating = false,
    newAccessKey,
    onNewAccessKey = noop,
    onDismissNewAccessKey,
    now,
  } = props;
  const [showNewServiceAccountModal, setShowDeleteConfirmationModal] =
    useState(false);
  const dismissNewServiceAccountModal = useCallback(
    () => setShowDeleteConfirmationModal(false),
    [setShowDeleteConfirmationModal],
  );
  const openNewServiceAccountModal = useCallback(
    () => setShowDeleteConfirmationModal(true),
    [setShowDeleteConfirmationModal],
  );
  const isEditor =
    me.memberships.find(
      ({ organisationId: memberOrganisationId }) =>
        memberOrganisationId === organisationId,
    )?.permissions?.editor ?? false;

  return (
    <>
      {newAccessKey && (
        <AccessKeyConfirmation
          onDismissNewKey={onDismissNewAccessKey}
          accessKey={newAccessKey}
        />
      )}
      {showNewServiceAccountModal && (
        <NewServiceAccountModal
          onDismiss={dismissNewServiceAccountModal}
          onNewServiceAccount={onCreate}
        />
      )}
      <Layout variant='narrow'>
        <Stack space='medium'>
          <Text>
            Service accounts provide an identity and a security context for
            other applications when they integrate with Kinesis.
          </Text>
          {!isEmpty(accounts) && (
            <Stack space='none'>
              {accounts.map((account) => (
                <OrganisationServiceAccount
                  key={account.id}
                  now={now}
                  isEditor={isEditor}
                  account={account}
                  onDelete={onDelete}
                  onDeleteKey={onDeleteKey}
                  onNewAccessKey={onNewAccessKey}
                />
              ))}
            </Stack>
          )}

          <Inline>
            <InlineItem>
              <UtilityButton
                icon='plus'
                onClick={openNewServiceAccountModal}
                disabled={isCreating}
                magnitude='small'
              >
                New service account
              </UtilityButton>
            </InlineItem>
          </Inline>
        </Stack>
      </Layout>
    </>
  );
};

export type OrganisationServiceAccountsProps = {
  now: string;
  organisationId: number;
  me: Me;
  accounts: ServiceAccount[];
  onDelete?: (account: ServiceAccount) => void;
  onDeleteKey?: (accountId: number, id: string) => void;
  onCreate?: (account: NewServiceAccount) => void;
  isCreating?: boolean;
  onNewAccessKey?: (account: ServiceAccount) => void;
  newAccessKey?: AccessKeySecret;
  onDismissNewAccessKey?: () => void;
};

export { OrganisationServiceAccounts };

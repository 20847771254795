import { useCallback } from 'react';
import { useToast } from '@kinesis/bungle';
import { UserDefaults } from '@/components/user-defaults';
import { DefaultOrganisationSelection } from '@/components/default-organisation';
import {
  DefaultProductOption,
  Product,
  ProductLaunchPreference,
} from '@/types';
import { Loading } from '@/components/loading';
import {
  useDefaultOrganisationQuery,
  useDefaultProductOptionsQuery,
  useDefaultProductQuery,
  useUpdateDefaultOrganisationMutation,
  useUpdateDefaultProductMutation,
  useUpdateProductLaunchSelectionMutation,
} from '@/api/user';
import {
  fromPreference,
  toPreference,
  productNamespace,
} from '@/data/products';
import { useDefaultOrganisationOptions } from '@/hooks/use-default-organisation-options';
import { useUserDefaultGridProducts } from '@/hooks/use-default-grid-products';

const SettingsUserDefaults = () => {
  const toast = useToast('globalTop');

  const {
    data: defaultOrganisationOptions,
    isLoading: defaultOrganisationOptionsLoading,
  } = useDefaultOrganisationOptions();

  const {
    data: defaultProductOptions,
    isLoading: defaultProductOptionsLoading,
  } = useDefaultProductOptionsQuery();

  const { data: defaultOrganisation, isLoading: defaultOrganisationLoading } =
    useDefaultOrganisationQuery();
  const [updateDefaultOrganisation] = useUpdateDefaultOrganisationMutation();

  const { data: defaultProduct, isLoading: defaultProductLoading } =
    useDefaultProductQuery();
  const [updateDefaultProduct] = useUpdateDefaultProductMutation();

  const { data: defaultGridProducts, isLoading: defaultGridProductsLoading } =
    useUserDefaultGridProducts();

  const [updateProductLaunchSelection] =
    useUpdateProductLaunchSelectionMutation();

  const settingsLoading =
    defaultProductOptionsLoading ||
    defaultOrganisationOptionsLoading ||
    defaultOrganisationLoading ||
    defaultProductLoading ||
    defaultGridProductsLoading;

  const defaultOrganisationSelection = defaultOrganisation ?? { id: 0 };

  const defaultProductOption = defaultProduct
    ? fromPreference(defaultProduct.preference)
    : undefined;

  const onChangeOrganisation = useCallback(
    async (selection: DefaultOrganisationSelection) => {
      try {
        await updateDefaultOrganisation(selection).unwrap();
      } catch (e) {
        toast(
          'We couldn’t save your product defaults settings. Please try again.',
          { variant: 'error' },
        );
      }
    },
    [updateDefaultOrganisation, toast],
  );

  const onChangeProductDefault = useCallback(
    async (option: DefaultProductOption) => {
      try {
        const preference = { preference: toPreference(option) };
        await updateDefaultProduct(preference).unwrap();
      } catch (e) {
        toast(
          'We couldn’t save your product defaults settings. Please try again.',
          { variant: 'error' },
        );
      }
    },
    [updateDefaultProduct, toast],
  );

  const onChangeDefaultGridProduct = useCallback(
    async (product: Product, selection: ProductLaunchPreference) => {
      try {
        const namespace = productNamespace(product);
        const launchSelection = { namespace: namespace, preference: selection };
        await updateProductLaunchSelection(launchSelection).unwrap();
      } catch (e) {
        toast(
          'We couldn’t save your product defaults settings. Please try again.',
          {
            variant: 'error',
          },
        );
      }
    },
    [updateProductLaunchSelection, toast],
  );

  if (!settingsLoading) {
    return (
      <UserDefaults
        defaultOrganisation={defaultOrganisationSelection}
        defaultProduct={defaultProductOption}
        defaultProductOptions={defaultProductOptions}
        defaultOrganisationOptions={defaultOrganisationOptions}
        defaultGridProducts={defaultGridProducts}
        onChangeOrganisation={onChangeOrganisation}
        onChangeDefaultProduct={onChangeProductDefault}
        onChangeDefaultGridProduct={onChangeDefaultGridProduct}
      />
    );
  } else {
    return <Loading />;
  }
};

export { SettingsUserDefaults };

import { useState, useCallback } from 'react';
import {
  Avatar,
  ConfirmationDialog,
  Inline,
  InlineItem,
  Text,
  Secondary,
  Strong,
  UtilityButton,
  Stack,
  Tooltip,
} from '@kinesis/bungle';
import {
  ServiceAccountEntry,
  ServiceAccountKeyEntry,
} from './organisation-service-account.styles';
import { AccessKeyItem } from './access-key-item';
import { ServiceAccount } from '@/types';
import { noop } from 'lodash/fp';

const initialOf = (v?: string) => v?.slice(0, 1)?.toUpperCase() ?? '';

const OrganisationServiceAccount = (props: OrganisationServiceAccountProps) => {
  const {
    isEditor = false,
    account,
    onDelete = noop,
    onDeleteKey = noop,
    onNewAccessKey = noop,
    now,
  } = props;
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] =
    useState(false);

  const openDeleteConfirmationModal = useCallback(() => {
    setShowDeleteConfirmationModal(true);
  }, [setShowDeleteConfirmationModal]);

  const closeDeleteModal = useCallback(() => {
    setShowDeleteConfirmationModal(false);
  }, [setShowDeleteConfirmationModal]);

  const deleteServiceAccount = useCallback(() => {
    onDelete(account);
  }, [account, onDelete]);

  const deleteServiceAccountAccessKey = useCallback(
    (keyId: string) => {
      onDeleteKey(account.id, keyId);
    },
    [account, onDeleteKey],
  );

  const newAccessKey = useCallback(() => {
    onNewAccessKey(account);
  }, [account, onNewAccessKey]);

  return (
    <Stack space='none'>
      <ServiceAccountEntry>
        <Inline heightMode='fill-container' space='small'>
          <InlineItem>
            <Avatar magnitude='xsmall' variant='connection'>
              {initialOf(account.name)}
            </Avatar>
          </InlineItem>
          <InlineItem sizing='fill-container'>
            <Text>
              <Strong>{account.name}</Strong>
            </Text>
          </InlineItem>
          <InlineItem>
            <Text>
              <Secondary>
                {account.keys.length} access{' '}
                {account.keys.length === 1 ? 'key' : 'keys'}
              </Secondary>
            </Text>
          </InlineItem>
          <InlineItem>
            {showDeleteConfirmationModal && (
              <ConfirmationDialog
                cancelText='Don’t delete'
                confirmText='Delete'
                icon='question'
                title={`Delete “${account.name}”?`}
                onCancel={closeDeleteModal}
                onConfirm={deleteServiceAccount}
                variant='danger'
                minWidth={320}
              >
                Any applications or integrations using this service account will
                no longer be able to access Kinesis.
              </ConfirmationDialog>
            )}
            <Tooltip title='Delete account' placement='top'>
              <UtilityButton
                icon='delete'
                magnitude='small'
                onClick={openDeleteConfirmationModal}
                variant='danger'
                disabled={!isEditor}
              />
            </Tooltip>
          </InlineItem>
        </Inline>
      </ServiceAccountEntry>
      {account.keys.map((key) => (
        <ServiceAccountKeyEntry key={key.id}>
          <AccessKeyItem
            id={key.id}
            created={key.created}
            now={now}
            onDelete={deleteServiceAccountAccessKey}
          />
        </ServiceAccountKeyEntry>
      ))}
      <ServiceAccountKeyEntry>
        <Inline>
          <InlineItem>
            <UtilityButton icon='plus' onClick={newAccessKey} magnitude='small'>
              Generate access key
            </UtilityButton>
          </InlineItem>
        </Inline>
      </ServiceAccountKeyEntry>
    </Stack>
  );
};

export type OrganisationServiceAccountProps = {
  now: string;
  isEditor?: boolean;
  account: ServiceAccount;
  onDelete?: (account: ServiceAccount) => void;
  onDeleteKey?: (accountId: number, id: string) => void;
  onNewAccessKey?: (account: ServiceAccount) => void;
};

export { OrganisationServiceAccount };

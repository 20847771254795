import { useCallback, useMemo, useState } from 'react';
import {
  Avatar,
  Button,
  Container,
  Content,
  Footer,
  Frame,
  Heading,
  Header,
  Icon,
  Inline,
  InlineItem,
  Stack,
  Text,
  Toolbar,
  ToolbarGroup,
  ToolbarItem,
} from '@kinesis/bungle';
import { useTheme } from 'styled-components';
import { Layout } from '@/components/layout';
import { Loading } from '@/components/loading';
import { CustomerTermsAndConditionsItem } from './customer-terms-and-conditions-item';
import { launch } from '@/services/launch';
import type { Entity, TermsItem } from '@/types';
import { useDispatch } from 'react-redux';
import { declineTerms, reviewUnacceptedTerms } from '@/api/terms';
import { TermsNotAcceptedPanel } from '../terms-not-accepted-panel';

type CustomerTermsAndConditionsProps = {
  entity: Entity;
  data?: TermsItem[];
  onAccept: (_: { id: number; entity: Entity }) => void;
};

const CustomerTermsAndConditions = ({
  entity,
  data = [],
  onAccept,
}: CustomerTermsAndConditionsProps) => {
  const [showNotAccepted, setShowNotAccepted] = useState(false);
  const [redirectIsLoading, setRedirectIsLoading] = useState(false);
  const theme = useTheme();
  const visibleTermsItem = useMemo(
    () => data.find((item) => !item.declined && !item.accepted),
    [data],
  );
  const dispatch = useDispatch<any>();

  const handleDecline = useCallback(() => {
    const isLastTerms =
      data.filter((item) => !item.declined && !item.accepted).length < 2;
    if (visibleTermsItem !== undefined) {
      dispatch(declineTerms(visibleTermsItem.id, entity));

      if (isLastTerms) {
        setShowNotAccepted(true);
      }
    }
  }, [data, dispatch, entity, visibleTermsItem]);

  const handleAccept = useCallback(async () => {
    const isLastTerms =
      data.filter((item) => !item.declined && !item.accepted).length < 2;
    const isOneOrMoreDeclined = data.filter((item) => item.declined).length > 0;
    if (visibleTermsItem !== undefined) {
      if (isLastTerms && isOneOrMoreDeclined) {
        setShowNotAccepted(true);
      }

      await onAccept({ id: visibleTermsItem.id, entity });
    }

    const redirect = localStorage.getItem('kinesis:redirect');
    if (isLastTerms && !isOneOrMoreDeclined) {
      setRedirectIsLoading(true);
      if (redirect) {
        localStorage.removeItem('kinesis:redirect');
        window.location.replace(redirect);
      } else {
        launch(entity, true);
      }
    }
  }, [onAccept, visibleTermsItem, data, entity]);

  const handleReviewUnacceptedTerms = useCallback(() => {
    dispatch(reviewUnacceptedTerms(entity));
    setShowNotAccepted(false);
  }, [dispatch, entity]);

  if (data.length === 0) {
    launch(entity, true);
    return <Loading />;
  }

  return (
    <Container direction='horizontal'>
      {visibleTermsItem && (
        <Header border shadow>
          <Content
            alignX='center'
            background='gray'
            padding='medium'
            paddingMode='equal'
          >
            <Content maxWidth={648} textAlign='center'>
              <Stack alignX='center' space='small'>
                <Avatar
                  alt={`${visibleTermsItem.ownerName} logo`}
                  image={visibleTermsItem.ownerAvatarUrl}
                  magnitude='xlarge'
                  variant='organisation'
                />
                <Heading as='h1' size='large'>
                  {visibleTermsItem.ownerName} data terms of use
                </Heading>
                <Text>
                  You are accessing data licensed by{' '}
                  {visibleTermsItem.ownerName}. Acknowledge that you have read
                  and accept the following terms of use to continue.
                </Text>
              </Stack>
            </Content>
          </Content>
        </Header>
      )}
      {showNotAccepted ? (
        <TermsNotAcceptedPanel />
      ) : (
        <Frame>
          <Layout variant='narrow'>
            {visibleTermsItem && (
              <Content padding='small'>
                <CustomerTermsAndConditionsItem
                  id={visibleTermsItem.id}
                  name={visibleTermsItem.name}
                  text={visibleTermsItem.text}
                />
              </Content>
            )}
          </Layout>
        </Frame>
      )}
      <Footer border shadow>
        <Layout variant='narrow'>
          <Toolbar variant='inline'>
            <ToolbarGroup>
              <ToolbarItem>
                <Inline as='ul' space='small'>
                  {data.map((item) => (
                    <InlineItem key={item.id} as='li'>
                      {item.accepted ? (
                        <Icon
                          color={theme.color.green6}
                          magnitude='large'
                          type='check-circle'
                        />
                      ) : (
                        <Avatar
                          magnitude='small'
                          showPlaceholderIfEmpty
                          variant='individual'
                        />
                      )}
                    </InlineItem>
                  ))}
                </Inline>
              </ToolbarItem>
            </ToolbarGroup>
            {showNotAccepted ? (
              <ToolbarGroup>
                <ToolbarItem>
                  <Button
                    onClick={handleReviewUnacceptedTerms}
                    variant='secondary'
                  >
                    Review terms
                  </Button>
                </ToolbarItem>
                <ToolbarItem>
                  <Button as='a' href='/settings' target='_blank'>
                    Continue to settings
                  </Button>
                </ToolbarItem>
              </ToolbarGroup>
            ) : (
              <ToolbarGroup>
                <ToolbarItem>
                  <Button
                    disabled={visibleTermsItem === undefined}
                    onClick={handleDecline}
                    variant='secondary'
                  >
                    Don&rsquo;t accept and continue
                  </Button>
                </ToolbarItem>
                <ToolbarItem>
                  <Button
                    loading={redirectIsLoading}
                    disabled={visibleTermsItem === undefined}
                    onClick={handleAccept}
                    type='submit'
                  >
                    Accept and continue
                  </Button>
                </ToolbarItem>
              </ToolbarGroup>
            )}
          </Toolbar>
        </Layout>
      </Footer>
    </Container>
  );
};

export { CustomerTermsAndConditions };

import { ReactNode } from 'react';
import { Stack, Content, CenteredPanel } from '@kinesis/bungle';
import { ResponsiveMinMax } from './layout.styles';

const Layout = (props: LayoutProps) => {
  const { children, variant } = props;
  return (
    <CenteredPanel>
      <ResponsiveMinMax variant={variant}>
        <Content padding='medium' paddingMode='equal' sizing='fill-container'>
          <Stack space='medium'>{children}</Stack>
        </Content>
      </ResponsiveMinMax>
    </CenteredPanel>
  );
};

export type LayoutProps = {
  children: ReactNode;
  variant?: 'default' | 'narrow';
};

export { Layout };

import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Center } from '@/components/center';
import { isNil } from 'lodash/fp';
import {
  RegistrationTerms,
  AcceptTerms,
} from '@/components/registration-terms';
import { useSessionQuery } from '@/api/login';
import { useRegisterTermsMutation } from '@/api/registration';
import { launch } from '@/services/launch';

const Terms = () => {
  const navigate = useNavigate();
  const { data: session, isLoading } = useSessionQuery();
  const [acceptTerms, { isLoading: isAcceptingTerms }] =
    useRegisterTermsMutation();
  const onAcceptTerms = useCallback(
    async (v: AcceptTerms) => {
      const data = await acceptTerms(v).unwrap();
      if (data.pendingInvitations) {
        navigate('/invitations');
      } else if (data.orphan) {
        navigate('/registration/complete');
      } else {
        launch(data.target, true);
      }
    },
    [acceptTerms, navigate],
  );

  if (isLoading || isNil(session)) {
    return null;
  } else {
    return (
      <Center>
        <RegistrationTerms
          requiresTerms={!session.acceptedTerms}
          requiresAnalytics={isNil(session.analytics)}
          isAccepting={isAcceptingTerms}
          onAcceptTerms={onAcceptTerms}
        />
      </Center>
    );
  }
};

export { Terms };

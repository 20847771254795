import { Outlet } from 'react-router-dom';
import { AppLayout } from '@/components/app-layout';

const StyledApp = () => {
  return (
    <AppLayout>
      <Outlet />
    </AppLayout>
  );
};

export { StyledApp };

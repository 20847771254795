import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useToast, Strong } from '@kinesis/bungle';
import { useNavigate } from 'react-router-dom';
import { useSettingsSelection } from '@/hooks/use-settings-selection';
import { useMeQuery } from '@/api/user';
import {
  useListMembersQuery,
  membersSelector,
  useListOrganisationsQuery,
  organisationByIdSelector,
  useRevokeMembershipMutation,
  useCancelInvitationMutation,
  useNewInvitationMutation,
  useReissueInvitationMutation,
  useRoleUpdateMutation,
  useExportMembersMutation,
} from '@/api/organisation';
import { Loading } from '@/components/loading';
import { OrganisationMembers } from '@/components/organisation-members';
import { Member } from '@/types';

const SettingsOrganisationMembers = () => {
  const toast = useToast('globalTop');

  const navigate = useNavigate();
  const { id } = useSettingsSelection() as any;
  const { data: me, isLoading: isLoadingMe } = useMeQuery();
  const { isLoading: isLoadingOrganisation } = useListOrganisationsQuery();
  const organisation = useSelector((state: any) =>
    organisationByIdSelector(state, id),
  );
  const { isLoading } = useListMembersQuery(id);
  const members = useSelector(membersSelector(id));
  const [revokeMembership] = useRevokeMembershipMutation();
  const [cancelInvitation] = useCancelInvitationMutation();
  const [reissueInvitation] = useReissueInvitationMutation();
  const [newInvitation] = useNewInvitationMutation();
  const [roleUpdate] = useRoleUpdateMutation();
  const [exportMembers] = useExportMembersMutation();

  const onSetMember = useCallback(
    async (member: Member) => {
      try {
        const isMe = me?.id === member.id;
        await roleUpdate({
          organisationId: member.membership.organisationId,
          userId: member.id,
          membershipId: member.membership.id,
          isMe,
          role: 'member',
        }).unwrap();
      } catch (e) {
        toast(`Failed to set ${member.email} to Member.`, { variant: 'error' });
      }
    },
    [me?.id, roleUpdate, toast],
  );

  const onSetAdmin = useCallback(
    async (member: Member) => {
      try {
        const isMe = me?.id === member.id;
        await roleUpdate({
          organisationId: member.membership.organisationId,
          userId: member.id,
          membershipId: member.membership.id,
          isMe,
          role: 'editor',
        }).unwrap();
      } catch {
        toast(`Failed to set ${member.email} to Admin.`, { variant: 'error' });
      }
    },
    [me?.id, roleUpdate, toast],
  );

  const onRemoveAccess = useCallback(
    async (member: Member) => {
      const isMe = me?.id === member.id;
      try {
        await revokeMembership({
          organisationId: member.membership.organisationId,
          userId: member.id,
          membershipId: member.membership.id,
          isMe,
        }).unwrap();
        if (isMe) {
          toast(
            <span>
              You have left <Strong>{organisation?.name}.</Strong>
            </span>,
            {
              variant: 'success',
            },
          );
          navigate('/settings/account/profile');
        }
      } catch {
        if (isMe) {
          toast(`Failed to leave ${organisation?.name}.`, { variant: 'error' });
        } else {
          toast(`Failed to remove access to ${organisation?.name}.`, {
            variant: 'error',
          });
        }
      }
    },
    [revokeMembership, navigate, me?.id, toast, organisation],
  );

  const onSendInvitations = useCallback(
    async (
      invitees: { email: string; role: 'editor' | 'member' }[],
      organisationId: number,
    ) => {
      const target =
        invitees.length === 1 ? invitees[0].email : `${invitees.length} emails`;
      try {
        await newInvitation({
          invitees,
          organisationId: organisationId,
        }).unwrap();
        toast(`Invitation to ${target} sent.`, { variant: 'success' });
      } catch (e) {
        toast(`We couldn’t send invitation to ${target}.`, {
          variant: 'error',
        });
      }
    },
    [newInvitation, toast],
  );

  const onResendInvitation = useCallback(
    async (member: Member) => {
      try {
        await reissueInvitation({
          userId: member.id,
          organisationId: member.membership.organisationId,
          invitationToken: member.membership.invitationToken as string,
        }).unwrap();
        toast(`Invitation to ${member.email} sent.`, { variant: 'success' });
      } catch (e) {
        toast(`We couldn’t send invitation to ${member.email}.`, {
          variant: 'error',
        });
      }
    },
    [reissueInvitation, toast],
  );

  const onCancelInvitation = useCallback(
    async (member: Member) => {
      try {
        await cancelInvitation({
          userId: member.id,
          organisationId: member.membership.organisationId,
          invitationToken: member.membership.invitationToken as string,
        }).unwrap();
      } catch {
        toast(`We couldn’t cancel invitation to ${member.email}.`, {
          variant: 'error',
        });
      }
    },
    [cancelInvitation, toast],
  );

  const onExport = useCallback(async () => {
    const { csv, filename } = await exportMembers(organisation.id).unwrap();
    const el = document.createElement('a');
    el.href = `data:text/csv;base64,${csv}`;
    el.download = filename;
    document.body.appendChild(el);
    el.click();
    document.body.removeChild(el);
  }, [organisation, exportMembers]);

  if (
    !isLoading &&
    !isLoadingMe &&
    !isLoadingOrganisation &&
    members &&
    me &&
    organisation
  ) {
    return (
      <OrganisationMembers
        key={id}
        organisationId={id}
        organisationName={organisation.name}
        mandatesSso={organisation.mandatesSso}
        ssoDomains={organisation.ssoDomains}
        me={me}
        members={members}
        onSetMember={onSetMember}
        onSetAdmin={onSetAdmin}
        onRemoveAccess={onRemoveAccess}
        onSendInvitations={onSendInvitations}
        onResendInvitation={onResendInvitation}
        onCancelInvitation={onCancelInvitation}
        onExport={onExport}
      />
    );
  } else {
    return <Loading />;
  }
};

export { SettingsOrganisationMembers };

import { api } from './api';

const cityApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getCollectionPortal: builder.query<CollectionPortalProject, string>({
      query: (token) => `city-collection-portals/${token}`,
    }),
    createCollectionPortalUpload: builder.mutation<
      CollectionUpload,
      CollectionUploadCreate
    >({
      query: ({ token, format }) => ({
        url: `city-collection-portals/${token}/upload-transfer`,
        method: 'POST',
        body: { format },
      }),
    }),
    submitCollectionPortalUpload: builder.mutation<
      void,
      CollectionUploadSubmit
    >({
      query: ({ token, uploads }) => ({
        url: `city-collection-portals/${token}/upload`,
        method: 'POST',
        body: { uploads },
      }),
    }),
  }),
});

export type CollectionUploadSubmit = {
  uploads: CollectionUpload[];
  token: string;
};

export type CollectionUploadCreate = {
  format: string;
  token: string;
};

export type CollectionUpload = {
  format: string;
  key: string;
  url: string;
};

export type CollectionPortalProject = {
  owner: {
    id: number;
    name: string;
    avatar?: string;
  };
  name: string;
};

export const {
  useGetCollectionPortalQuery,
  useCreateCollectionPortalUploadMutation,
  useSubmitCollectionPortalUploadMutation,
} = cityApi;

import { useTheme } from 'styled-components';
import { useCallback, useMemo } from 'react';
import { rgba } from 'polished';
import {
  Stack,
  Icon,
  Content,
  Inline,
  InlineItem,
  Text,
  Strong,
  UtilityButton,
} from '@kinesis/bungle';

const renderRole = (role: 'editor' | 'member') =>
  ({
    editor: 'Admin',
    member: 'Member',
  })[role];

const Email = (props: EmailProps) => {
  const {
    email,
    role,
    selected,
    highlighted = false,
    errorMessage,
    errorState,
    onSelect,
    onDeselect,
  } = props;
  const theme = useTheme() as any;
  const valid = email.length > 3 && email.includes('@');
  const onClick = useCallback(() => {
    if (valid) {
      if (selected) {
        onDeselect(email);
      } else {
        onSelect(email, role);
      }
    }
  }, [email, role, selected, valid, onSelect, onDeselect]);

  const itemStyle = useMemo(() => {
    if (!highlighted) {
      return {
        display: 'block',
        width: '100%',
      };
    } else {
      return {
        display: 'block',
        width: '100%',
        fontWeight: theme.fontWeight.normal,
        backgroundColor: theme.color.monochrome2,
        '&:hover': {
          backgroundColor: theme.color.gray3,
        },
        '&:focus': {
          boxShadow: `0 0 0 2px ${rgba('black', 0.05)}`,
          zIndex: 1,
        },
        '&:active': {
          backgroundColor: theme.color.gray4,
        },
      };
    }
  }, [highlighted, theme]);

  return (
    <UtilityButton onClick={onClick} style={itemStyle} disabled={errorState}>
      <Inline>
        <InlineItem>
          <Icon type='mail' magnitude='large' />
        </InlineItem>
        <InlineItem sizing='fill-container'>
          <Content textAlign='left'>
            <Stack space='none'>
              <Text>
                <Strong>{email}</Strong>
              </Text>
              <Text tone={errorState ? 'critical' : 'secondary'}>
                {errorState ? errorMessage : 'email contact'}
              </Text>
            </Stack>
          </Content>
        </InlineItem>
        <InlineItem>
          <Text tone='secondary'>{renderRole(role)}</Text>
        </InlineItem>
        {valid && (
          <InlineItem>
            <Icon
              type={selected ? 'check-circle' : 'circle'}
              magnitude='large'
              color={selected ? '#74D487' : theme.color.gray5}
            />
          </InlineItem>
        )}
      </Inline>
    </UtilityButton>
  );
};

export type EmailProps = {
  email: string;
  role: 'editor' | 'member';
  selected: boolean;
  highlighted?: boolean;
  errorMessage?: string;
  errorState?: boolean;
  onSelect: (email: string, role: 'editor' | 'member') => void;
  onDeselect: (email: string) => void;
};

export { Email };

import { useMemo } from 'react';
import { useMatches, useParams } from 'react-router-dom';
import { isNil } from 'lodash/fp';
import { SettingsMenuSelection } from '@/components/settings-menu';

const useSettingsSelection = (): SettingsMenuSelection => {
  const params = useParams();
  const { settingType, settingKey } =
    useMatches()?.find(({ handle }: any) => !isNil(handle?.settingType))
      ?.handle ?? ({} as any);
  const selection = useMemo<SettingsMenuSelection>(
    () =>
      settingType === 'organisation'
        ? ({
            type: settingType,
            key: settingKey,
            id: Number(params.organisationId),
          } as SettingsMenuSelection)
        : ({
            type: settingType,
            key: settingKey,
          } as SettingsMenuSelection),
    [settingType, settingKey, params.organisationId],
  );

  return selection;
};

export { useSettingsSelection };

import { Icon } from '@kinesis/bungle';

/* eslint-disable max-len */
const IconDefaults = (props: IconDefaultsProps) => {
  const { magnitude = 'small' } = props;
  return (
    <Icon type='custom' magnitude={magnitude}>
      <svg
        width='1024'
        height='1024'
        viewBox='0 0 1024 1024'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path d='M752 240H144C126.3 240 112 254.3 112 272V880C112 897.7 126.3 912 144 912H752C769.7 912 784 897.7 784 880V272C784 254.3 769.7 240 752 240ZM712 840H184V312H712V840ZM880 112H264C259.6 112 256 115.6 256 120V176C256 180.4 259.6 184 264 184H840V760C840 764.4 843.6 768 848 768H904C908.4 768 912 764.4 912 760V144C912 126.3 897.7 112 880 112Z' />
      </svg>
    </Icon>
  );
};

export type IconDefaultsProps = {
  magnitude?: string;
};

export { IconDefaults };
/* eslint-enable max-len */

import styled from 'styled-components';

const Layout = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: '48px',
  padding: '0px 16px 0px',
});

export { Layout };

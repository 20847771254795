import { Anchor, ConfirmationDialog } from '@kinesis/bungle';
import { noop } from 'lodash/fp';

const InvitationError = (props: InvitationErrorProps) => {
  const { animation = true, onClose = noop } = props;
  return (
    <ConfirmationDialog
      animation={animation}
      confirmText='Cancel'
      icon='exclamation'
      title='Invitation cannot be accepted for this organisation'
      onConfirm={onClose}
      showCancelButton={false}
      variant='secondary'
      minWidth={320}
    >
      This organisation requires SSO to join. In order to change authentication
      mechanism, please{' '}
      <Anchor href='mailto:support@kinesis.org?Subject=Request%20to%20change%20authentication%20mechanism%20to%20SSO'>
        contact support
      </Anchor>
      .
    </ConfirmationDialog>
  );
};

export type InvitationErrorProps = {
  onClose: () => void;
  animation?: boolean;
};

export { InvitationError };

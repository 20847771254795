import { useCallback } from 'react';
import { Avatar, Inline, InlineItem, Text, Select } from '@kinesis/bungle';
import { noop } from 'lodash/fp';
import { Organisation } from '@/types';
import { DefaultOrganisationSelection } from './types';

const DefaultOrganisation = (props: DefaultOrganisationProps) => {
  const { defaultOrganisation, options, onChangeOrganisation = noop } = props;

  const handleChangeOrganisation = useCallback(
    (id: number) => onChangeOrganisation({ id }),
    [onChangeOrganisation],
  );

  const renderOrganisationItem = (organisation: Organisation) => (
    <Inline space='small'>
      <InlineItem sizing='fit-content'>
        <Avatar
          image={organisation.avatar}
          magnitude='xsmall'
          variant='organisation'
        >
          {organisation.name.slice(0, 1)}
        </Avatar>
      </InlineItem>
      <InlineItem sizing='fill-container'>
        <Text>{organisation.name}</Text>
      </InlineItem>
    </Inline>
  );

  return (
    <Select
      label='Default organisation'
      options={options}
      renderItem={renderOrganisationItem}
      valueKey='id'
      onChange={handleChangeOrganisation}
      value={defaultOrganisation.id}
    />
  );
};

export type DefaultOrganisationProps = {
  defaultOrganisation: DefaultOrganisationSelection;
  options: Organisation[];
  onChangeOrganisation?: (selection: DefaultOrganisationSelection) => void;
};

export { DefaultOrganisation };

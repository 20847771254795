const key = 'kinesis_login_email';

const setLastLogin = (email?: string | null) => {
  if (!email) {
    localStorage.removeItem(key);
  } else {
    localStorage.setItem(key, email);
  }
};

const getLastLogin = () => localStorage.getItem(key) ?? '';

export { getLastLogin, setLastLogin };

import { useCallback, useState } from 'react';
import {
  Text,
  Stack,
  AvatarUploader,
  LabelText,
  Input,
  Select,
  Button,
  Inline,
  InlineItem,
} from '@kinesis/bungle';
import { NewOrganisation } from '@/types';
import { AnchorText } from '@/components/anchor-text';
import { Layout } from '@/components/layout';
import { noop, isEmpty } from 'lodash/fp';

const dataStorageOptions = [
  { value: 'au', label: 'Australia' },
  { value: 'us', label: 'United States' },
];

const industries = [
  { label: 'Manufacturing', value: 'Manufacturing' },
  { label: 'Retail', value: 'Retail' },

  { label: 'Financial services', value: 'Financial services' },
  {
    label: 'Mobility and transportation',
    value: 'Mobility and transportation',
  },
  {
    label: 'Property development and management',
    value: 'Property development and management',
  },
  { label: 'Public sector', value: 'Public sector' },
  { label: 'Utilities', value: 'Utilities' },
  { label: 'Other', value: 'Other' },
];

type DataStorage = 'au' | 'us';
type Industry = string;

const OrganisationProfileCreate = (props: OrganisationProfileCreateProps) => {
  const { onCreateOrganisationProfile = noop } = props;
  const [dataStorageState, setDataStorageState] = useState<DataStorage>('au');
  const [industryState, setIndustryState] = useState<Industry>('');
  const [avatarState, setAvatarState] = useState(undefined);
  const [nameState, setNameState] = useState('');

  const onSave = useCallback(() => {
    onCreateOrganisationProfile({
      avatar: avatarState,
      name: nameState,
      industry: industryState,
      dataStorage: dataStorageState,
    });
  }, [
    onCreateOrganisationProfile,
    avatarState,
    nameState,
    industryState,
    dataStorageState,
  ]);

  return (
    <Layout variant='narrow'>
      <Stack space='large'>
        <Stack space='medium'>
          <Stack space='medium'>
            <Stack space='xsmall'>
              <LabelText>Logo</LabelText>
              <AvatarUploader
                variant='organisation'
                image={avatarState}
                onChange={setAvatarState}
              />
            </Stack>
          </Stack>
          <Stack space='medium'>
            <Inline>
              <InlineItem sizing='fill-container'>
                <Input
                  label='Name'
                  magnitude='large'
                  value={nameState}
                  onChange={setNameState}
                  placeholder='Enter name'
                />
              </InlineItem>
            </Inline>
            <Select
              label='Industry'
              magnitude='large'
              options={industries}
              value={industryState}
              onChange={setIndustryState}
            />
            <Select
              onChange={setDataStorageState}
              value={dataStorageState}
              options={dataStorageOptions}
              label='Data storage location'
              message={
                <Text>
                  This cannot be changed later.{' '}
                  <AnchorText
                    href='https://kinesis.org/'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    Learn More.
                  </AnchorText>
                </Text>
              }
              disabled
            />
          </Stack>
        </Stack>
        <Inline>
          <InlineItem>
            <Button
              variant='primary'
              onClick={onSave}
              disabled={isEmpty(nameState)}
            >
              Save changes
            </Button>
          </InlineItem>
        </Inline>
      </Stack>
    </Layout>
  );
};

export type OrganisationProfileCreateProps = {
  onCreateOrganisationProfile?: (update: NewOrganisation) => void;
};

export { OrganisationProfileCreate };

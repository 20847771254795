import { api } from './api';

type Namespace =
  | 'city'
  | 'integrated'
  | 'workspace'
  | 'inventory'
  | 'dataset'
  | 'pathways'
  | 'settings'
  | 'bill-management';

type Entity = {
  type: 'entity';
  id: string;
  name: string;
  namespace: Namespace;
};

type EntityType = {
  type: 'entity-type';
  namespace: Namespace;
};

type SearchResult = Entity | EntityType;

type SearchResponse = {
  total: number;
  results: SearchResult[];
};

const entitiesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    searchEntities: builder.query<SearchResponse, string>({
      query: (query) => ({
        url: `entities/search`,
        method: 'POST',
        body: {},
        params: { query: query },
      }),
    }),
    entityTypes: builder.query<EntityType[], void>({
      query: () => ({
        url: `entities/types`,
        method: 'GET',
      }),
    }),
    recentEntities: builder.query<Entity[], void>({
      query: () => ({
        url: `entities/recent`,
        method: 'GET',
      }),
    }),
  }),
});

export const getEntityUrl = (namespace: Namespace, id: string) => {
  switch (namespace) {
    case 'city':
      return `/city/${id}`;
    case 'integrated':
      return `/integrated/${id}`;
    case 'workspace':
      return `/workspaces/${id}`;
    case 'inventory':
      return `/inventories/${id}`;
    case 'dataset':
      return `/datasets/${id}`;
    case 'bill-management':
      return `/bill-management/${id}`;
    default:
      return '';
  }
};

export const getEntityTypeUrl = (namespace: Namespace) => {
  switch (namespace) {
    case 'city':
      return '/city/';
    case 'integrated':
      return '/integrated/';
    case 'workspace':
      return '/workspaces/';
    case 'inventory':
      return '/inventories/';
    case 'dataset':
      return '/datasets/';
    case 'pathways':
      return '/pathways/';
    case 'settings':
      return '/settings/';
    case 'bill-management':
      return '/bill-management/';
    default:
      return '';
  }
};

export type { Entity, EntityType, Namespace, SearchResponse, SearchResult };

export const {
  useSearchEntitiesQuery,
  useEntityTypesQuery,
  useRecentEntitiesQuery,
} = entitiesApi;

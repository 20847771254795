import {
  Button,
  Container,
  Content,
  Frame,
  Footer,
  H2,
  Header,
  Stack,
  Text,
  Toolbar,
  ToolbarItem,
} from '@kinesis/bungle';
import { noop } from 'lodash/fp';
import { PendingInvitationList } from '@/components/pending-invitation-list';
import { InvitationData } from '@/types';

const PendingInvitationsBox = (props: PendingInvitationsBoxProps) => {
  const {
    onAccept,
    onDecline,
    onDone = noop,
    invitations,
    isLoading = false,
  } = props;
  return (
    <Content
      shadow
      background='white'
      borderRadiusSize='large'
      width={496}
      height={360}
    >
      <Container direction='vertical'>
        <Header border>
          <Content padding='medium' sizing='fill-container' paddingMode='equal'>
            <H2>Pending invitations</H2>
          </Content>
        </Header>
        <Frame>
          <Content
            background='white'
            sizing='fill-container'
            padding='medium'
            paddingMode='equal'
            borderRadiusSize='large'
            borderRadiusPlacement='bottom'
            height='100%'
          >
            <Stack space='medium'>
              <Text>
                You have an invitation to join a new organisation on Kinesis.
              </Text>
              <PendingInvitationList
                onAccept={onAccept}
                onDecline={onDecline}
                invitations={invitations}
              />
            </Stack>
          </Content>
        </Frame>
        <Footer border>
          <Toolbar>
            <ToolbarItem>
              <Button
                variant='primary'
                magnitude='medium'
                onClick={onDone}
                loading={isLoading}
              >
                Done
              </Button>
            </ToolbarItem>
          </Toolbar>
        </Footer>
      </Container>
    </Content>
  );
};

export type PendingInvitationsBoxProps = {
  onAccept?: (token: string) => void;
  onDecline?: (token: string) => void;
  onDone?: () => void;
  invitations: InvitationData[];
  isLoading?: boolean;
};

export { PendingInvitationsBox };

import { useMemo, useState, useCallback } from 'react';
import {
  Stack,
  Input,
  Button,
  Inline,
  InlineItem,
  Container,
  Frame,
  Header,
  Separator,
} from '@kinesis/bungle';
import Fuse from 'fuse.js';
import { OrganisationMember } from './organisation-member';
import { OrganisationMemberInviteModal } from './organisation-member-invite-modal';
import { Layout } from '@/components/layout';
import { Me, Member } from '@/types';
import { noop, isEmpty } from 'lodash/fp';

const fuseOptions = {
  isCaseSensitive: false,
  shouldSort: true,
  includeMatches: false,
  findAllMatches: true,
  minMatchCharLength: 0,
  keys: ['name', 'email', 'role', 'namerole'],
};

const OrganisationMembers = (props: OrganisationMembersProps) => {
  const {
    organisationId,
    organisationName,
    mandatesSso,
    ssoDomains,
    me,
    members,
    onSetMember = noop,
    onSetAdmin = noop,
    onRemoveAccess = noop,
    onSendInvitations = noop,
    onResendInvitation = noop,
    onCancelInvitation = noop,
    onExport = noop,
  } = props;

  const isEditor =
    me.memberships.find(
      ({ organisationId: memberOrganisationId }) =>
        memberOrganisationId === organisationId,
    )?.permissions?.editor ?? false;

  const adminCount = members.filter(
    ({ membership }) =>
      membership.permissions.editor && membership.status === 'active',
  ).length;

  const [showInviteMembersModal, setShowInviteMembersModal] = useState(false);
  const openInviteMemberModal = useCallback(
    () => setShowInviteMembersModal(true),
    [setShowInviteMembersModal],
  );
  const closeInviteMemberModal = useCallback(
    () => setShowInviteMembersModal(false),
    [setShowInviteMembersModal],
  );
  const [search, setSearch] = useState('');

  const relevant = useMemo<Member[]>(() => {
    if (isEmpty(search)) {
      return members;
    }
    const searchable = members.map((member: Member) => ({
      name: `${member.firstName} ${member.lastName}`,
      email: member.email,
      role: member.membership.permissions.editor ? 'Admin' : 'Member',
      namerole: `${member.firstName} ${member.lastName} ${
        member.membership.permissions.editor ? 'Admin' : 'Member'
      }`,
      member,
    }));
    const fuse = new Fuse(searchable, fuseOptions);
    const results = fuse.search(search);
    return results.map(({ item }: any) => item.member);
  }, [search, members]);

  const onCancelInput = useCallback(
    (event: KeyboardEvent) => {
      if (!isEmpty(search) && event.key === 'Escape') {
        event.stopPropagation();
        setSearch('');
      }
    },
    [setSearch, search],
  );

  const onInviteMembers = useCallback(
    (emails: { email: string; role: 'editor' | 'member' }[]) => {
      onSendInvitations(emails, organisationId);
    },
    [onSendInvitations, organisationId],
  );

  return (
    <>
      <Container>
        <Header border>
          <Layout variant='narrow'>
            <Inline space='small'>
              <InlineItem sizing='fill-container'>
                <Input
                  magnitude='large'
                  value={search}
                  onChange={setSearch}
                  placeholder='Search for name or email'
                  onKeyDown={onCancelInput}
                  search
                />
              </InlineItem>
              <Separator />
              <InlineItem>
                <Button variant='secondary' onClick={onExport} icon='download'>
                  Export members
                </Button>
              </InlineItem>
              <InlineItem>
                <Button
                  variant='secondary'
                  onClick={openInviteMemberModal}
                  disabled={!isEditor}
                  icon='plus'
                >
                  Invite members
                </Button>
              </InlineItem>
            </Inline>
          </Layout>
        </Header>
        <Frame>
          <Layout variant='narrow'>
            <Stack space='medium'>
              {relevant.map((member) => (
                <OrganisationMember
                  key={member.id}
                  organisationName={organisationName}
                  isEditor={isEditor}
                  isMe={member.id === me.id}
                  canLeave={
                    !member.membership.permissions.editor || adminCount > 1
                  }
                  member={member}
                  onSetAdmin={onSetAdmin}
                  onSetMember={onSetMember}
                  onRemoveAccess={onRemoveAccess}
                  onResendInvitation={onResendInvitation}
                  onCancelInvitation={onCancelInvitation}
                />
              ))}
            </Stack>
          </Layout>
        </Frame>
      </Container>
      {showInviteMembersModal && (
        <OrganisationMemberInviteModal
          onClose={closeInviteMemberModal}
          onInvite={onInviteMembers}
          mandatesSso={mandatesSso}
          ssoDomains={ssoDomains}
        />
      )}
    </>
  );
};

export type OrganisationMembersProps = {
  organisationId: number;
  organisationName: string;
  mandatesSso?: boolean;
  ssoDomains?: string[];
  me: Me;
  members: Member[];
  onSetMember?: (member: Member) => void;
  onSetAdmin?: (member: Member) => void;
  onRemoveAccess?: (member: Member) => void;
  onSendInvitations?: (
    invitees: { email: string; role: 'editor' | 'member' }[],
    organisationId: number,
  ) => void;
  onResendInvitation?: (member: Member) => void;
  onCancelInvitation?: (member: Member) => void;
  onExport?: () => void;
};

export { OrganisationMembers };

const KinesisLogo = (props: KinesisLogoProps) => {
  const { width = '64px', height = '48px' } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 285 210'
      xmlns='http://www.w3.org/2000/svg'
      style={{ verticalAlign: 'middle' }}
    >
      <g>
        <path
          fill='#FAAB44'
          d='M137.8,171.61c0,20.04-16.25,36.29-36.29,36.29c-20.04,0-36.3-16.25-36.3-36.29s16.25-36.29,36.3-36.29
        C121.55,135.31,137.8,151.56,137.8,171.61'
        />
        <path
          fill='#3BBEBE'
          d='M283.72,78.54c0,8.42-6.83,15.25-15.25,15.25c-8.43,0-15.26-6.83-15.26-15.25s6.83-15.25,15.26-15.25
        C276.89,63.29,283.72,70.12,283.72,78.54'
        />
        <path
          fill='#E7404E'
          d='M49.15,86.44c0,13.22-10.72,23.94-23.93,23.94C12,110.37,1.28,99.66,1.28,86.44
        c0-13.22,10.72-23.93,23.94-23.93C38.43,62.51,49.15,73.22,49.15,86.44'
        />
        <path
          fill='#8F2E90'
          d='M224.17,70.31c0,30.26-24.53,54.78-54.77,54.78c-30.26,0-54.79-24.53-54.79-54.78
        c0-30.26,24.53-54.78,54.79-54.78C199.64,15.53,224.17,40.06,224.17,70.31'
        />
        <path
          fill='#A4B2DB'
          d='M214.16,2.1c-11.76,0-21.97,6.62-27.11,16.34c19.03,6.48,33.35,23.14,36.48,43.48
        c12.34-3.96,21.27-15.53,21.27-29.18C244.8,15.82,231.08,2.1,214.16,2.1'
        />
        <path
          fill='#867AB8'
          d='M187.05,18.44c-2.26,4.27-3.54,9.14-3.54,14.3c0,16.92,13.72,30.64,30.65,30.64c3.27,0,6.42-0.51,9.37-1.46
        C220.4,41.57,206.08,24.92,187.05,18.44'
        />
      </g>
    </svg>
  );
};

export type KinesisLogoProps = {
  width?: string;
  height?: string;
};
export { KinesisLogo };

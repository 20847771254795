import { Icon } from '@kinesis/bungle';

/* eslint-disable max-len */
const IconLicences = (props: IconLicencesProps) => {
  const { magnitude = 'small' } = props;
  return (
    <Icon type='custom' magnitude={magnitude}>
      <svg
        width='1024'
        height='1024'
        viewBox='0 0 1024 1024'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path d='M415.876 912.125C583.776 912.125 719.876 776.025 719.876 608.125C719.876 537.825 695.976 473.125 655.976 421.625L697.076 380.525L759.376 442.825C760.899 444.315 762.945 445.15 765.076 445.15C767.207 445.15 769.253 444.315 770.776 442.825L810.576 403.025C812.066 401.502 812.901 399.456 812.901 397.325C812.901 395.194 812.066 393.148 810.576 391.625L748.276 329.325L793.176 284.425L855.476 346.725C856.999 348.215 859.045 349.05 861.176 349.05C863.307 349.05 865.353 348.215 866.876 346.725L906.676 306.925C908.166 305.402 909.001 303.356 909.001 301.225C909.001 299.094 908.166 297.048 906.676 295.525L844.376 233.225L909.676 167.925C911.165 166.421 912 164.391 912 162.275C912 160.159 911.165 158.129 909.676 156.625L867.376 114.325C864.276 111.225 859.176 111.225 856.076 114.325L602.476 367.925C549.091 326.518 483.437 304.07 415.876 304.125C247.976 304.125 111.876 440.225 111.876 608.125C111.876 776.025 247.976 912.125 415.876 912.125ZM254.676 446.925C297.676 403.825 354.976 380.125 415.876 380.125C476.776 380.125 534.076 403.825 577.076 446.925C620.176 489.925 643.876 547.225 643.876 608.125C643.876 669.025 620.176 726.325 577.076 769.325C534.076 812.425 476.776 836.125 415.876 836.125C354.976 836.125 297.676 812.425 254.676 769.325C211.576 726.325 187.876 669.025 187.876 608.125C187.876 547.225 211.576 489.925 254.676 446.925Z' />
      </svg>
    </Icon>
  );
};

export type IconLicencesProps = {
  magnitude?: string;
};

export { IconLicences };
/* eslint-enable max-len */
